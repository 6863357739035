import React from 'react'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'

function Instructions({ instructions, setInstructions, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-instructions">
          What are the administration instructions?
        </label>
        <InputTextarea
          id="dose-instructions"
          rows={5}
          cols={30}
          data-testid="instructions"
          autoResize
          value={instructions}
          onChange={(event) => setInstructions(event.target.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
        />
      </div>
    </div>
  )
}

export default Instructions
