import React from 'react'
import { Dialog } from 'primereact/dialog'
import MedicalConditionForm from '@components/display/MedicalConditions/MedicalConditionForm'

function DoseMedicalConditionDialog({
  isVisible,
  onHide,
  onSave,
  isLoading,
  doseId,
  patientId,
}) {
  return (
    <Dialog
      header="Add Medical Condition"
      visible={isVisible}
      style={{ width: '50vw' }}
      onHide={onHide}
    >
      <MedicalConditionForm
        saveMedicalCondition={(medicalCondition) => {
          onSave({
            ...medicalCondition,
            doseId,
            patientId,
          })
        }}
        isLoading={isLoading}
        hide={onHide}
      />
    </Dialog>
  )
}

export default DoseMedicalConditionDialog
