import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Menu } from 'primereact/menu'
import { SelectButton } from 'primereact/selectbutton'
import { useCurrentOrganization } from '@components/App'
import { checkValidRow, checkAllValidRow } from './validation/validation'
import {
  conditionTypes,
  actionTypes,
  actionConditionTypes,
  administrationUnits,
} from './config'

const onFieldEdit = (props, field, setActions, setIsValid, actions) => (e) => {
  const actionsCopy = [...actions]
  const actionCopy = actionsCopy[props.rowIndex]
  actionCopy[field] = e.target.value

  if (field === 'action') {
    if (e.target.value === 'ADMINISTER') {
      actionCopy.administerUnit = 'Units'
    } else {
      actionCopy.administerQuantity = null
      actionCopy.administerUnit = null
    }
  }

  // check if the row has all valid data. If not, write which
  // cell has an error to actionCopy under errors and return false
  const { validRow, errors } = checkValidRow(actionCopy)
  actionCopy.valid = validRow
  actionCopy.errors = errors

  // write the actionsCopy to state
  setActions(actionsCopy)
  // check final actions to enable submit button by setting isValid to true
  checkAllValidRow(actions, setIsValid)
}

export default function ActionsTable({
  actions, conditionType, setActions, setIsValid,
}) {
  const addActionMenu = useRef(null)
  const [metric, setMetric] = useState(null)
  const [unit, setUnit] = useState(null)
  const organization = useCurrentOrganization()
  // Reset the table when the condition type changes
  useEffect(() => {
    const newConditionType = conditionTypes.find((ct) => ct.code === conditionType)

    if (newConditionType) {
      setMetric(newConditionType.metrics?.[0])
      setUnit(newConditionType.units?.[0])
    }
  }, [conditionType])

  if (!conditionType) {
    return null
  }

  // Get the config for the selected condition type
  const conditionConfig = conditionTypes.find((ct) => ct.code === conditionType)

  const onAddAction = (actionConditionType) => {
    setActions([...actions, {
      // HACK: We need to generate a unique ID for each row in the DataTable.
      rowId: Math.floor(Math.random() * 1_000_000_000),
      action: 'ADMINISTER',
      administerQuantity: '',
      administerUnit: 'Units',
      conditionMetric: metric,
      conditionType: actionConditionType,
      conditionUnit: unit,
      note: '',
      quantityOne: '',
      quantityTwo: '',
      valid: false,
      errors: {
        quantityTwo: false, quantityOne: false, administerQuantity: false,
      },
    }])
  }

  const menuActions = [
    {
      label: 'From ... To ...',
      command: () => onAddAction('RANGE'),
    },
    {
      label: 'Greater Than',
      command: () => onAddAction('GREATER_THAN'),
    },
    {
      label: 'Greater Than or Equal To',
      command: () => onAddAction('GREATER_THAN_OR_EQUAL'),
    },
    {
      label: 'Less Than',
      command: () => onAddAction('LESS_THAN'),
    },
    {
      label: 'Less Than or Equal To',
      command: () => onAddAction('LESS_THAN_OR_EQUAL'),
    },
  ]

  let rangeHeader = `${conditionConfig.displayText} Range`

  if (conditionConfig.units.length === 1) {
    rangeHeader += ` (${unit})`
  }

  const rangeInputGroup = (rowData, props) => {
    if (rowData.conditionType === 'RANGE') {
      return (
        <div className="p-inputgroup flex-shrink-1">
          {conditionConfig.metrics.length > 1 && rowData.conditionMetric
            && <span className="p-inputgroup-addon">{rowData.conditionMetric}</span>}
          <span className="p-inputgroup-addon">from</span>
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(props, 'quantityOne', setActions, setIsValid, actions)}
            placeholder={rowData.conditionUnit}
            value={rowData.quantityOne || ''}
            style={{ minWidth: '60x' }}
            invalid={actions[props.rowIndex]?.errors?.quantityOne}
          />
          <span className="p-inputgroup-addon">to</span>
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(props, 'quantityTwo', setActions, setIsValid, actions)}
            placeholder={rowData.conditionUnit}
            value={rowData.quantityTwo || ''}
            style={{ minWidth: '60x' }}
            invalid={actions[props.rowIndex]?.errors?.quantityTwo}
          />
          {conditionConfig.units.length > 1 && rowData.conditionUnit
            && <span className="p-inputgroup-addon">{rowData.conditionUnit}</span>}
        </div>
      )
    }

    if (rowData.conditionType in actionConditionTypes) {
      return (
        <div className="p-inputgroup flex-shrink-1">
          {conditionConfig.metrics.length > 1 && rowData.conditionMetric
            && <span className="p-inputgroup-addon">{rowData.conditionMetric}</span>}
          <span className="p-inputgroup-addon">{actionConditionTypes[rowData.conditionType]}</span>
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(props, 'quantityOne', setActions, setIsValid, actions)}
            placeholder={rowData.conditionUnit}
            value={rowData.quantityOne || ''}
            style={{ minWidth: '60x' }}
            invalid={actions[props.rowIndex]?.errors?.quantityOne}
          />
          {conditionConfig.units.length > 1 && rowData.conditionUnit
            && <span className="p-inputgroup-addon">{rowData.conditionUnit}</span>}
        </div>
      )
    }

    return (<div>unsupported condition type</div>)
  }

  const actionInputGroup = (rowData, props) => (
    <div className="flex gap-2">
      <Dropdown
        onChange={onFieldEdit(props, 'action', setActions, setIsValid, actions)}
        options={actionTypes(organization)}
        optionLabel="displayText"
        optionValue="code"
        placeholder="Select an Action"
        value={rowData.action}
        style={{ flex: 1 }}
      />
      {rowData.action === 'ADMINISTER' && (
        <div className="p-inputgroup flex-1">
          <InputText
            keyfilter="num"
            onChange={onFieldEdit(props, 'administerQuantity', setActions, setIsValid, actions)}
            placeholder="Amount"
            value={rowData.administerQuantity || ''}
            style={{ flex: '1' }}
            invalid={actions[props.rowIndex]?.errors?.administerQuantity}
          />
          <Dropdown
            onChange={onFieldEdit(props, 'administerUnit', setActions, setIsValid, actions)}
            options={administrationUnits()}
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Unit"
            value={rowData.administerUnit}
            style={{ width: '112px', maxWidth: '116px', flexShrink: 0 }}
          />
        </div>
      )}
    </div>
  )

  const noteTextArea = (rowData, props) => (
    <div className="flex justify-content-center">
      <InputTextarea
        onChange={onFieldEdit(props, 'note', setActions, setIsValid, actions)}
        value={rowData.note}
      />
    </div>
  )

  const deleteActionButton = (rowData, { rowIndex }) => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Button
        aria-label="Delete"
        className="p-button-danger p-button-text p-button-rounded"
        icon="pi pi-trash"
        onClick={() => setActions(actions.filter((_, index) => index !== rowIndex))}
        severity="danger"
      />
    </div>
  )

  const actionsTableHeader = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Actions</span>
      {conditionConfig.metrics.length > 1 && (
        <SelectButton
          onChange={(e) => setMetric(e.value)}
          options={conditionConfig.metrics}
          value={metric}
        />
      )}
      {conditionConfig.units.length > 1 && (
        <SelectButton
          onChange={(e) => setUnit(e.value)}
          options={conditionConfig.units}
          value={unit}
        />
      )}
      <Menu className="w-auto" id="popup_menu" model={menuActions} popup ref={addActionMenu} />
      <Button
        aria-controls="popup_menu"
        aria-haspopup
        className="p-button-sm ml-3"
        icon="pi pi-chevron-down"
        iconPos="right"
        label="Add Action"
        onClick={(event) => addActionMenu.current.toggle(event)}
      />
    </div>
  )

  return (
    <div className="col-12 flex flex-column gap-2 text-center">
      <DataTable
        dataKey="rowId"
        emptyMessage="Add actions to inform the caregiver of additional instructions."
        header={actionsTableHeader}
        value={actions}
        showGridlines
      >
        <Column body={rangeInputGroup} header={rangeHeader} style={{ width: '31%' }} sortable />
        <Column body={actionInputGroup} header="Action" style={{ width: '43%' }} />
        <Column body={noteTextArea} header="Note" style={{ width: '20%' }} />
        <Column body={deleteActionButton} style={{ width: '6%' }} />
      </DataTable>
    </div>
  )
}
