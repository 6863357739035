import React from 'react'
import SuperAdminCard from '@components/display/SuperAdminCard'
import DoseFillsTable from '@components/doseFills/DoseFillsTable'

function DoseFillsCard({
  model, id, timezone, status = 'all',
}) {
  return (
    <div className="col-12">
      <SuperAdminCard title="Dispensed Fills">
        <DoseFillsTable model={model} id={id} timezone={timezone} status={status} />
      </SuperAdminCard>
    </div>
  )
}

export default DoseFillsCard
