import moment from 'moment'
import { useMutation } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'
import { downloadFileFromBase64Response } from '@services/utils'
import { momentFormats } from '@services/utils/moment'

export default function useCreateReportMedicationRelease(statusMessage, patientId) {
  return useMutation({
    mutationKey: ['reportMedicationRelease'],
    mutationFn: async ({ startDate, endDate }) => {
      const response = await ApiClient.post('/reports/medication_releases', {
        startDate: moment(startDate).format(momentFormats.date),
        endDate: moment(endDate).format(momentFormats.date),
        patientId,
      })
      downloadFileFromBase64Response(response)
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
