import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Message } from 'primereact/message'
import { Toast } from 'primereact/toast'
import Exports from '@components/patients/Exports'
import PharmacyOrdersCard from '@components/pharmacyOrders/Card/PharmacyOrdersCard'
import Redirect from '@components/routing/Redirect'
import { ScheduledNoteCard } from '@components/scheduledNotes'
import { TaskConditionsCard } from '@components/tasks/TaskConditionsCard'
import { TaskTable } from '@components/tasks/TaskTable'
import TreatmentHeader from '@components/treatments/TreatmentHeader'
import { useTreatment } from '@hooks/treatments'

function ManageTreatmentPage({ handleSetHeader, patient }) {
  const statusMessage = useRef(null)
  const { patientId: clientId, treatmentId } = useParams()

  const {
    data: treatment,
    isLoading,
  } = useTreatment({ treatmentId })

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  if (isLoading || isEmpty(treatment) || isEmpty(patient)) {
    return 'Loading...'
  }

  // If the treatment hasn't been onboarded yet, redirect to the needs review page for treatments
  if (!treatment.onboardedAt) {
    return <Redirect to={`/admin/patients/${clientId}/orders/treatments`} />
  }

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  const tasks = treatment.tasks || []
  const showTasks = !treatment.showInMedpass
  const showTaskConditions = showTasks && tasks.length === 1
  const showScheduledNote = treatment.showInMedpass
  const showConfigurationMessage = tasks.length === 0 && !treatment.scheduledNote

  return (
    <>
      <Toast ref={statusMessage} />
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <TreatmentHeader treatment={treatment} />
      {
        showConfigurationMessage && (
          <Message
            className="m-2"
            severity="warn"
            text="Please configure this treatment as a task or a reminder in the Actions menu."
          />
        )
      }
      {
        showScheduledNote && (
          <ScheduledNoteCard
            scheduledNote={treatment.scheduledNote}
            editable={!treatment.discontinuedAt}
            timezone={patient.timezone}
          />
        )
      }
      {
        showTasks && tasks.map((task) => (
          <TaskTable
            key={task.id}
            task={task}
            editable={!treatment.discontinuedAt}
            timezone={patient.timezone}
          />
        ))
      }
      {
        showTaskConditions && (
          <TaskConditionsCard task={tasks[0]} />
        )
      }
      <PharmacyOrdersCard model="Treatment" id={treatment.id} />
    </>
  )
}

export default ManageTreatmentPage
