import React, { useState } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useCurrentOrganization } from '@components/App'
import SuperAdminDialog from '@components/display/SuperAdminDialog'
import { useWorkosMessages } from '@hooks/sso'
import { useReportSentryError } from '@hooks/useReportSentryError'
import { momentFormats } from '@services/utils/moment'

function timeTemplate(field, timezone, placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        { value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder }
      </div>
    )
  }
}

const getStatus = (workosMessage) => {
  if (workosMessage.processedAt) {
    return 'Processed'
  }

  if (workosMessage.failedAt) {
    return 'Failed'
  }

  return 'Unknown'
}

function WorkosMessagesTable({ model, id }) {
  const { timezone } = useCurrentOrganization()
  const [payload, setPayload] = useState('{}')
  const [showDialog, setShowDialog] = useState(false)
  const { data: workosMessages, error, isLoading } = useWorkosMessages(model, id)
  const reportSentryError = useReportSentryError([model, id])

  if (error) {
    reportSentryError(`Error getting WorkOS messages for ${model} ${id}: ${JSON.stringify(error)}`)
    return (<div>Error getting WorkOS messages.</div>)
  }

  const handleShowPayload = (rowData) => {
    setPayload(rowData.payload)
    setShowDialog(true)
  }

  const showPayloadButton = (rowData) => (
    <Button
      label={rowData.id}
      className="p-button-text p-button-sm"
      onClick={() => handleShowPayload(rowData)}
    />
  )

  return (
    <>
      <SuperAdminDialog
        header="WorkOS Message Payload"
        hide={() => setShowDialog(false)}
        isVisible={showDialog}
      >
        <pre>{JSON.stringify(JSON.parse(payload), null, 2)}</pre>
      </SuperAdminDialog>
      <DataTable
        dataKey="id"
        loading={isLoading}
        removableSort
        scrollable
        scrollHeight="60vh"
        value={workosMessages}
      >
        <Column
          body={showPayloadButton}
          field="id"
          header="ID"
        />
        <Column
          field="eventType"
          header="Event Type"
        />
        <Column
          body={(rowData) => getStatus(rowData)}
          field="status"
          header="Status"
        />
        <Column
          body={timeTemplate('createdAt', timezone)}
          field="createdAt"
          header="Created"
          className="w-2"
        />
        <Column
          body={(rowData) => timeTemplate(rowData.processedAt ? 'processedAt' : 'failedAt', timezone)(rowData)}
          field="lastRun"
          header="Last Run"
          className="w-2"
        />
        <Column
          field="errorCode"
          header="Error Code"
        />
        <Column
          field="error"
          header="Error"
        />
      </DataTable>
    </>
  )
}

export default WorkosMessagesTable
