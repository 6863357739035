import React from 'react'
import { momentFormats, momentTzWithoutChangingTime } from '@services/utils/moment'
import { useLeaveOfAbsenceCreateMutation } from '@hooks/leaveOfAbsences'
import LeaveOfAbsenceForm from '@components/display/LeaveOfAbsence/LeaveOfAbsenceForm'
import moment from 'moment'

function LeaveOfAbsenceSection({
  patientId,
  timezone,
  consumptionDate,
  onCancel,
  statusMessage,
}) {
  const {
    mutateAsync: createLOA,
    isLoading: isCreatingLOA,
  } = useLeaveOfAbsenceCreateMutation(patientId, statusMessage)

  if (!patientId) return null

  const handleCreateLOA = async ({ startAt, endAt, note }) => {
    const payload = {
      patientId,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await createLOA(payload, {
      onSuccess: () => {
        onCancel()
      },
    })
  }

  return (
    <div className="flex flex-column gap-2">
      <span className="text-xs">
        To change the status of the selected item to leave of absence
        you need to create a leave of absence period.
        This will affect all meds and tasks in that time period, not only the selected one.
      </span>
      <LeaveOfAbsenceForm
        onCancel={onCancel}
        onConfirm={handleCreateLOA}
        isLoading={isCreatingLOA}
        mode="add"
        timezone={timezone}
        defaultStartAt={momentTzWithoutChangingTime(moment(consumptionDate), timezone).startOf('day')}
        defaultEndAt={momentTzWithoutChangingTime(moment(consumptionDate), timezone).endOf('day')}
        showCancel={false}
      />
    </div>
  )
}

export default LeaveOfAbsenceSection
