import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useTimezones = ({ statusMessage }) => useQuery({
  queryKey: ['timezones'],
  queryFn: () => ApiClient.get('/timezones'),
  onError: (error) => displayErrorMessages(error, statusMessage),
})

export default useTimezones
