import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { map, find } from 'lodash'

const displayCondition = (medicalCondition) => {
  const { icdCode, description } = medicalCondition
  return icdCode ? `${icdCode} - ${description}` : `${description}`
}

function DoseDiagnosisField({
  doseSig,
  isEditing,
  newDiagnosis,
  setNewDiagnosis,
  setIsCreationDialogVisible,
}) {
  const { dose: { hrstMedicalConditionId } } = doseSig
  const options = map(doseSig.dose.medicalConditions, (medicalCondition) => ({
    label: displayCondition(medicalCondition),
    value: medicalCondition.id,
  }))

  if (!isEditing) {
    if (hrstMedicalConditionId || doseSig.dose.medicalConditions.length === 1) {
      const medicalCondition = hrstMedicalConditionId
        ? find(doseSig.dose.medicalConditions, { id: hrstMedicalConditionId })
        : doseSig.dose.medicalConditions[0]
      return <span>{displayCondition(medicalCondition)}</span>
    }
    return <span>None</span>
  }

  return (
    <div className="flex flex-column gap-2">
      {doseSig.dose.medicalConditions.length > 1 && (
        <Dropdown
          value={newDiagnosis}
          options={options}
          style={{ maxWidth: '200px' }}
          onChange={(e) => setNewDiagnosis(e.value)}
          placeholder="Select a diagnosis"
        />
      )}
      {doseSig.dose.medicalConditions.length === 1 && (
        <span>{displayCondition(doseSig.dose.medicalConditions[0])}</span>
      )}
      <Button
        label="Add Condition"
        className="p-button-sm"
        onClick={() => setIsCreationDialogVisible(true)}
      />
    </div>
  )
}

export default DoseDiagnosisField
