import React, { useRef, useId } from 'react'
import DropdownWithLabel from '@components/display/DropdownWithLabel'
import useTimezones from '@hooks/timezones'

function TimezoneField({ value, onChange }) {
  const statusMessage = useRef(null)
  const { data: timezones } = useTimezones(statusMessage)
  const options = timezones ? Object.values(timezones).map((tz) => ({ label: tz, value: tz })) : []
  const id = useId()

  return (
    <div className="flex flex-column w-6 gap-2 mb-3">
      <DropdownWithLabel
        inputId={`${id}-timezone`}
        className="h-3rem"
        value={value}
        onChange={onChange}
        options={options}
        optionLabel="label"
        placeholder="Select Timezone"
        filter
      />
    </div>
  )
}

export default TimezoneField
