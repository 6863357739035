import React from 'react'
import moment from 'moment-timezone'
import { isEmpty } from 'lodash'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { useTaskOccurrenceHistory } from '@hooks/taskOccurrences'
import { momentFormats } from '@services/utils/moment'
import { titleize } from '@services/utils/string'
import ColoredCircle from '../ColoredCircle'
import OccurrenceHistory from './OccurrenceHistory'

function OccurrenceDetails({ occurrence, canEdit, onEditClick }) {
  const {
    data: occurrenceWithHistory,
    isLoading,
  } = useTaskOccurrenceHistory({ taskOccurrence: occurrence })

  if (isLoading) {
    return null
  }

  const {
    actionedBy, actionedAt, expectedAt, note, prn, prnReason, status, timezone,
  } = occurrenceWithHistory

  const missedOrRefused = status === 'missed' || status === 'refused'

  return (
    <div className="flex flex-column row-gap-2 m-1">
      {
        status && (
          <div className="flex flex-row gap-1 align-items-center text-left">
            <ColoredCircle type={status} />
            <span className="text-base font-medium line-height-2">
              {titleize(status)}
            </span>
            {
              canEdit && (
                <Button
                  label="Edit"
                  className="p-button-sm p-button-text text-xs p-0 ml-auto"
                  onClick={() => onEditClick(occurrenceWithHistory)}
                />
              )
            }
          </div>
        )
      }
      {
        expectedAt && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">Expected:</span>
            <span className="text-xs font-medium">
              {moment(expectedAt).tz(timezone)
                .format(momentFormats.dateYearTime)}
            </span>
          </div>
        )
      }
      {
        (actionedAt && status !== 'leave_of_absence') && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">{`${titleize(status)}:`}</span>
            <span className="text-xs font-medium">
              {moment(actionedAt).tz(timezone)
                .format(momentFormats.dateYearTime)}
            </span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{actionedBy?.fullName || 'Unspecified'}</span>
          </div>
        )
      }
      {
        ((note || missedOrRefused) && status !== 'leave_of_absence') && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">{missedOrRefused ? 'Reason:' : 'Note:'}</span>
            <span className="text-xs font-medium">{note || 'Unspecified'}</span>
          </div>
        )
      }
      {
        prn && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">PRN Reason:</span>
            <span className="text-xs font-medium">{prnReason || 'Unspecified'}</span>
          </div>
        )
      }
      <OccurrenceHistory occurrence={occurrenceWithHistory} />
    </div>
  )
}

/**
 * Overlay that displays the status of task occurrences.
 *
 * @param {Object} occurrence - The selected cell containing details.
 * @param {boolean} canEdit - If the current user can edit the status.
 * @param {Function} onEditClick - The function to call when the edit button is clicked.
 * @returns {JSX.Element} - JSX element representing the overlay.
 */
function StatusOverlay({
  occurrences,
  canEdit,
  onEditClick,
}) {
  if (isEmpty(occurrences)) {
    return null
  }

  return (
    <div className="w-22rem flex flex-column">
      {
        occurrences.map((occurrence) => (
          <div className="flex flex-column p-1">
            <OccurrenceDetails
              key={occurrence.id}
              occurrence={occurrence}
              // TODO: Allow editing occurrences for custom tasks once the backend fully supports it
              canEdit={canEdit}
              onEditClick={onEditClick}
            />
          </div>
        )).reduce((prev, curr) => [prev, <Divider />, curr])
      }
    </div>
  )
}

export default StatusOverlay
