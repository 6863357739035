import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useCreateScheduledNote = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (scheduledNote) => {
      const params = {
        patientId: scheduledNote.patientId,
        treatmentId: scheduledNote.treatmentId,
        note: scheduledNote.note,
        startAt: scheduledNote.startAt,
        endAt: scheduledNote.endAt,
      }

      return ApiClient.post('/scheduled_notes', params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('treatment')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Scheduled Note Created!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useCreateScheduledNote
