import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { useCurrentUser, useCurrentOrganization } from '@components/App'
import MonthlyRangeExportDialog from '@components/patients/MontlyRangeExportDialog'
import {
  useCreateReportLateDoses,
  useCreateReportMedicationErrors,
  useCreateReportTar,
  useCreateReportClinicalNotes,
  useCreateReportBowelMovements,
  useCreateReportPrnMar,
  useCreateReportMar,
  useCreateReportNarcoticCounts,
  useCreateReportPhysiciansOrders,
} from '@hooks/reports'
import VitalSignsReportDialog from './VitalSignsReportDialog'
import MedicationReleaseReportDialog from './MedicationReleaseReportDialog'
import MonthlyExportDialog from './MontlyExportDialog'

const showExportsMenu = (currentUser, patientId) => {
  if (['superadmin', 'site_admin', 'caregiver'].includes(currentUser.role)) {
    return true
  }

  return currentUser.role === 'patient' && patientId === currentUser.id && currentUser.patientSettings?.selfAdmin
}

function Exports({
  patientId,
  exportsMenuSetupCallBack,
}) {
  const menu = useRef(null)
  const [visibleDialog, setVisibleDialog] = useState('')
  const currentUser = useCurrentUser()
  const { narcoticCountsEnabled } = useCurrentOrganization()

  const isCurrentUserAdminOrCaregiver = ['superadmin', 'site_admin', 'caregiver'].includes(currentUser.role)
  const canExportClinicalNotes = isCurrentUserAdminOrCaregiver
  const canExportNarcoticCounts = narcoticCountsEnabled && isCurrentUserAdminOrCaregiver

  // when adding new actions, please keep the alphabetical order
  const actions = [
    {
      label: 'Bowel Movements Report',
      command: () => setVisibleDialog('bowelMovementsReport'),
      visible: true,
    },
    {
      label: 'Clinical Notes Report',
      command: () => setVisibleDialog('clinicalNotesReport'),
      visible: canExportClinicalNotes,
    },
    {
      label: 'Late Administrations',
      command: () => setVisibleDialog('lateDoses'),
      visible: true,
    },
    {
      label: 'MAR',
      command: () => setVisibleDialog('mar'),
      visible: true,
    },
    {
      label: 'Medication Errors Report',
      command: () => setVisibleDialog('medicationErrorsReport'),
      visible: true,
    },
    {
      label: 'Medication Release',
      command: () => setVisibleDialog('medicationRelease'),
      visible: true,
    },
    {
      label: 'Narcotic Counts',
      command: () => setVisibleDialog('narcoticCounts'),
      visible: canExportNarcoticCounts,
    },
    {
      label: 'Physician Orders',
      command: () => setVisibleDialog('physicianOrders'),
      visible: true,
    },
    {
      label: 'PRN MAR',
      command: () => setVisibleDialog('prnMar'),
      visible: true,
    },
    {
      label: 'TAR',
      command: () => setVisibleDialog('tar'),
      visible: true,
    },
    {
      label: 'Vital Signs',
      command: () => setVisibleDialog('vitalSigns'),
      visible: true,
    },
  ]

  const primaryAction = showExportsMenu(currentUser, patientId) && (
    <div className="flex flex-row gap-2">
      <Menu model={actions} popup ref={menu} id="popup_menu" />
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-file-export"
        onClick={(event) => menu.current.toggle(event)}
        label="Exports"
      />
    </div>
  )

  useEffect(() => {
    exportsMenuSetupCallBack(primaryAction)
  }, [])

  return (
    <>
      <MonthlyExportDialog
        dialogTitle="MAR"
        exportMutation={useCreateReportMar}
        isVisible={visibleDialog === 'mar'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="TAR"
        exportMutation={useCreateReportTar}
        isVisible={visibleDialog === 'tar'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="PRN MAR"
        exportMutation={useCreateReportPrnMar}
        isVisible={visibleDialog === 'prnMar'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="Medication Errors Report"
        exportMutation={useCreateReportMedicationErrors}
        isVisible={visibleDialog === 'medicationErrorsReport'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="Clinical Notes"
        exportMutation={useCreateReportClinicalNotes}
        isVisible={visibleDialog === 'clinicalNotesReport'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="Bowel Movements"
        exportMutation={useCreateReportBowelMovements}
        isVisible={visibleDialog === 'bowelMovementsReport'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="Narcotic Counts"
        exportMutation={useCreateReportNarcoticCounts}
        isVisible={visibleDialog === 'narcoticCounts'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyRangeExportDialog
        dialogTitle="Physician Orders"
        exportMutation={useCreateReportPhysiciansOrders}
        isVisible={visibleDialog === 'physicianOrders'}
        patientId={patientId}
        rangeStart={moment()}
        rangeEnd={moment().add(3, 'months')}
        setIsVisible={setVisibleDialog}
      />
      <VitalSignsReportDialog
        isVisible={visibleDialog === 'vitalSigns'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MedicationReleaseReportDialog
        isVisible={visibleDialog === 'medicationRelease'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
      <MonthlyExportDialog
        dialogTitle="Late Medications"
        exportMutation={useCreateReportLateDoses}
        isVisible={visibleDialog === 'lateDoses'}
        patientId={patientId}
        setIsVisible={setVisibleDialog}
      />
    </>
  )
}

export default Exports
