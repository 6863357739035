import React from 'react'
import { useLeaveOfAbsenceAtTimeQuery, useLeaveOfAbsenceDeleteMutation, useLeaveOfAbsenceUpdateMutation } from '@hooks/leaveOfAbsences'
import { momentFormats } from '@services/utils/moment'
import LeaveOfAbsenceDialog from '@components/display/LeaveOfAbsence/LeaveOfAbsenceDialog'
import moment from 'moment'

function AdherenceLOADialog({
  visible,
  onHide,
  patient,
  time,
  statusMessageRef,
}) {
  const timeMoment = time
    ? moment(time).utc().format(momentFormats.iso8601_utc)
    : null
  const {
    data: loa,
    isLoading: isLOALoading,
  } = useLeaveOfAbsenceAtTimeQuery(patient?.id, timeMoment, statusMessageRef)

  const {
    mutateAsync: updateLOA,
    isLoading: isUpdatingLOA,
  } = useLeaveOfAbsenceUpdateMutation(statusMessageRef)

  const {
    mutateAsync: deleteLOA,
    isLoading: isDeletingLOA,
  } = useLeaveOfAbsenceDeleteMutation(statusMessageRef)

  if (!patient) return null

  const handleUpdateLOA = async ({ startAt, endAt, note }) => {
    const payload = {
      id: loa.id,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await updateLOA(payload, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  const handleDeleteLOA = async () => {
    await deleteLOA(loa.id, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  return (
    <LeaveOfAbsenceDialog
      patient={patient}
      visible={visible}
      onHide={onHide}
      onConfirm={handleUpdateLOA}
      leaveOfAbsenceToEdit={loa}
      onDelete={handleDeleteLOA}
      isLoading={isLOALoading || isUpdatingLOA || isDeletingLOA}
      showDeleteButton
    />
  )
}

export default AdherenceLOADialog
