import React, { useState, useEffect } from 'react'
import { get, isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { Divider } from 'primereact/divider'
import { DataTable } from 'primereact/datatable'
import { TreatmentCategoryTag, TreatmentStatusTag, TreatmentTypeTag } from '@components/treatments/tags'
import SearchField from '@components/display/Form/SearchField'
import { useTreatments } from '@hooks/treatments'
import { momentFormats } from '@services/utils/moment'
import { fuzzySearch } from '@services/utils/search'

const appliedFilters = (filters) => filters.reduce((result, filter) => {
  const newResult = result
  newResult[filter.name] = {
    value: filter.value,
    matchMode: FilterMatchMode.EQUALS,
  }
  return newResult
}, {})

const dateCellTemplate = (dateString, timezone) => (
  <div className="flex flex-column gap-2">
    {dateString ? moment(dateString).tz(timezone).format(momentFormats.dateYear) : null}
  </div>
)

const treatmentCategoryTemplate = (treatment) => (<TreatmentCategoryTag treatment={treatment} />)
const treatmentStatusTemplate = (treatment) => (<TreatmentStatusTag treatment={treatment} />)
const treatmentTypeTemplate = (treatment) => (<TreatmentTypeTag treatment={treatment} />)

const instructionsTemplate = (treatment) => {
  if (treatment.scheduledNote?.note) {
    return treatment.scheduledNote.note
  }

  return get(treatment, 'tasks[0].description', null)
}

function TreatmentList({ patient, filters = [] }) {
  const navigate = useNavigate()
  const [treatments, setTreatments] = useState([])
  const [searchTerm, setSearchTerm] = useState()

  const query = useTreatments(patient.id)

  useEffect(() => {
    if (!isEmpty(query.data)) {
      setTreatments(fuzzySearch(query.data, ['instructions', 'note'], searchTerm, 0.9))
    }
  }, [query.data, searchTerm])

  return (
    <div className="flex flex-column gap-3 fadein">
      <SearchField
        className="w-19rem"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search by instructions"
      />
      <Divider className="my-0" />
      <DataTable
        dataKey="id"
        loading={query.isLoading}
        value={treatments}
        filters={appliedFilters(filters)}
        sortField="categoryFriendlyName"
        sortOrder={1}
        selectionMode="single"
        onSelectionChange={({ value }) => navigate(`../manage/${value.id}`)}
        removableSort
        scrollable
      >
        <Column
          header="Status"
          field="status"
          sortField="status"
          sortable
          body={treatmentStatusTemplate}
        />
        <Column
          header="Category"
          field="categoryFriendlyName"
          body={treatmentCategoryTemplate}
          sortable
        />
        <Column
          header="Order Number"
          field="orderNumber"
          sortable
        />
        <Column
          header="Instructions"
          body={instructionsTemplate}
          className="w-4"
          sortable
        />
        <Column
          header="Start"
          field="startAt"
          body={({ startAt }) => dateCellTemplate(startAt, patient.timezone)}
          sortable
        />
        <Column
          header="End"
          field="endAt"
          body={({ endAt }) => dateCellTemplate(endAt, patient.timezone)}
          sortable
        />
        <Column
          header="Discontinued"
          field="discontinuedAt"
          body={({ discontinuedAt }) => dateCellTemplate(discontinuedAt, patient.timezone)}
          sortable
        />
        <Column
          header="Type"
          field="treatmentType"
          body={treatmentTypeTemplate}
        />
      </DataTable>
    </div>
  )
}

export default TreatmentList
