import { formattedTextsFromSchedules } from '@components/clientDoses/doseUtils'
import { Column } from '@components/display/Box/Box.stories'
import { momentFormats, momentFromIso8601Str } from '@services/utils/moment'
import { isEmpty } from 'lodash'
import { DataTable } from 'primereact/datatable'
import React from 'react'

export default function RawSigsTable({
  sigs,
  showDosing = true,
}) {
  // dosing template will contain administration amount, units, and quantity
  const dosingTemplate = (rowData) => (
    <div className="flex flex-column gap-1 text-sm">
      <div>
        Administration Amount:
        {' '}
        {rowData.administrationAmount}
      </div>
      <div>
        Units:
        {' '}
        {rowData.units}
      </div>
    </div>
  )

  const schedulesTemplate = (rowData) => {
    if (rowData.prn) {
      return 'PRN'
    }

    if (isEmpty(rowData.schedules)) {
      return 'No Schedules'
    }

    const unsupportedSchedules = []
    const validSchedules = []
    rowData.schedules.forEach((schedule) => {
      if (schedule.eventType === 'unsupported') {
        unsupportedSchedules.push(schedule)
      } else {
        validSchedules.push(schedule)
      }
    })

    return (
      <div className="flex flex-column gap-1 text-sm">
        {(validSchedules.length > 0) && formattedTextsFromSchedules(validSchedules)}
        {unsupportedSchedules.length > 0
          && (
          <div>
            <i className="pi pi-exclamation-triangle text-orange-500 text-xl mr-2" />
            {`${unsupportedSchedules.length} Unsupported`}
          </div>
          )}
      </div>
    )
  }

  return (
    <DataTable
      dataKey="id"
      value={sigs}
      title="Sigs"
      size="small"
    >
      <Column field="id" header="ID" />
      <Column field="startAt" header="Start" body={(rowData) => (rowData.startAtWall ? momentFromIso8601Str(rowData.startAtWall).format(momentFormats.datetime_12) : '')} />
      <Column field="endAt" header="End" body={(rowData) => (rowData.endAtWall ? momentFromIso8601Str(rowData.endAtWall).format(momentFormats.datetime_12) : '')} />
      {
        showDosing && (
          <Column field="dosing" header="Dosing" body={dosingTemplate} />
        )
      }
      <Column field="instructions" header="Instructions" />
      <Column field="schedules" header="Schedules" body={schedulesTemplate} />
    </DataTable>
  )
}
