import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { formatDateTimeLocale } from '@services/utils'

import { isEmpty } from 'lodash'
import { momentFormats } from '@services/utils/moment'
import {
  useDoseHolds,
  useDoseHoldCreate,
  useDoseHoldDelete,
  useDoseHoldUpdate,
} from '@hooks/doseHolds'
import DoseHoldDialog from '@components/DoseHold/DoseHoldDialog'

function HoldHistory({
  doseId, statusMessageRef, timezone,
}) {
  const [showHoldDialog, setShowHoldDialog] = useState(false)
  const [holdToEdit, setHoldToEdit] = useState(null)

  const {
    data: holds,
    isLoading: isHoldsLoading,
  } = useDoseHolds(doseId)

  const {
    mutateAsync: createHold,
    isLoading: isCreatingHold,
  } = useDoseHoldCreate(doseId, statusMessageRef)

  const {
    mutateAsync: updateHold,
    isLoading: isUpdatingHold,
  } = useDoseHoldUpdate(statusMessageRef)

  const {
    mutateAsync: deleteHold,
    isLoading: isDeletingHold,
  } = useDoseHoldDelete(statusMessageRef)

  const handleCreateHold = async ({ startAt, endAt, note }) => {
    if (!startAt) {
      statusMessageRef.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Start Date is required',
      })
      return
    }
    const payload = {
      doseId,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt?.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await createHold(payload, {
      onSuccess: () => {
        setShowHoldDialog(false)
      },
    })
  }

  const handleUpdateHold = async ({ startAt, endAt, note }) => {
    const payload = {
      id: holdToEdit.id,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt?.utc().format(momentFormats.iso8601_utc),
      note,
    }
    await updateHold(payload, {
      onSuccess: () => {
        setShowHoldDialog(false)
        setHoldToEdit(null)
      },
    })
  }

  const handleDeleteHold = async (hold) => {
    await deleteHold(hold.id, {
      onSuccess: () => {
        setShowHoldDialog(false)
        setHoldToEdit(null)
      },
    })
  }

  const handleEdit = (hold) => {
    setShowHoldDialog(true)
    setHoldToEdit(hold)
  }

  const handleHide = () => {
    setShowHoldDialog(false)
    setHoldToEdit(null)
  }

  const renderEditButton = (hold) => (
    <Button
      icon="pi pi-pencil"
      className="p-button-outlined p-button-text text-xs p-1"
      label="Edit"
      type="button"
      onClick={() => handleEdit(hold)}
    />
  )

  return (
    <>
      <div className="flex flex-row justify-content-end align-items-center mb-2 text-lg">
        <Button
          label="Add Hold"
          className="p-button-sm"
          type="button"
          icon="pi pi-plus"
          onClick={() => setShowHoldDialog(true)}
        />
      </div>
      <DoseHoldDialog
        timezone={timezone}
        visible={showHoldDialog}
        onHide={handleHide}
        onConfirm={holdToEdit ? handleUpdateHold : handleCreateHold}
        onDelete={holdToEdit ? () => handleDeleteHold(holdToEdit) : null}
        showDeleteButton={!isEmpty(holdToEdit)}
        holdToEdit={holdToEdit}
        isLoading={isHoldsLoading || isUpdatingHold || isDeletingHold}
        statusMessage={statusMessageRef}
      />
      <DataTable
        dataKey="id"
        className="fadein"
        loading={isCreatingHold || isUpdatingHold || isDeletingHold || isHoldsLoading}
        sortField="startAt"
        sortOrder={1}
        sortMode="single"
        value={holds}
      >
        <Column
          className="w-3"
          header="Start Date"
          field="startAt"
          body={({ startAt }) => formatDateTimeLocale(startAt, timezone)}
        />
        <Column
          className="w-3"
          header="End Date"
          field="endAt"
          body={({ endAt }) => formatDateTimeLocale(endAt, timezone)}
        />
        <Column className="w-4" header="Note" field="note" />
        <Column className="w-2" body={renderEditButton} />
      </DataTable>
    </>
  )
}

export default HoldHistory
