import React from 'react'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { formatDateTimeLocale } from '@services/utils'
import { consumptionDisplayNameWithStrength } from '@components/clientDoses/doseUtils'
import { ExportConsumptionsButton } from '../../../features/ExportConsumptionsButton/ExportConsumptionsButton'

function ConsumptionsTable({
  title = 'Consumptions',
  consumptions,
  isLoading,
  maxDataViewHeight,
  userId,
  userRole,
  showExportButton = true,
  patientLabel,
  showPatientName,
}) {
  const statusTemplate = ({ status }) => {
    let color
    let text
    if (status === 'confirmed') {
      text = 'On Time'
      color = '#76db9b'
    } else if (status === 'missed') {
      text = 'Missed'
      color = '#ff8780'
    } else if (status === 'refused') {
      text = 'Refused'
      color = '#9fa9b7'
    } else if (status === 'LOA') {
      text = 'LOA'
      color = '#bab6b8'
    }

    return (
      <Tag style={{ background: color }}>
        {text}
      </Tag>
    )
  }

  return (
    <Card className="h-full">
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center">
          <span className="text-lg">
            <i className="pi pi-list mr-2" />
            {title}
          </span>
          {
            showExportButton && <ExportConsumptionsButton userId={userId} role={userRole} />
          }
        </div>
        <Divider className="my-0" />
        <DataTable
          className="fadein"
          dataKey="id"
          loading={isLoading}
          value={consumptions}
          scrollable
          scrollHeight={maxDataViewHeight - 40}
        >
          <Column
            header="Status"
            field="status"
            style={{ minWidth: '6rem' }}
            body={statusTemplate}
          />
          {
            showPatientName && (
              <Column
                header={patientLabel}
                field="patient.fullName"
                style={{ minWidth: '8rem' }}
              />
            )
          }
          <Column
            header="Medicine"
            style={{ minWidth: '10rem', wordBreak: 'break-word' }}
            body={(consumption) => consumptionDisplayNameWithStrength(consumption)}
          />
          <Column
            header="Expected At"
            field="expectedAt"
            style={{ minWidth: '11rem' }}
            body={({ expectedAt, timezone }) => (expectedAt
              ? formatDateTimeLocale(expectedAt, timezone) : null)}
          />
          <Column
            header="Confirmed At"
            field="confirmedAt"
            style={{ minWidth: '11rem' }}
            body={({ confirmedAt, timezone }) => (confirmedAt
              ? formatDateTimeLocale(confirmedAt, timezone) : null)}
          />
          <Column
            header="Confirmed By"
            field="confirmedBy.fullName"
            style={{ minWidth: '8rem' }}
          />
          <Column
            header="Reason Missed"
            field="missedReason"
            style={{ minWidth: '10rem' }}
          />
        </DataTable>
      </div>
    </Card>
  )
}

export default ConsumptionsTable
