import React, { useState } from 'react'
import { Button } from 'primereact/button'
import {
  StatusDropdown,
} from './Form'
import LeaveOfAbsenceSection from './Form/LeaveOfAbsenceSection'
import DoseHoldSection from './Form/DoseHoldSection'
import StatusFormSection from './Form/StatusFormSection'

function AdherenceForm({
  mode,
  formData,
  setFormData,
  onSubmit,
  onHide,
  isLoading,
  statusMessage,
  patientId,
  timezone,
  doseId,
  cellTime,
  canEditTime = true,
  showConditionChecks = true,
  showDateTimeInput = true,
  showNotes = false,
  showReasonInput = false,
  showReasonDropdown = false,
  showInjectionSiteDropdown = false,
  showStatusDropdown = false,
  showUserDropdown = false,
  submitButtonLabel = 'Save',
}) {
  const [deactivatedUserAgreed, setDeactivatedUserAgreed] = useState(false)

  if (!cellTime) {
    return null
  }

  const selectedDateFormatted = cellTime.format('MM/DD/YYYY')

  const showDeactivatedUserConfirmation = formData.administeredBy
    && formData.administeredBy.currentUserActivation?.deactivatedAt

  const formIsInvalid = !formData.status
    || (showUserDropdown && !formData.administeredBy)
    || (showNotes && formData.note.length === 0)
    || (showReasonInput && !formData.reason)
    || (showReasonDropdown && !formData.reason)
    || (showDeactivatedUserConfirmation && !deactivatedUserAgreed)

  const userDropdownLabel = formData.status === 'on_hold' ? 'Held By' : 'Administered By'

  const handleStatusChange = (status) => {
    // When the status is changed, we also reset the time to the default time.
    // This is particularly relevant for statuses that disable the time input.
    const time = formData.defaultTime

    setFormData({
      ...formData,
      status,
      time,
      reason: '',
      note: '',
    })
  }

  const renderStatusSection = () => {
    switch (formData.status) {
      case 'leave_of_absence':
        return (
          <LeaveOfAbsenceSection
            mode={mode}
            patientId={patientId}
            timezone={timezone}
            consumptionDate={formData.defaultTime}
            onCancel={onHide}
            statusMessage={statusMessage}
          />
        )
      case 'on_hold':
        return (
          <DoseHoldSection
            mode={mode}
            doseId={doseId}
            timezone={timezone}
            consumptionDate={formData.defaultTime}
            onCancel={onHide}
            statusMessage={statusMessage}
          />
        )
      default:
        return (
          <StatusFormSection
            // posible mode values before split are: mar_prn, tar_prn, mar_routine and tar_routine
            mode={mode.split('_')[0]}
            formData={formData}
            setFormData={setFormData}
            showUserDropdown={showUserDropdown}
            userDropdownLabel={userDropdownLabel}
            selectedDateFormatted={selectedDateFormatted}
            canEditTime={canEditTime}
            showDateTimeInput={showDateTimeInput}
            showReasonInput={showReasonInput}
            showReasonDropdown={showReasonDropdown}
            showInjectionSiteDropdown={showInjectionSiteDropdown}
            showNotes={showNotes}
            showConditionChecks={showConditionChecks}
            deactivatedUserAgreed={deactivatedUserAgreed}
            setDeactivatedUserAgreed={setDeactivatedUserAgreed}
            statusMessage={statusMessage}
            patientId={patientId}
          />
        )
    }
  }

  return (
    <div className="flex flex-column w-full gap-3">
      {
        showStatusDropdown && (
          <StatusDropdown
            mode={mode}
            status={formData.status}
            setStatus={handleStatusChange}
          />
        )
      }
      {renderStatusSection()}
      <Button
        label={submitButtonLabel}
        className="p-button-sm w-full mt-2"
        disabled={formIsInvalid}
        onClick={onSubmit}
      >
        {isLoading && <i className="pi pi-spin pi-spinner text-lg" />}
      </Button>
    </div>
  )
}

export default AdherenceForm
