import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useSaveSlotConfigQuery(medBoxId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (simpillDrawers) => ApiClient.post(`simpills/${medBoxId}/save_slot_config`, { simpill: { simpillDrawers } }),
    onSuccess: () => {
      queryClient.invalidateQueries('simpill')
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Ekit saved successfully',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useSaveSlotConfigQuery
