import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { injectionSiteOptions } from './config'
import InjectionSite from './InjectionSite'

const injectionSiteTemplate = (injectionSiteOption) => (
  <div className="flex flex-row gap-2">
    <div className="mr-3 flex-shrink-0" style={{ width: '4rem' }}>
      <strong>{injectionSiteOption.code}</strong>
    </div>
    <div className="flex flex-column">
      <span>{injectionSiteOption.description}</span>
    </div>
  </div>
)

const valueTemplate = (selectedValue) => {
  if (!selectedValue) {
    return (
      <div className="flex flex-row gap-2">
        <i className="fa-solid fa-syringe" />
        <span>Select injection site</span>
      </div>
    )
  }

  return (
    <InjectionSite value={selectedValue?.value || selectedValue} />
  )
}

function InjectionSiteDropdown({
  onChange,
  value,
}) {
  return (
    <Dropdown
      id="injection-site-dropdown"
      itemTemplate={injectionSiteTemplate}
      onChange={(e) => onChange(e.value)}
      options={injectionSiteOptions}
      optionLabel="description"
      placeholder="Select injection site"
      showClear
      value={value}
      valueTemplate={valueTemplate}
    />
  )
}

export default InjectionSiteDropdown
