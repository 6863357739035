import { useCurrentUser } from '@components/App'
import React from 'react'
import { Button } from 'primereact/button'
import VitalsDashboard from './VitalsDashboard/VitalsDashboard'

function TypeNavigator({ granularity, setApprovalDialogVisible }) {
  const currentUser = useCurrentUser()
  return (
    <div className="flex flex-row gap-3 align-items-center w-full">
      <div className="flex-grow-1" />
      {
        currentUser.role === 'site_admin' && granularity === 'Monthly' && (
          <Button
            label="Approve"
            className="p-button-primary"
            onClick={() => setApprovalDialogVisible(true)}
          />
        )
      }
    </div>
  )
}

function Vitals({
  patient,
  startDate,
  endDate,
  granularity,
}) {
  const dateRange = { startDate, endDate }

  return (
    <div className="flex flex-column gap-3">
      <TypeNavigator
        granularity={granularity}
      />
      <VitalsDashboard
        patient={patient}
        dateRange={dateRange}
      />
    </div>
  )
}

export default Vitals
