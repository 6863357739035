import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { useCurrentUser } from '@components/App'
import { usePrescriptions } from '@hooks/prescriptions'
import { useReportSentryError } from '@hooks/useReportSentryError'
import { momentFormats } from '@services/utils/moment'
import UnlinkPrescriptionDialog from './UnlinkPrescriptionDialog'

const getPrescriptionStatus = (prescription) => {
  if (prescription.active) {
    return 'Active'
  }

  if (prescription.discontinuedAt) {
    return 'Discontinued'
  }

  if (prescription.deletedAt) {
    return 'Deleted'
  }

  return 'Unknown'
}

function timeTemplate(field, timezone, placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        {value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder}
      </div>
    )
  }
}

const filterPrescriptions = (filter, prescriptions = []) => {
  const activePrescriptions = prescriptions.filter(({ active }) => active)

  switch (filter) {
    case 'all':
      return prescriptions
    case 'active':
      return activePrescriptions
    case 'needs_review':
      return prescriptions.filter(({ needsReview }) => needsReview)
    case 'no_refills':
      return activePrescriptions.filter(({ refillsRemaining }) => refillsRemaining === 0)
    case 'low_refills':
      return activePrescriptions.filter(({ refillsRemaining }) => refillsRemaining <= 2)
    case 'unlinked':
      return prescriptions.filter(({ doseId }) => !doseId)
    case 'discontinued':
      return prescriptions.filter(({ discontinuedAt }) => discontinuedAt)
    case 'deleted':
      return prescriptions.filter(({ deletedAt }) => deletedAt)
    default:
      return []
  }
}

function PrescriptionsTable({
  model,
  timezone,
  id,
  filter = 'all',
  allowUnlink = false,
  defaultRows = 10,
}) {
  const messagesRef = useRef()
  const navigate = useNavigate()
  const [selectedPrescription, setSelectedPrescription] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const { data: prescriptions, error, isLoading } = usePrescriptions({ model, id })
  const reportSentryError = useReportSentryError([model, id])

  if (error) {
    reportSentryError(`Error getting prescriptions for ${model} ${id}: ${JSON.stringify(error)}`)
    return (<div>Error getting prescriptions.</div>)
  }

  const filteredPrescriptions = filterPrescriptions(filter, prescriptions)
  const actionsTemplate = (rowData) => {
    if (!allowUnlink && !rowData.doseId) {
      return null
    }

    return (
      <Button
        icon="fa-solid fa-unlink"
        className="p-button-text p-button-sm"
        onClick={() => {
          setSelectedPrescription(rowData)
          setShowDialog(true)
        }}
      />
    )
  }

  const handlePrescriptionSelection = (prescription) => {
    navigate(`/admin/patients/${prescription.patientId}/prescriptions/manage/${prescription.id}`)
  }

  const currentUser = useCurrentUser()
  const superAdmin = currentUser.role === 'superadmin'
  const siteAdmin = currentUser.role === 'site_admin'
  const siteAdminOrSuperAdmin = siteAdmin || superAdmin
  if (!siteAdminOrSuperAdmin) {
    return null
  }
  return (
    <>
      <Toast ref={messagesRef} />
      <UnlinkPrescriptionDialog
        isVisible={showDialog}
        prescriptionId={selectedPrescription?.id}
        messagesRef={messagesRef}
        onHide={() => setShowDialog(false)}
      />
      <DataTable
        dataKey="id"
        emptyMessage="No prescriptions found"
        loading={isLoading}
        paginator
        removableSort
        rows={defaultRows}
        rowsPerPageOptions={[5, 10, 25, 50]}
        selectionMode={(superAdmin && 'single') || null}
        onSelectionChange={
          ({ value: prescription }) => superAdmin && handlePrescriptionSelection(prescription)
        }
        value={filteredPrescriptions}
      >

        <Column
          field="prescriptionNumber"
          filter
          filterField="prescriptionNumber"
          filterPlaceholder="Search by Rx"
          header="Prescription Number"
          sortable
        />

        <Column
          body={getPrescriptionStatus}
          field="status"
          header="Status"
        />

        {siteAdmin && (
          <Column
            field="prescriberFullName"
            header="Prescriber"
          />
        )}

        {siteAdmin && (
          <Column
            field="prescriberIdNumber"
            header="Prescriber NPI"
          />
        )}

        {siteAdmin && (
          <Column
            field="pharmacy.name"
            header="Pharmacy"
          />
        )}

        {superAdmin && (
          <Column
            field="refillsRemaining"
            header="Refills Remaining"
          />
        )}

        {superAdmin && (
          <Column
            body={timeTemplate('createdAt', timezone)}
            field="createdAt"
            header="Created"
            sortable
          />
        )}

        {siteAdmin && (
          <Column
            body={timeTemplate('updatedAt', timezone)}
            field="updatedAt"
            header="Last Updated"
            sortable
          />
        )}

        <Column
          body={timeTemplate('discontinuedAt', timezone)}
          field="discontinuedAt"
          header="Discontinued"
          sortable
        />

        {superAdmin && (
          <Column
            body={timeTemplate('deletedAt', timezone)}
            field="deletedAt"
            header="Deleted"
          />
        )}

        {superAdmin && (
          <Column
            body={actionsTemplate}
            field="actions"
          />
        )}
      </DataTable>
    </>
  )
}

export default PrescriptionsTable
