import React, { useState } from 'react'
import {
  map, sortBy, uniq,
} from 'lodash'
import moment from 'moment/moment'
import {
  AdministrationAmountEditor, DateEditor, DoseAttributeRow, EditButton, DropdownEditor,
} from '@components/DoseAttributeRow'
import { AddTimeEditor, PrnEditor, ScheduleEditor } from '@components/pharmacyOrders/Editors'
import { momentFormats, momentFromMomentDateAndTime, getMomentFromMinutes } from '@services/utils/moment'
import { formatAdministrationAmount } from '@components/clientDoses/doseUtils'
import { convertTimeToMinutes } from '@services/utils/time'
import { RRule } from 'rrule'
import {
  useReviewableOrderHandlers,
  useReviewableOrderState,
} from '../../../../features/ClientDoses/PharmacyOrders/reviewableOrderState/ReviewableOrderContext'

function buildAvailableTimesForPharmacyOrders(schedules, startAt, endAt) {
  const availableTimes = uniq(schedules.flatMap((schedule) => {
    const rruleOptions = RRule.parseString(schedule.rruleForDose)

    const dtstart = moment(startAt)
    dtstart.hour(schedule.time.hour())
    dtstart.minute(schedule.time.minute())
    dtstart.second(schedule.time.second())
    rruleOptions.dtstart = dtstart.toDate()

    if (endAt) {
      const until = moment(endAt)
      until.hour(schedule.time.hour())
      until.minute(schedule.time.minute())
      until.second(schedule.time.second())
      rruleOptions.until = until.toDate()
    }

    const endBetween = moment(dtstart).add(2, 'days')

    const occurrences = new RRule(rruleOptions).between(dtstart.toDate(), endBetween.toDate())
    return occurrences.map((occurrence) => moment(occurrence).format('hh:mm a'))
  })).map((formattedTime) => ({
    formattedTime,
    timeInMinutes: convertTimeToMinutes(formattedTime),
  }))

  return sortBy(availableTimes, 'timeInMinutes')
}

function SigFieldGroup({ compositeOrderSig, doseSig }) {
  const [editAttribute, setEditAttribute] = useState(null)

  const {
    handleAddSchedule,
    handleDeleteSchedule,
    handleSetPrn,
    handleUpdateAdministrationAmount,
    handleUpdateEndAt,
    handleUpdateSchedule,
    handleUpdateStartAt,
    initializeSchedules,
    handleUpdateStartAdministrationTime,
    handleUpdateEndAdministrationTime,
  } = useReviewableOrderHandlers()

  const { medicine, sigs } = useReviewableOrderState()

  const stateSig = sigs?.find((sig) => sig.compositeOrderSigId === compositeOrderSig.id)

  if (!stateSig) {
    return null
  }

  const {
    administrationAmount,
    id: sigId,
    prn,
    schedules,
    startAt,
    endAt,
    startAdministrationTime,
    endAdministrationTime,
  } = stateSig

  // end at can only be in the future and after start at in case start is also in the future
  let minEndAt = startAt || moment().startOf('day')
  const tomorrowStart = moment().add(1, 'day').startOf('day')
  if (minEndAt.isBefore(tomorrowStart)) {
    minEndAt = tomorrowStart
  }

  let availableTimes = []
  if (schedules) {
    availableTimes = buildAvailableTimesForPharmacyOrders(schedules, startAt, endAt)
  }

  const displayAdministrationTimes = !prn && availableTimes?.length > 1

  const sigAttributes = [
    {
      label: 'Instructions',
      order: 2,
      currentValueLabel: 'Pharmacy Order',
      currentValue: compositeOrderSig?.instructions,
      newValueLabel: 'Dose',
      newValue: doseSig?.instructions,
    },
    {
      label: 'Administration Amount',
      order: 4,
      newValue: formatAdministrationAmount({
        administrationAmount: compositeOrderSig?.administrationAmount,
        medicine,
      }),
      currentValue: formatAdministrationAmount({
        administrationAmount,
        medicine,
      }),
      valueChangeHandler: () => (
        handleUpdateAdministrationAmount({
          id: sigId,
          administrationAmount: compositeOrderSig?.administrationAmount,
        })
      ),
      editor: (
        <AdministrationAmountEditor
          medicine={medicine}
          units={compositeOrderSig?.units}
          administrationAmount={administrationAmount}
          setAdministrationAmount={(value) => {
            handleUpdateAdministrationAmount({ id: sigId, administrationAmount: value })
            setEditAttribute(null)
          }}
        />
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'administrationAmount' ? setEditAttribute(null) : setEditAttribute('administrationAmount'))}
          isEditing={editAttribute === 'administrationAmount'}
        />
      ),
      isEditing: editAttribute === 'administrationAmount',
    },
    {
      label: 'Start Date',
      order: 6,
      newValue: compositeOrderSig?.startAt ? compositeOrderSig?.startAt.format(momentFormats.monthDay) : '',
      currentValue: startAt ? startAt.format(momentFormats.monthDay) : '',
      valueChangeHandler: () => (
        handleUpdateStartAt({ id: sigId, startAt: compositeOrderSig?.startAt })
      ),
      editor: (
        <DateEditor
          momentDate={startAt}
          max={endAt ? moment(endAt) : null}
          setMomentDate={(value) => {
            const timeMoment = startAt || moment().startOf('day')
            const newStartAt = momentFromMomentDateAndTime(value, timeMoment)
            handleUpdateStartAt({
              id: sigId,
              startAt: newStartAt,
              startAtWall: newStartAt.format(momentFormats.iso8601),
            })
            setEditAttribute(null)
          }}
        />
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      order: 7,
      newValue: compositeOrderSig?.endAt ? compositeOrderSig?.endAt.format(momentFormats.monthDay) : '',
      currentValue: endAt ? endAt.format(momentFormats.monthDay) : '',
      valueChangeHandler: () => (
        handleUpdateEndAt({ id: sigId, endAt: compositeOrderSig?.endAt })
      ),
      editor: (
        <DateEditor
          momentDate={endAt}
          min={minEndAt}
          showButtonBar
          setMomentDate={(value) => {
            if (!value) {
              handleUpdateEndAt({ id: sigId, endAt: null, endAtWall: null })
            } else {
              const timeMoment = endAt || moment().endOf('day')
              const newEndAt = momentFromMomentDateAndTime(value, timeMoment)
              handleUpdateEndAt({
                id: sigId,
                endAt: newEndAt,
                endAtWall: newEndAt.format(momentFormats.iso8601),
              })
            }

            setEditAttribute(null)
          }}
        />
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
    {
      label: 'PRN',
      order: 8,
      newValue: compositeOrderSig?.prn ? 'YES' : 'NO',
      currentValue: prn ? 'YES' : 'NO',
      valueChangeHandler: () => (
        handleSetPrn({ id: sigId, prn: compositeOrderSig?.prn })
      ),
      action: (
        <PrnEditor prn={prn} setPrn={(value) => handleSetPrn({ id: sigId, prn: value })} />
      ),
    },
    {
      label: 'Schedule',
      order: 9,
      currentValue: (
        <ScheduleEditor
          orderSchedules={compositeOrderSig?.schedules}
          invalidSchedules={compositeOrderSig?.invalidSchedules}
          schedules={schedules}
          initializeSchedules={initializeSchedules(sigId)}
          handleAddSchedule={({ schedule }) => handleAddSchedule(sigId)(schedule)}
          handleUpdateSchedule={({ schedule }) => handleUpdateSchedule(sigId)(schedule)}
          handleDeleteSchedule={({ schedule }) => handleDeleteSchedule(sigId)(schedule)}
        />
      ),
      action: (
        <AddTimeEditor
          handleCreateSchedule={({ schedule }) => handleAddSchedule(sigId)(schedule)}
          schedulableType="DoseSig"
        />
      ),
    },
  ]

  if (displayAdministrationTimes) {
    sigAttributes.push({
      label: 'First Administration Time',
      currentValue: startAdministrationTime ? getMomentFromMinutes(startAdministrationTime).format('hh:mm a') : null,
      action: <EditButton
        onClick={() => (editAttribute === 'startAdministrationTime' ? setEditAttribute(null) : setEditAttribute('startAdministrationTime'))}
        isEditing={editAttribute === 'startAdministrationTime'}
        disabled={editAttribute && editAttribute !== 'startAdministrationTime'}
      />,
      editor: <DropdownEditor
        option={startAdministrationTime}
        options={availableTimes}
        optionLabel="formattedTime"
        setOption={(newValue) => {
          handleUpdateStartAdministrationTime({
            id: sigId,
            startAdministrationTime: newValue?.timeInMinutes,
          })
          setEditAttribute(null)
        }}
        showClear
      />,
      isEditing: editAttribute === 'startAdministrationTime',
    })

    sigAttributes.push({
      label: 'End Administration Time',
      currentValue: endAdministrationTime ? getMomentFromMinutes(endAdministrationTime).format('hh:mm a') : null,
      action: <EditButton
        onClick={() => (editAttribute === 'endAdministrationTime' ? setEditAttribute(null) : setEditAttribute('endAdministrationTime'))}
        isEditing={editAttribute === 'endAdministrationTime'}
        disabled={editAttribute && editAttribute !== 'endAdministrationTime'}
      />,
      editor: <DropdownEditor
        option={endAdministrationTime}
        options={availableTimes}
        optionLabel="formattedTime"
        setOption={(newValue) => {
          handleUpdateEndAdministrationTime({
            id: sigId,
            endAdministrationTime: newValue?.timeInMinutes,
          })
          setEditAttribute(null)
        }}
        showClear
      />,
      isEditing: editAttribute === 'endAdministrationTime',
    })
  }

  const sortedAttributes = sortBy(sigAttributes, 'order')

  return (
    <ul className="list-none p-0 m-0 dose-order-fields">
      {map(sortedAttributes, ({
        label, currentValue, newValue, action,
        valueChangeHandler, editor, isEditing,
        currentValueLabel, newValueLabel, warning,
      }, index) => (
        <DoseAttributeRow
          key={label}
          label={label}
          warning={warning}
          valueChangeHandler={valueChangeHandler}
          currentValue={currentValue}
          currentValueLabel={currentValueLabel}
          newValue={newValue}
          newValueLabel={newValueLabel}
          action={action}
          editor={editor}
          isEditing={isEditing}
          backgroundHighlight={index % 2 === 0}
        />
      ))}
    </ul>
  )
}

export default SigFieldGroup
