import React from 'react'
import { Button } from 'primereact/button'

function DoseEditActions({
  doseSig,
  editingSig,
  loading,
  onEdit,
  onEditCancel,
  onEditSave,
}) {
  const { id } = doseSig

  if (editingSig?.id === id) {
    return (
      <div className="flex flex-column gap-2">
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text"
          onClick={onEditSave}
          loading={loading}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text"
          onClick={onEditCancel}
        />
      </div>
    )
  }
  return (
    <Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-text"
      onClick={onEdit}
    />
  )
}

export default DoseEditActions
