import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { AdherenceForm } from '@components/patients/AdherenceDashboard/Dialog'
import { useUpsertConsumption } from '@hooks/consumptions'
import { useUpsertMeasurements } from '@hooks/measurements'
import { generateFormattedDateTimeString } from '@services/utils'
import { momentToDatePreservingTime } from '@services/utils/moment'
import { getAdministrationEventCode } from './utils'

function MarAdherenceForm({
  onHide,
  consumption,
  cellTime,
  marTimeRow,
  timezone,
  mode,
  patientId,
  statusMessage,
}) {
  const [formData, setFormData] = useState({})

  const {
    isLoading,
    mutateAsync: upsertConsumption,
  } = useUpsertConsumption({
    consumptionId: consumption?.id,
    chained: true,
    statusMessage,
  })

  const {
    mutateAsync: upsertMeasurements,
  } = useUpsertMeasurements({ mode: 'mar', patientId, statusMessage })

  useEffect(() => {
    setFormData({
      administeredBy: consumption?.confirmedBy || '',
      reason: consumption?.refusedReason || consumption?.missedReason || '',
      status: consumption?.status || '',
      note: consumption?.note || '',
      time: consumption
        ? momentToDatePreservingTime(moment(
          consumption.confirmedAt || consumption.expectedAt,
        ), timezone)
        : momentToDatePreservingTime(cellTime, timezone),
      defaultTime: consumption && consumption.expectedAt
        ? momentToDatePreservingTime(moment(consumption.expectedAt), timezone)
        : momentToDatePreservingTime(cellTime, timezone),
      conditions: marTimeRow?.doseConditions || [],
      conditionChecks: (consumption?.conditionChecks || [])
        .map((cc) => ({ ...cc, condition: cc.doseCondition })),
      injectionSite: consumption?.injection?.site,
    })
  }, [consumption, cellTime, marTimeRow, timezone])

  if (!cellTime || !marTimeRow) {
    return null
  }

  const isAddMode = mode === 'add'
  const isPrnMode = isAddMode || marTimeRow.doseSig.prn
  const canEditTime = ['confirmed', 'refused'].includes(formData.status)
  const showReasonInput = formData.status === 'missed'
  const showReasonDropdown = formData.status === 'refused'
  const showStatusDropdown = isAddMode || ['confirmed', 'missed', 'refused', 'leave_of_absence', 'on_hold'].includes(formData.status)
  const showUserDropdown = ['confirmed', 'refused'].includes(formData.status)
  const isInjectable = marTimeRow.doseSig?.dose?.injectable
  const showInjectionSiteDropdown = isInjectable && (isAddMode || ['confirmed', 'refused'].includes(formData.status))

  const onSubmit = async () => {
    const {
      administeredBy, reason, status, note, time, injectionSite,
    } = formData
    const selectedTime = moment(time).format('HH:mm:ss')
    const consumptionConfirmedDate = generateFormattedDateTimeString(
      cellTime,
      selectedTime,
    )

    let expectedAt

    // We only want to set expectedAt for scheduled consumptions.
    // When the consumption already exists and we're editing it, just use the existing expectedAt.
    // When we're adding a new consumption, use the default time which derives from the cell time.
    // Expected At must be set correctly, because the MAR uses it to group consumptions into rows.
    if (!marTimeRow.doseSig.prn) {
      expectedAt = consumption?.expectedAt || generateFormattedDateTimeString(
        cellTime,
        moment(formData.defaultTime).format('HH:mm:ss'),
      )
    }

    let body = {
      code: getAdministrationEventCode(consumption?.status, status),
      note,
      subjectId: administeredBy.id,
      confirmedAt: consumptionConfirmedDate,
      timestamp: consumptionConfirmedDate,
      expectedAt,
      reason,
      refusedCode: reason,
      doseId: marTimeRow.doseSig?.dose.id,
      doseSigId: marTimeRow.doseSig?.id,
      consumptionTime: consumption?.expectedAt || consumptionConfirmedDate,
      patientId,
    }

    if (showInjectionSiteDropdown) {
      body.injectionSite = injectionSite
    }

    if (isAddMode) {
      body = { consumption: body }
    }

    const responseBody = await upsertConsumption(body)

    await upsertMeasurements({
      conditionChecks: formData.conditionChecks,
      consumption: responseBody,
    })

    onHide()

    setFormData({
      administeredBy: '',
      reason: '',
      status: '',
      note: '',
      time: '',
      conditions: [],
      conditionChecks: [],
    })
  }

  return (
    <AdherenceForm
      mode={isPrnMode ? 'mar_prn' : 'mar_routine'}
      formData={formData}
      setFormData={setFormData}
      onSubmit={onSubmit}
      onHide={onHide}
      isLoading={isLoading}
      statusMessage={statusMessage}
      patientId={patientId}
      timezone={timezone}
      doseId={marTimeRow.doseSig?.dose.id}
      cellTime={cellTime}
      canEditTime={canEditTime}
      showConditionChecks={!!consumption?.id}
      showReasonInput={showReasonInput}
      showReasonDropdown={showReasonDropdown}
      showInjectionSiteDropdown={showInjectionSiteDropdown}
      showStatusDropdown={showStatusDropdown}
      showUserDropdown={showUserDropdown}
      showNotes
      submitButtonLabel="Save"
    />
  )
}

export default MarAdherenceForm
