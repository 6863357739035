import React from 'react'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { momentFormats } from '@services/utils/moment'

// TODO: DRY
function timeTemplate(field, timezone, placeholder = '') {
  return function tableCell(rowData) {
    const value = rowData[field]
    return (
      <div>
        { value ? moment(value).tz(timezone).format(momentFormats.datetime_12) : placeholder }
      </div>
    )
  }
}

function SmartFlipsTable({ smartFlips, timezone }) {
  return (
    <DataTable
      dataKey="id"
      emptyMessage="No smart flips found"
      removableSort
      value={smartFlips || []}
    >
      <Column
        field="id"
        header="ID"
      />
      <Column
        field="fromPrescriptionId"
        header="From Prescription ID"
      />
      <Column
        field="toPrescriptionId"
        header="To Prescription ID"
      />
      <Column
        field="pharmacyOrderId"
        header="Pharmacy Order ID"
      />
      <Column
        body={timeTemplate('createdAt', timezone)}
        field="createdAt"
        header="Created"
        sortable
      />
      <Column
        body={timeTemplate('updatedAt', timezone)}
        field="updatedAt"
        header="Last Updated"
        sortable
      />
      <Column
        body={timeTemplate('deletedAt', timezone)}
        field="deletedAt"
        header="Deleted"
      />
    </DataTable>
  )
}

export default SmartFlipsTable
