import React, { useRef, useEffect } from 'react'
import {
  isEmpty,
} from 'lodash'
import { useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import Exports from '@components/patients/Exports'
import { TaskConditionsCard } from '@components/tasks/TaskConditionsCard'
import { TaskTable } from '@components/tasks/TaskTable'
import { useTask } from '@hooks/tasks'

function ManageTaskPage({ handleSetHeader, patient }) {
  const scheduleStatusMessage = useRef(null)
  const { patientId: clientId, taskId } = useParams()

  const {
    data: task,
    isLoading,
  } = useTask({ taskId })

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  if (isLoading || isEmpty(task) || isEmpty(patient)) {
    return 'Loading...'
  }

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <>
      <Toast ref={scheduleStatusMessage} />
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      {/* TODO: TaskHeader component with task lifecycle actions */}
      <TaskTable
        editable
        task={task}
        timezone={patient.timezone}
      />
      <TaskConditionsCard task={task} />
      {/* TODO: Schedule component like ClientDoseSchedule for calendar view of task schedules */}
      {/* TODO: PrnSettings component once we add permission request support for tasks */}
      {/* TODO: Superadmin PharmacyOrders component for the treatment orders */}
    </>
  )
}

export default ManageTaskPage
