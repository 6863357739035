import React, { useState, useEffect } from 'react'
import {
  isEmpty, startCase,
  map,
} from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Divider } from 'primereact/divider'
import DoseTag from '@components/clientDoses/Header/DoseTag'
import { calculateDoseStatus, calculateDoseType, formatAdministrationAmount } from '@components/clientDoses/doseUtils'
import { csaScheduleLabel } from '@components/medicines/csaScheduleLabel'
import SearchField from '@components/display/Form/SearchField'
import { momentFormats, momentToLocalWithoutChangingTime } from '@services/utils/moment'
import moment from 'moment'
import { fuzzySearch } from '@services/utils/search'
import { useClientDoses } from './clientDosesHooks'

const dateCellTemplate = (datestring, timezone) => (
  <div className="flex flex-column gap-2">
    {datestring ? moment(datestring).tz(timezone).format(momentFormats.dateYear) : null}
  </div>
)

function DosesList({ patient, maxDataViewHeight, filters = [] }) {
  const navigate = useNavigate()
  const [doses, setDoses] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'status.order', order: 1 }, { field: 'medicine.name', order: 2 }])

  const handleMultiSort = (e) => {
    setMultiSortMeta(e)
  }

  const appliedFilters = filters.reduce((result, filter) => {
    const newResult = result
    newResult[filter.name] = {
      value: filter.value,
      matchMode: FilterMatchMode.EQUALS,
    }
    return newResult
  }, {})

  const query = useClientDoses(patient.id)

  const medicineTemplate = ({ orderText, medicine }) => (
    <div className="flex flex-column gap-2">
      { !isEmpty(orderText) ? <span>{orderText}</span> : (
        <>
          <span>{medicine.name}</span>
          <span>{medicine.strength}</span>
        </>
      )}
    </div>
  )

  const administrationAmountTemplate = ({ doseSigs }) => (
    <div className="flex flex-column gap-2">
      {map(doseSigs, (doseSig) => {
        const administrationAmountDisplay = formatAdministrationAmount({
          administrationAmount: doseSig.administrationAmount,
          medicine: doseSig.dose.medicine,
        })
        return (<span>{administrationAmountDisplay}</span>)
      })}
    </div>
  )

  const doseTypeTemplate = (dose) => {
    const doseType = calculateDoseType(dose)
    return (
      <DoseTag color={doseType.color} text={doseType.text} />
    )
  }

  const expirationDateTemplate = ({ expiration }) => {
    if (!expiration) {
      return 'N/A'
    }
    return momentToLocalWithoutChangingTime(moment(expiration)).format(momentFormats.monthDay)
  }

  const statusTemplate = ({ status }) => (
    <DoseTag color={status.color} text={startCase(status.text)} />
  )

  const attributesTemplate = ({ integrated, medicine }) => (
    <div className="flex flex-row gap-2">
      {medicine.csaSchedule
        && csaScheduleLabel(medicine.csaSchedule)}
      { integrated && (<DoseTag color="var(--indigo-400)" text="Pharmacy" />) }
    </div>
  )

  useEffect(() => {
    if (!isEmpty(query.data)) {
      const tableDoses = query.data.map((dose) => ({
        ...dose,
        status: calculateDoseStatus(dose),
        activatedAt: !!dose.activatedAt,
        daysScheduled: Object.keys(dose.schedules).length,
      }))
      setDoses(fuzzySearch(tableDoses, ['medicine.name'], searchTerm))
    }
  }, [query.data, searchTerm])

  return (
    <div className="flex flex-column gap-3 fadein">
      <SearchField
        className="w-19rem"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search Medications"
      />
      <Divider className="my-0" />
      <DataTable
        dataKey="id"
        loading={query.isLoading}
        value={doses}
        filters={appliedFilters}
        selectionMode="single"
        sortMode="multiple"
        onSelectionChange={({ value }) => navigate(`../manage/${value.id}`)}
        autoLayout
        scrollable
        scrollHeight={maxDataViewHeight + 35}
        multiSortMeta={multiSortMeta}
        onSort={(e) => handleMultiSort(e.multiSortMeta)}
      >
        <Column
          header="Status"
          field="status"
          sortField="status.order"
          sortable
          body={statusTemplate}
        />
        <Column
          header="Medicine"
          field="medicine"
          sortable
          sortField="medicine.name"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          body={medicineTemplate}
        />
        <Column
          header="Administration Amount"
          field="administrationAmount"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          body={administrationAmountTemplate}
        />
        <Column
          header="End At"
          field="endAt"
          body={({ endAt }) => dateCellTemplate(endAt, patient.timezone)}
        />
        <Column
          header="Expiration Date"
          field="expiration"
          body={expirationDateTemplate}
        />
        <Column
          header="Days Scheduled"
          field="daysScheduled"
        />
        <Column
          header="Dose Type"
          field="doseType"
          sortable
          body={doseTypeTemplate}
        />
        <Column
          header="Attributes"
          field="medicine"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          body={attributesTemplate}
        />
        <Column
          hidden
          field="activatedAt"
        />
      </DataTable>
    </div>
  )
}

export default DosesList
