import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { confirmDialog } from 'primereact/confirmdialog'
import {
  DoseAttributeRow,
  TextAreaEditor,
  EditButton,
  DateEditor,
} from '@components/DoseAttributeRow'
import { momentFormats, momentToLocalWithoutChangingTime, momentTzWithoutChangingTime } from '@services/utils/moment'

export function ReviewScheduledNoteTable({
  patient,
  treatment,
  scheduledNote,
  setScheduledNote,
}) {
  const [editAttribute, setEditAttribute] = useState(null)

  useEffect(() => {
    const currentScheduledNote = treatment?.scheduledNote || {}

    setScheduledNote({
      id: currentScheduledNote.id,
      note: currentScheduledNote.note || treatment?.instructions || '',
      startAt: currentScheduledNote.startAt || treatment?.startAt,
      endAt: currentScheduledNote.endAt || treatment?.endAt,
    })
  }, [setScheduledNote, treatment])

  if (!treatment) {
    return null
  }

  const handleUpdateAttribute = (attribute, value) => {
    const updatedScheduledNote = { ...scheduledNote }
    updatedScheduledNote[attribute] = value
    setScheduledNote(updatedScheduledNote)
    setEditAttribute(null)
  }

  const { timezone } = patient

  const scheduledNoteAttributes = [
    {
      label: 'Note',
      currentValue: scheduledNote.note,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'note' ? setEditAttribute(null) : setEditAttribute('note'))}
          isEditing={editAttribute === 'note'}
          disabled={editAttribute && editAttribute !== 'note'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={scheduledNote.note}
          setText={(value) => handleUpdateAttribute('note', value)}
        />
      ),
      isEditing: editAttribute === 'note',
    },
    {
      label: 'Start Date',
      currentValue: scheduledNote.startAt && timezone
        ? moment(scheduledNote.startAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
          disabled={editAttribute && editAttribute !== 'startAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={scheduledNote.startAt
            ? momentToLocalWithoutChangingTime(moment(scheduledNote.startAt), timezone) : null}
          setMomentDate={(value) => {
            const startToSet = momentTzWithoutChangingTime(value.startOf('day'), timezone)
            return handleUpdateAttribute('startAt', startToSet.toISOString())
          }}
          max={scheduledNote.endAt ? moment(scheduledNote.endAt) : null}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      currentValue: scheduledNote.endAt && timezone
        ? moment(scheduledNote.endAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
          disabled={editAttribute && editAttribute !== 'endAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={scheduledNote.endAt
            ? momentToLocalWithoutChangingTime(moment(scheduledNote.endAt), timezone) : null}
          min={scheduledNote.startAt ? moment(scheduledNote.startAt) : null}
          setMomentDate={(value) => {
            if (!value) {
              return handleUpdateAttribute('endAt', null)
            }
            const newEnd = momentTzWithoutChangingTime(value.endOf('day'), timezone)
            if (newEnd.startOf('day').isBefore(moment().startOf('day'))) {
              confirmDialog({
                message: 'The end date is before today, are you sure you want to continue?',
                header: 'Warning',
                accept: () => handleUpdateAttribute('endAt', newEnd.toISOString()),
                icon: 'pi pi-exclamation-triangle',
              })
            } else {
              return handleUpdateAttribute('endAt', newEnd.toISOString())
            }
          }}
          showButtonBar
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
  ]

  return (
    <div className="col-12">
      <ul className="list-none p-0 m-0 dose-order-fields">
        {scheduledNoteAttributes
          .filter(({ display = true }) => display)
          .map(({
            label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
          }, index) => (
            <DoseAttributeRow
              key={label}
              label={label}
              valueChangeHandler={valueChangeHandler}
              currentValue={currentValue}
              newValue={newValue}
              action={action}
              editor={editor}
              isEditing={isEditing}
              backgroundHighlight={index % 2 === 0}
            />
          ))}
      </ul>
    </div>
  )
}

export default ReviewScheduledNoteTable
