export const checkAllValidRow = (rows, setIsValid) => {
  setIsValid(rows?.every((v) => v.valid === true) || false)
}

const setCellError = (data) => {
  const {
    quantityOne, quantityTwo,
    administerQuantity, conditionType, action,
  } = data
  const errObject = {
    quantityOne: false,
    quantityTwo: false,
    administerQuantity: false,
  }

  // Check if input one is equal to or less than 1 for a range
  if (quantityOne >= quantityTwo && conditionType === 'RANGE') {
    errObject.quantityTwo = true
  }

  // Check if input 1 is null
  if (!quantityOne) {
    errObject.quantityOne = true
  }

  // Check if Administer Qty is null when type is selected
  if (action === 'ADMINISTER' && !administerQuantity) {
    errObject.administerQuantity = true
  }

  return errObject
}

export const checkValidRow = (data) => {
  let validRow = false
  const {
    action, conditionType, administerQuantity, quantityOne, quantityTwo,
  } = data

  switch (conditionType) {
    case 'RANGE':
      if (action === 'ADMINISTER' && quantityOne && quantityTwo && administerQuantity && (quantityOne < quantityTwo)) {
        validRow = true
      }
      if (action !== 'ADMINISTER' && quantityOne && quantityTwo && (quantityOne < quantityTwo)) {
        validRow = true
      }
      break
    default:
      if (action === 'ADMINISTER' && quantityOne && administerQuantity) {
        validRow = true
      }
      if (action !== 'ADMINISTER' && quantityOne) {
        validRow = true
      }
  }

  const errors = setCellError(data)
  return { validRow, errors }
}
