import React from 'react'
import { momentFormats } from '@services/utils/moment'
import { useDoseHoldCreate } from '@hooks/doseHolds'
import DoseHoldDialog from '@components/DoseHold/DoseHoldDialog'

function ClientDoseHoldDialog({
  visible,
  onHide,
  doseId,
  timezone,
  statusMessage,
}) {
  const {
    mutateAsync: createHold,
    isLoading: isCreatingHold,
  } = useDoseHoldCreate(doseId, statusMessage)

  if (!doseId) return null

  const handleCreateHold = async ({ startAt, endAt, note }) => {
    const payload = {
      doseId,
      startAt: startAt.utc().format(momentFormats.iso8601_utc),
      endAt: endAt?.utc()?.format(momentFormats.iso8601_utc),
      note,
    }
    await createHold(payload, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  return (
    <DoseHoldDialog
      timezone={timezone}
      visible={visible}
      onHide={onHide}
      onConfirm={handleCreateHold}
      showDeleteButton={false}
      isLoading={isCreatingHold}
    />
  )
}

export default ClientDoseHoldDialog
