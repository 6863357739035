import React, { useState } from 'react'
import { get } from 'lodash'
import { Button } from 'primereact/button'
import InputTextWithLabel from '../InputTextWithLabel'

function MedicalConditionForm({
  medicalCondition,
  saveMedicalCondition,
  updateMedicalCondition,
  isLoading,
  hide,
}) {
  const [description, setDescription] = useState(get(medicalCondition, 'description', ''))
  const [icdCode, setIcdCode] = useState(get(medicalCondition, 'icdCode', ''))
  const [icdVersion, setIcdVersion] = useState('10')

  const handleSave = async () => {
    await saveMedicalCondition({ description, icdCode, icdVersion })
  }

  const handleUpdate = async () => {
    await updateMedicalCondition({
      id: medicalCondition.id, description, icdCode, icdVersion,
    })
  }

  return (
    <div className="flex flex-column">
      <InputTextWithLabel
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <InputTextWithLabel
        label="ICD Code"
        value={icdCode}
        onChange={(e) => setIcdCode(e.target.value)}
      />
      <InputTextWithLabel
        label="ICD Version"
        value={icdVersion}
        disabled
        onChange={(e) => setIcdVersion(e.target.value)}
      />
      <div className="flex flex-row mt-3 justify-content-end">
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-sm p-button-outlined"
          onClick={async () => {
            if (medicalCondition) {
              await handleUpdate()
            } else {
              await handleSave()
            }
            hide()
          }}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

export default MedicalConditionForm
