import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import { InputSwitch } from 'primereact/inputswitch'
import { Checkbox } from 'primereact/checkbox'
import { exactSearch } from '@services/utils/search'
import SearchField from '@components/display/Form/SearchField'
import UserDirectoryServiceIcon from '@components/integrations/UserDirectoryServiceIcon'

function SiteSelection({
  userSites,
  availableSites,
  onUserSitesChange,
  isLoading,
  editEnabled = true,
  showAllSitesAccess = false,
  hasAllSitesAccess,
  allSitesAccessLoading,
  onAllSitesAccessChange,
  isDirectoryManaged,
  maxDataViewHeight,
}) {
  const [assignedSites, setAssignedSites] = useState([])
  const [filteredAvailableSites, setFilteredAvailableSites] = useState([])
  const [filteredAssignedSites, setFilteredAssignedSites] = useState([])
  const [selectedSites, setSelectedSites] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setAssignedSites(userSites)
    setSelectedSites(userSites)
    setFilteredAssignedSites(userSites)
  }, [userSites])

  useEffect(() => {
    setFilteredAvailableSites(availableSites)
  }, [availableSites])

  useEffect(() => {
    if (isEditing) {
      setFilteredAvailableSites(exactSearch(availableSites, ['name'], searchTerm))
    }
  }, [availableSites, searchTerm, isEditing])

  useEffect(() => {
    if (organizationFilter) {
      if (isEditing) {
        setFilteredAvailableSites(
          availableSites.filter((site) => site.organization.id === organizationFilter.id),
        )
      } else {
        setFilteredAssignedSites(
          assignedSites.filter((site) => site.organization.id === organizationFilter.id),
        )
      }
    } else {
      setFilteredAvailableSites(availableSites)
    }
  }, [organizationFilter, isEditing, availableSites, assignedSites])

  const handleSiteAccessChange = async (e) => {
    onAllSitesAccessChange(e.value)
  }

  const handleSubmit = async () => {
    onUserSitesChange(selectedSites)
    setIsEditing(false)
    setOrganizationFilter(null)
    setSearchTerm('')
  }

  const handleCancel = () => {
    setSelectedSites(assignedSites)
    setIsEditing(false)
    setOrganizationFilter(null)
    setSearchTerm('')
  }

  const handleEdit = () => {
    setIsEditing(true)
    setOrganizationFilter(null)
  }

  const handleSelectionChange = (e) => {
    const addedSite = e.value.find((site) => !selectedSites.some((s) => s.id === site.id))
    const removedSite = selectedSites.find(
      (site) => !e.value.some((s) => s.id === site.id),
    )
    if (isDirectoryManaged
                && (addedSite?.isScimManaged || removedSite?.isScimManaged)) {
      return
    }

    return setSelectedSites(e.value)
  }

  const checkBoxTemplate = (rowSite) => {
    if (isDirectoryManaged && rowSite.isScimManaged) {
      return (
        <div className="flex flex-row align-items-center justify-content-center">
          <UserDirectoryServiceIcon service="active-directory" />
        </div>
      )
    }

    return (
      <Checkbox
        checked={selectedSites.some((site) => site.id === rowSite.id)}
        onChange={(e) => {
          if (e.checked) {
            setSelectedSites([...selectedSites, rowSite])
          } else {
            setSelectedSites(selectedSites.filter((site) => site.id !== rowSite.id))
          }
        }}
      />
    )
  }

  return (
    <Card className="h-full">
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row gap-1 align-items-center">
          <span className="text-lg py-2">
            <i className="pi pi-building mr-2" />
            Site Access
          </span>
          {isEditing && (
          <>
            <Divider layout="vertical" className="my-0 py-1" />
            <SearchField
              className="w-18rem"
              isLoading={isLoading}
              placeholder="Search sites"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </>
          )}
          { showAllSitesAccess
            && <Divider layout="vertical" className="my-0 py-1" />}
          <div className="flex flex-row flex-grow-1 gap-3 align-items-center">
            {
              showAllSitesAccess && (
                <div className="flex flex-row align-items-center">
                  <label htmlFor="acess-all-sites" className="p-checkbox-label flex align-items-center">
                    Access to all sites:
                    <InputSwitch
                      className="m-2"
                      inputId="acess-all-sites"
                      checked={hasAllSitesAccess}
                      onChange={handleSiteAccessChange}
                      disabled={allSitesAccessLoading}
                    />
                    {allSitesAccessLoading && (
                    <i
                      className="pi pi-spin pi-spinner mx-2"
                      style={{ width: '1rem', height: '1rem' }}
                    />
                    )}
                  </label>
                </div>
              )
            }
            {
              ((showAllSitesAccess && !hasAllSitesAccess) || !showAllSitesAccess) && (
                <div className="flex flex-grow-1 justify-content-end ml-auto">
                  {isEditing ? (
                    <div className="flex ml-auto">
                      <Button
                        icon="pi pi-times"
                        onClick={handleCancel}
                        className="mr-2 p-button-sm"
                      />
                    </div>
                  ) : (
                    editEnabled && (
                    <Button label="Edit Assignments" onClick={handleEdit} className="p-button-sm" />
                    )
                  )}
                </div>
              )
            }
          </div>
        </div>
        <Divider className="my-0" />
        <DataTable
          value={isEditing ? filteredAvailableSites : filteredAssignedSites}
          selection={isEditing ? selectedSites : null}
          onSelectionChange={
            isEditing ? handleSelectionChange : null
          }
          selectionMode={isEditing ? 'multiple' : null}
          dataKey="id"
          scrollable
          scrollHeight={maxDataViewHeight - 100}
          onRowClick={(e) => {
            e.originalEvent.preventDefault()
          }}
        >
          {isEditing && (
            <Column style={{ width: '24px' }} field="id" body={checkBoxTemplate} />
          )}
          <Column field="name" header="Name" style={{ maxWidth: '25%' }} />
          <Column field="address" header="Address" style={{ minWidth: '0px' }} />
        </DataTable>
        {isEditing
          && (
          <div className="flex flex-row">
            <Button
              label="Save Assignments"
              onClick={handleSubmit}
              loading={isLoading}
              className="p-button-sm p-button-success"
            />
          </div>
          )}
      </div>
    </Card>
  )
}

export default SiteSelection
