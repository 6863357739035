import moment from 'moment'

/**
 * The rrule.js library converts rrules to a string in the format:
 *  "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"
 * This function extracts the rule itself, omitting the "RRULE:" so that it is compatible
 * with API validations.
 * TODO: Replace all duplications of this logic with a call to this function.
 *
 * @param {string} rrule
 * @returns {string}
 */
export const formatRrule = (rrule) => rrule?.replace('RRULE:', '')

/**
 * Schedule components don't use a consistent schedule data structure, so we need to format
 * the schedule this way to make use of the ScheduleEditor component. It will be much easier to
 * refactor this logic after adopting TypeScript.
 */
export const formatSchedule = (schedule) => {
  const timeHours = parseInt(schedule.time_hh_mm.split(':')[0], 10)
  const timeMinutes = parseInt(schedule.time_hh_mm.split(':')[1], 10)

  return ({
    flexibility: schedule.flexibilityAttributes,
    rrule: schedule.rrule,
    time_hh_mm: schedule.time_hh_mm,
    time: schedule.time_hh_mm.split(':').reduce((acc, time) => (60 * acc) + parseInt(time, 10), 0),
    timeHours,
    timeMinutes,
    // Needed to properly display the expected time in the EditableScheduleTime component
    momentTime: moment().set({
      hour: timeHours,
      minute: timeMinutes,
      second: 0,
      millisecond: 0,
    }),
  })
}

/**
 * Utility function to format the payload for a list of schedules that should be included
 * in a POST request to an API when creating schedules at the same time as another resources.
 */
export const formatSchedulesApiCreatePayload = (schedules) => (schedules || []).map((schedule) => ({
  eventTimeDirective: schedule.eventTimeDirective,
  eventTimeId: schedule.eventTimeId,
  rrule: formatRrule(schedule.rrule),
  time_hh_mm: schedule.time_hh_mm,
  flexibility_attributes: schedule.flexibility || schedule.flexibilityAttributes,
}))
