import FormWrapper from '@components/display/Form/FormWrapper'
import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { useCurrentOrganization } from '@components/App'
import { useMySitesQuery } from '@components/sites/sitesHooks'
import { MultiSelect } from 'primereact/multiselect'
import { Button } from 'primereact/button'
import getAssignedSiteIds from '../../../features/SSODirectoryUsers/utils'
import { useUserTitleOptions } from '../../../features/Caregivers/caregiversHooks'
import { SharedDeviceForm } from '.'
import ProfileForm from './Profile'

function CaregiverCreateForm({
  onSave,
  saveLoading,
  submitButtonLabel = 'Save',
  statusMessageRef,
  initialValues = {},
}) {
  const organization = useCurrentOrganization()
  const [profile, setProfile] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    customerId: '',
    restrictSiteAccess: false,
    ...initialValues,
  })

  const [sharedDevice, setSharedDevice] = useState({
    pin: '',
  })
  const [userTitle, setUserTitle] = useState({})
  const [assignedSiteIds, setAssignedSiteIds] = useState(
    getAssignedSiteIds(initialValues?.sites || []),
  )

  const canViewSites = (organization.hasSites && !organization.deviceBasedAccess)
    || (organization.hasSites && profile.restrictSiteAccess)
  const canViewSharedDevice = organization.caregiversCanShareDevice
    && organization.pinSwitchingEnabled

  const isSubmitButtonDisabled = ((canViewSites && assignedSiteIds.length === 0)
    || (profile.pin && profile.pin.length < 4))
    || (profile.firstName?.trim() === '' || profile.lastName?.trim() === '')

  const {
    data: accessibleSites,
    isLoading: isAccessibleSitesLoading,
  } = useMySitesQuery(organization.id, statusMessageRef)

  const {
    data: titleOptions,
    isLoading: isTitleOptionsLoading,
  } = useUserTitleOptions()

  return (
    <FormWrapper title="Profile" fullHeight>
      <ProfileForm
        profile={profile}
        setProfile={(value) => setProfile((p) => ({ ...p, ...value }))}
      />
      <div className="field">
        <label className="block" htmlFor="user-title">Title</label>
        <Dropdown
          inputId="user-title"
          value={userTitle}
          options={titleOptions}
          dataKey="abbreviation"
          optionLabel="title"
          onChange={(e) => setUserTitle(e.value)}
          placeholder="Select a title"
          loading={isTitleOptionsLoading || saveLoading}
          disabled={saveLoading}
          className="w-full"
          filter
        />
      </div>
      {
        canViewSites && (
          <div className="field">
            <label className="block">Sites*</label>
            <MultiSelect
              value={assignedSiteIds}
              options={accessibleSites}
              onChange={(e) => setAssignedSiteIds(e.value)}
              placeholder="Select Sites"
              loading={isAccessibleSitesLoading || saveLoading}
              disabled={saveLoading}
              className="w-full"
              filter
              showClear
              multiple
              optionLabel="name"
              optionValue="id"
            />
          </div>
        )
      }
      {
        canViewSharedDevice && (
          <SharedDeviceForm
            sharedDevice={sharedDevice}
            setSharedDevice={(value) => setSharedDevice((p) => ({ ...p, ...value }))}
            loading={saveLoading}
          />
        )
      }
      <Button
        label={submitButtonLabel}
        className="p-button-sm m-2"
        loading={saveLoading}
        onClick={() => onSave({
          ...profile,
          ...sharedDevice,
          caregiverSiteAccessIds: assignedSiteIds,
          userTitleAttributes: { title: userTitle.abbreviation },
          organizationId: organization.id,
        })}
        disabled={isSubmitButtonDisabled}
      />
    </FormWrapper>
  )
}

export default CaregiverCreateForm
