import React, { useState } from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { get } from 'lodash'
import { formatDateTimeLocale } from '@services/utils'
import EditPatientInsuranceDialog from './EditInsuranceDialog'
import CreatePatientInsuranceDialog from './CreateInsuranceDialog'

function PatientInsurances({
  patient,
  isLoading,
}) {
  const [selectedInsurance, setSelectedInsurance] = useState(false)
  const [showCreateInsuranceDialog, setShowCreateInsuranceDialog] = useState(false)

  return (
    <div>
      {
        selectedInsurance && (
          <EditPatientInsuranceDialog
            visible
            insurance={selectedInsurance}
            patient={patient}
            onHide={() => setSelectedInsurance(null)}
          />
        )
      }
      {
        showCreateInsuranceDialog
        && (
        <CreatePatientInsuranceDialog
          visible
          patient={patient}
          onHide={() => setShowCreateInsuranceDialog(false)}
        />
        )
      }
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-lg">
          <div className="flex flex-row justify-content-between align-items-center">
            <div>
              Insurance
            </div>
            <Button
              label="Add Insurance"
              icon="fa-solid fa-address-card"
              className="p-button-sm p-button-info"
              onClick={() => setShowCreateInsuranceDialog(true)}
            />
          </div>
        </div>
        <DataTable
          dataKey="id"
          value={get(patient, 'insurances', [])}
          isLoading={isLoading}
          className="mt-2"
          rowClassName="fadein"
          selectionMode="single"
          onSelectionChange={(e) => setSelectedInsurance(e.value)}
        >
          <Column
            key="name"
            field="name"
            header="Name"
          />
          <Column
            key="groupNumber"
            field="groupNumber"
            header="Group Number"
          />
          <Column
            key="policyNumber"
            field="policyNumber"
            header="Policy Number"
          />
          <Column
            key="deactivatedAt"
            field={({ deactivatedAt }) => {
              const formattedDateTime = formatDateTimeLocale(
                deactivatedAt,
                patient.timezone,
              )
              return deactivatedAt ? formattedDateTime : null
            }}
            header="Deactivated At"
          />
        </DataTable>
      </Card>
    </div>
  )
}

export default PatientInsurances
