import React from 'react'
import { useCurrentUser } from '@components/App'
import SuperAdminCard from '@components/display/SuperAdminCard'
import PharmacyOrdersTable from '@components/pharmacyOrders/Table/PharmacyOrdersTable'
import { usePharmacyOrders } from '@hooks/pharmacyOrders'

function PharmacyOrdersCard({ model, id }) {
  const { role } = useCurrentUser()

  const {
    data: pharmacyOrders,
    isLoading,
  } = usePharmacyOrders(model, id, { enabled: !!id && role === 'superadmin' })

  return (
    <div className="col-12">
      <SuperAdminCard title="Orders">
        <PharmacyOrdersTable
          pharmacyOrders={pharmacyOrders}
          isLoading={isLoading}
          showDoseId={model !== 'Treatment'}
          showDosing={model !== 'Treatment'}
          showMedicine={model !== 'Treatment'}
          showRawAdministrationTimes={model === 'Treatment'}
        />
      </SuperAdminCard>
    </div>
  )
}

export default PharmacyOrdersCard
