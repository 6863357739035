import React, { useEffect } from 'react'
import { withApp, useCurrentUser } from '@components/App'

function CurrentUser() {
  const currentUser = useCurrentUser()

  useEffect(() => {
    const isCurrentUserAdmin = ['site_admin'].includes(currentUser.role)
    const isCurrentUserCaregiver = ['caregiver'].includes(currentUser.role)
    const isCurrentUserPatient = ['patient'].includes(currentUser.role)
    const isCurrentUserPharmacist = ['pharmacist'].includes(currentUser.role)
    if (isCurrentUserAdmin) {
      window.location.assign(`/admin/admins/${currentUser.id}`)
    } else if (isCurrentUserCaregiver) {
      window.location.assign(`/admin/caregivers/${currentUser.id}`)
    } else if (isCurrentUserPatient) {
      window.location.assign(`/admin/patients/${currentUser.id}/profile`)
    } else if (isCurrentUserPharmacist) {
      window.location.assign(`/admin/pharmacists/${currentUser.id}`)
    }
  }, [currentUser])

  return (
    <div>Loading...</div>
  )
}

export default withApp(CurrentUser)
