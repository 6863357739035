import React from 'react'
import CustomTag from '@components/display/CustomTag'

function TreatmentTypeTag({ treatment }) {
  if (!treatment) {
    return null
  }

  const text = treatment.showInMedpass ? 'reminder' : treatment.treatmentType

  return <CustomTag color="var(--blue-400)" text={text} />
}

export default TreatmentTypeTag
