import React from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { TreatmentCategoryTag, TreatmentDirectiveTag } from '@components/treatments/tags'
import { momentFormats } from '@services/utils/moment'

function TreatmentAttribute({ label, value }) {
  return (
    <div key={label} className="flex flex-row align-items-center">
      <span className="mr-1 text-600">
        {label}
        :
      </span>
      <span>{value}</span>
    </div>
  )
}

function TreatmentDetails({ treatment, directives = [], handleUndo }) {
  const {
    orderNumber, instructions, startAt, endAt,
  } = treatment

  const startDate = startAt ? moment(startAt).format(momentFormats.dateYear) : 'N/A'
  const endDate = endAt ? moment(endAt).format(momentFormats.dateYear) : 'N/A'

  return (
    <div className="flex flex-row">
      <div className="flex flex-column col-10 p-4 gap-2">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2">
            <TreatmentDirectiveTag treatment={treatment} directives={directives} />
            <TreatmentCategoryTag treatment={treatment} />
          </div>
        </div>
        <div className="flex flex-column gap-2 text-sm text-900 p-1">
          <div>
            <span className="p-0 mr-1 text-600">Instructions:</span>
            <span>{instructions}</span>
          </div>
          <div className="flex flex-wrap gap-2">
            <TreatmentAttribute label="Order Number" value={orderNumber} />
            <TreatmentAttribute label="Start At" value={startDate} />
            <TreatmentAttribute label="End At" value={endDate} />
          </div>
        </div>
      </div>
      {handleUndo && (
        <Button
          onClick={handleUndo}
          className="align-self-center col-2 p-4 gap-2 max-w-8rem max-h-1rem"
          label="Undo"
          icon="pi pi-undo"
          outlined
        />
      )}
    </div>
  )
}

export default TreatmentDetails
