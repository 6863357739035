import { get } from 'lodash'
import moment from 'moment'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useUpdateDrawer(medBoxId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (drawer) => {
      const { doseScheduleMinTime, doseScheduleMaxTime } = drawer
      const drawerFields = drawer
      if (doseScheduleMinTime && doseScheduleMaxTime) {
        drawerFields.dose_schedule_min_time_hh_mm = moment(get(doseScheduleMinTime, 'time')).format('HH:mm')
        drawerFields.dose_schedule_max_time_hh_mm = moment(get(doseScheduleMaxTime, 'time')).format('HH:mm')
      } else {
        drawerFields.dose_schedule_min_time_hh_mm = null
        drawerFields.dose_schedule_max_time_hh_mm = null
      }

      return ApiClient.patch(`simpills/${medBoxId}/medbox_drawers/${drawer.id}`, {
        drawer: drawerFields,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries('medbox')
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Drawer updated!',
        },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpdateDrawer
