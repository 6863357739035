import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect'
import { InputTextarea } from 'primereact/inputtextarea'
import { useMySitesQuery } from '@components/sites/sitesHooks'

function ReactivateCaregiverDialog({
  statusMessage, organization, userId, visible, onHide, onConfirm, isLoading,
}) {
  const isDirectAssignment = !organization.deviceBasedAccess
  const [note, setNote] = useState('')
  const [siteIds, setSiteIds] = useState([])

  const {
    data: accessibleSites,
    isLoading: isAccessibleSitesLoading,
  } = useMySitesQuery(organization.id, statusMessage)

  const footer = (
    <div className="flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text p-button-sm"
        onClick={onHide}
        disabled={isLoading}
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        className="p-button-sm"
        onClick={() => onConfirm({ userId, siteIds, note })}
        loading={isLoading}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Reactivate"
      footer={footer}
      modal
      className="p-fluid"
      closable={!isLoading}
    >
      <div className="p-field p-2">
        { isDirectAssignment && (
          <div className="field">
            <label htmlFor="reactivate-caregiver-site">Sites to assign to</label>
            <MultiSelect
              id="reactivate-caregiver-site"
              value={siteIds}
              options={accessibleSites}
              onChange={(e) => setSiteIds(e.value)}
              placeholder="Select Sites"
              loading={isAccessibleSitesLoading}
              className="w-full"
              filter
              showClear
              multiple
              optionLabel="name"
              optionValue="id"
            />
          </div>
        )}
        <label htmlFor="reactivate-caregiver-note">Note for reactivation</label>
        <InputTextarea
          id="reactivate-user-note"
          className="mt-2"
          rows={3}
          cols={30}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          autoFocus
        />
      </div>
    </Dialog>
  )
}

export default ReactivateCaregiverDialog
