import React, { useRef, useState } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Messages } from 'primereact/messages'
import { TaskForm } from '@components/tasks/TaskForm'
import { useCreateTask } from '@hooks/tasks'

function TaskDialog({
  treatment, onHide, visible,
}) {
  const statusMessage = useRef()
  const [task, setTask] = useState({
    taskType: treatment.treatmentType,
    taskAction: 'binary',
    startAt: treatment.startAt ? moment(treatment.startAt).toDate() : null,
    endAt: treatment.endAt ? moment(treatment.endAt).toDate() : null,
    title: '',
    description: treatment.instructions,
    showOnTar: true,
    schedules: [],
  })

  const {
    mutateAsync: createTask,
    isLoading,
  } = useCreateTask({ statusMessage })

  const handleCreateTask = async () => {
    await createTask({
      ...task,
      patientId: treatment.patientId,
      treatmentId: treatment.id,
      taskCategory: 'treatment',
    })
    onHide()
  }

  return (
    <Dialog
      header="Create Task"
      onHide={onHide}
      style={{ width: '50vw' }}
      visible={visible}
    >
      <Messages ref={statusMessage} />
      <div className="flex flex-column">
        <TaskForm
          task={task}
          setTask={(value) => setTask((t) => ({ ...t, ...value }))}
          showOnTarVisible={false}
        />
        <div className="flex flex-row justify-content-end">
          <Button
            label="Create"
            icon="pi pi-check"
            loading={isLoading}
            disabled={isLoading || !task}
            onClick={handleCreateTask}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default TaskDialog
