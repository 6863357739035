import { useMutation, useQueryClient } from '@tanstack/react-query'
import { get, last } from 'lodash'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

/**
 * TODO: Replace with a TypeScript interface after adding TypeScript to the project.
 * @typedef {Object} UpsertTaskOccurrenceParams
 * @property {Object} task - The current task object.
 * @property {Object} taskOccurrence - The current task occurrence object.
 * @property {string} status - The new status of the task occurrence.
 * @property {number} responsibleId - The new ID of the responsible person.
 * @property {string} note - The new note associated with the task occurrence.
 * @property {string} reason - The new reason for the task occurrence status.
 * @property {string} responsibleAt - The new date and time when the task was actioned.
 * @property {number} scheduleId - The ID of the schedule associated with the task occurrence.
 * @property {string} expectedAt - The expected date and time of the task occurrence.
 */

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const createTreatmentConfirmation = async (params) => {
  const treatmentId = params.task.taskableId

  return ApiClient.post(`/treatments/${treatmentId}/confirmations`, {
    treatmentConfirmation: {
      note: params.note,
      responsibleAt: params.responsibleAt,
      responsibleId: params.responsibleId,
      taskId: params.task.id,
      taskOccurrenceId: params.taskOccurrence?.id,
      scheduleId: params.scheduleId,
      expectedAt: params.expectedAt,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const createTreatmentMiss = async (params) => {
  const treatmentId = params.task.taskableId

  return ApiClient.post(`/treatments/${treatmentId}/misses`, {
    treatmentMiss: {
      reason: params.reason,
      responsibleId: params.responsibleId,
      taskOccurrenceId: params.taskOccurrence?.id,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const createTreatmentHold = async (params) => {
  const treatmentId = params.task.taskableId

  return ApiClient.post(`/treatments/${treatmentId}/holds`, {
    treatmentHold: {
      code: params.reason,
      heldAt: params.responsibleAt,
      heldById: params.responsibleId,
      note: params.note,
      taskOccurrenceId: params.taskOccurrence?.id,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const createTreatmentRefusal = async (params) => {
  const treatmentId = params.task.taskableId

  return ApiClient.post(`/treatments/${treatmentId}/refusals`, {
    treatmentRefusal: {
      reason: params.reason,
      responsibleAt: params.responsibleAt,
      responsibleId: params.responsibleId,
      taskId: params.task.id,
      taskOccurrenceId: params.taskOccurrence?.id,
      scheduleId: params.scheduleId,
      expectedAt: params.expectedAt,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const updateTreatmentConfirmation = async (params) => {
  const { id } = last(params.taskOccurrence.completions) || {}

  // Safety check in case the status was set to completed without creating a treatment confirmation.
  if (!id) {
    return createTreatmentConfirmation(params)
  }

  return ApiClient.patch(`/treatment_confirmations/${id}`, {
    treatmentConfirmation: {
      note: params.note,
      responsibleAt: params.responsibleAt,
      responsibleId: params.responsibleId,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const updateTreatmentMiss = async (params) => {
  const { id } = last(params.taskOccurrence.misses) || {}

  // We do not create a treatment miss when the allowed window expires, so the first time a missed
  // treatment is edited on the TAR, we need to create the treatment miss.
  if (!id) {
    return createTreatmentMiss(params)
  }

  return ApiClient.patch(`/treatment_misses/${id}`, {
    treatmentMiss: {
      reason: params.reason,
      responsibleId: params.responsibleId,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const updateTreatmentHold = async (params) => {
  const { id } = last(params.taskOccurrence.holds) || {}

  // Safety check in case the status was set to on_hold without creating a treatment hold.
  // This can happen when the underlying task occurrence was set to on_hold by a scheduled hold.
  if (!id) {
    return createTreatmentHold(params)
  }

  return ApiClient.patch(`/treatment_holds/${id}`, {
    treatmentHold: {
      code: params.reason,
      heldAt: params.responsibleAt,
      heldById: params.responsibleId,
      note: params.note,
    },
  })
}

/**
 * @param {UpsertTaskOccurrenceParams} params
 */
const updateTreatmentRefusal = async (params) => {
  const { id } = last(params.taskOccurrence.refusals) || {}

  // Safety check in case the status was set to refused without creating a treatment refusal.
  if (!id) {
    return createTreatmentRefusal(params)
  }

  return ApiClient.patch(`/treatment_refusals/${id}`, {
    treatmentRefusal: {
      reason: params.reason,
      responsibleAt: params.responsibleAt,
      responsibleId: params.responsibleId,
    },
  })
}

const useUpsertTaskOccurrence = ({ chained = false, statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    /**
     * @param {UpsertTaskOccurrenceParams} params
     */
    mutationFn: (params) => {
      const action = params.taskOccurrence?.status === params.status ? 'update' : 'create'
      const taskableType = get(params, 'task.taskableType', 'custom-task').toLowerCase()
      const operation = `${action}-${taskableType}-${params.status}`

      // TODO: Need to support upsert operations for custom tasks
      switch (operation) {
        case 'create-treatment-completed':
          return createTreatmentConfirmation(params)
        case 'create-treatment-missed':
          return createTreatmentMiss(params)
        case 'create-treatment-on_hold':
          return createTreatmentHold(params)
        case 'create-treatment-refused':
          return createTreatmentRefusal(params)
        case 'update-treatment-completed':
          return updateTreatmentConfirmation(params)
        case 'update-treatment-missed':
          return updateTreatmentMiss(params)
        case 'update-treatment-on_hold':
          return updateTreatmentHold(params)
        case 'update-treatment-refused':
          return updateTreatmentRefusal(params)
        default:
          throw new Error(`Unsupported upsert operation: ${operation}`)
      }
    },
    onSuccess: async (response) => {
      if (chained) {
        return response
      }

      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'TAR Update Successful!' },
      ])

      await queryClient.invalidateQueries('tarDashboard')
      await queryClient.invalidateQueries('taskOccurrenceHistory')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpsertTaskOccurrence
