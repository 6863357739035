import React, { useEffect } from 'react'
import {
  Route, Routes, useNavigate, useParams,
} from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import LinkButtonNavigator from '@components/display/LinkButtonNavigator'
import ScheduledDoses from './ScheduledDoses/ScheduledDoses'
import DosesList from './DosesList'
import UpsertClientDoses from './UpsertClientDoses'
import ClientDose from './ClientDose/ClientDose'
import './client-doses-styles.scss'
import Exports from '../../components/patients/Exports'

const wrapper = (component) => (
  <div className="p-4">
    {component}
  </div>
)

const viewLinks = [
  {
    label: 'Schedule',
    linkTo: 'schedule',
  },
  {
    label: 'PRN',
    linkTo: 'prn',
  },
  {
    label: 'All',
    linkTo: 'all',
  },
]

function IndexPage({ clientId, patient, maxDataViewHeight }) {
  return (
    <div className="col-12 client-doses">
      <Card className="h-full client-dose-card-body">
        <div className="flex flex-column text-base text-900">
          <LinkButtonNavigator
            buttons={viewLinks}
            actionButton={{ label: 'Create Medication', linkTo: 'create' }}
          />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="schedule"
              element={
                wrapper(
                  <ScheduledDoses clientId={clientId} maxDataViewHeight={maxDataViewHeight} />,
                )
              }
            />
            <Route
              path="prn"
              element={
                wrapper(<DosesList patient={patient} filters={[{ name: 'prn', value: true }]} />)
              }
            />
            <Route
              path="all"
              element={wrapper(<DosesList patient={patient} />)}
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

function CreateDose({ patient, handleSetHeader, layoutRef }) {
  return (
    <div className="col-12">
      <UpsertClientDoses
        patient={patient}
        handleSetHeader={handleSetHeader}
        layoutRef={layoutRef}
      />
    </div>
  )
}

function Redirect({ to }) {
  const navigate = useNavigate()
  useEffect(() => navigate(to), [])
  return null
}

function ClientDoses({
  patient, handleSetHeader, maxDataViewHeight, layoutRef,
}) {
  const { patientId: clientId } = useParams()

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <>
      <Exports
        patientId={patient.id}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Routes>
        <Route index element={<Redirect to="schedule" />} />
        <Route
          path="create"
          element={(
            <CreateDose
              patient={patient}
              handleSetHeader={handleSetHeader}
              layoutRef={layoutRef}
            />
        )}
        />
        <Route
          path="manage/:doseId"
          element={(
            <ClientDose
              clientId={clientId}
              patient={patient}
              handleSetHeader={handleSetHeader}
            />
        )}
        />
        <Route
          path="/*"
          element={(
            <IndexPage
              clientId={clientId}
              patient={patient}
              maxDataViewHeight={maxDataViewHeight}
              handleSetHeader={handleSetHeader}
            />
        )}
        />
      </Routes>
    </>
  )
}

export default ClientDoses
