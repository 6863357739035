import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { momentFormats } from '@services/utils/moment'

// eslint-disable-next-line import/prefer-default-export
export function useNarcoticCountsDashboardsQuery(siteId, dateRange, statusMessage) {
  const query = useQuery({
    queryKey: ['patientNarcotics', siteId, dateRange],
    queryFn: async () => {
      const params = {
        startDate: dateRange.startDate.format(momentFormats.date),
        endDate: dateRange.endDate.format(momentFormats.date),
        siteId,
      }
      return ApiClient.get('/narcotic_counts/dashboards', { params })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return {
    ...query,
    data: query.data || {},
  }
}
