import React from 'react'
import { useCurrentOrganization } from '../App'
import InlineTimeInput from './InlineTimeEditor'

function CheckTimeInput({
  value,
  onChange,
  onSubmit,
  disabled,
}) {
  const prnCheckTimeStepMinutes = 30
  const prnCheckTimeMinMinutes = 30
  const prnCheckTimeMaxMinutes = 12 * 60

  const organization = useCurrentOrganization()
  return (
    <InlineTimeInput
      leftLabel={`An impact check will be required by ${organization.caregiverLabelSingular}`}
      rightLabel="after PRN medications are administered."
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      disabled={disabled}
      min={prnCheckTimeMinMinutes}
      max={prnCheckTimeMaxMinutes}
      step={prnCheckTimeStepMinutes}
    />
  )
}

export default CheckTimeInput
