import React from 'react'
import { Button } from 'primereact/button'
import '@components/Theme'
import { mobileRedirectUrl } from '@utils/url'

function OpenAppButton() {
  return (
    <Button
      label="Open App"
      className="p-button-text"
      onClick={() => {
        window.open(mobileRedirectUrl(), '_blank')
      }}
      style={{
        margin: '.5rem', position: 'absolute', top: 0, right: 110, zIndex: 1000,
      }}
    />
  )
}

export default OpenAppButton
