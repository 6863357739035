import { filter, find, map } from 'lodash'
import { formatSchedule, updateSig } from './utils'
import initialState from './initialState'

export function reviewableOrderReducer(state, action) {
  switch (action.type) {
    case 'updateMedicine': {
      return {
        ...state,
        medicine: {
          ...state.medicine,
          ...action,
        },
      }
    }
    case 'updatePurpose': {
      return { ...state, purpose: action.purpose }
    }
    case 'updatePurposeText': {
      return { ...state, purposeText: action.purposeText }
    }
    case 'updateNote': {
      return { ...state, note: action.note }
    }
    case 'updateAdministrationAmount': {
      return updateSig(state, action, 'administrationAmount')
    }
    case 'updateAdministrationRoute': {
      return { ...state, administrationRoute: action.administrationRoute }
    }
    case 'updateRefillsRemaining': {
      return { ...state, refillsRemaining: action.refillsRemaining }
    }
    case 'updateStartAt': {
      // At the moment we need to update both wall and regular time until
      // we remove regular start time
      const newState = updateSig(state, action, 'startAt')
      return updateSig(newState, action, 'startAtWall')
    }
    case 'updateEndAt': {
      // At the moment we need to update both wall and regular time until
      // we remove regular end time
      const newState = updateSig(state, action, 'endAt')
      return updateSig(newState, action, 'endAtWall')
    }
    case 'setPrn': {
      return updateSig(state, action, 'prn')
    }
    case 'updateFillsLeft': {
      const fill = find(
        state.doseFills,
        ({ pharmacyOrderId }) => pharmacyOrderId === action.pharmacyOrderId,
      )

      const otherFills = filter(
        state.doseFills,
        ({ pharmacyOrderId }) => pharmacyOrderId !== action.pharmacyOrderId,
      )

      const updatedFill = { ...fill, fillsLeft: action.fillsLeft }

      return { ...state, doseFills: [...otherFills, updatedFill] }
    }
    case 'removeDoseFill': {
      const fills = filter(
        state.doseFills,
        ({ pharmacyOrderId }) => pharmacyOrderId !== action.pharmacyOrderId,
      )

      return { ...state, doseFills: fills }
    }
    case 'updateInstructions': {
      return updateSig(state, action, 'instructions')
    }
    case 'initialize_schedules': {
      const schedules = map(action.schedules, (schedule) => formatSchedule({ ...action, schedule }))
      return updateSig(state, { id: action.id, schedules }, 'schedules')
    }
    case 'add_schedule': {
      const sigSchedules = state.sigs.find(({ id }) => id === action.id)?.schedules ?? []
      const newSchedule = formatSchedule({
        ...action,
        schedule: { ...action.schedule },
      })
      return updateSig(state, { id: action.id, schedules: [...sigSchedules, newSchedule] }, 'schedules')
    }
    case 'update_schedule': {
      const sigSchedules = state.sigs.find(({ id }) => id === action.id)?.schedules ?? []
      const currentSchedules = sigSchedules.filter(({ rrule, time }) => (
        rrule !== action.schedule.rrule
        && time.format('HH:mm') !== action.schedule.time.format('HH:mm')
      ))
      return updateSig(state, { id: action.id, schedules: [...currentSchedules, formatSchedule(action)] }, 'schedules')
    }
    case 'delete_schedule': {
      const sigSchedules = state.sigs.find(({ id }) => id === action.id)?.schedules ?? []
      const currentSchedules = sigSchedules.filter(({ rrule, time }) => (
        rrule !== action.schedule.rrule
        || time.format('HH:mm') !== action.schedule.time.format('HH:mm')
      ))

      let scheduleToDelete = find(
        sigSchedules,
        ({ rrule, time }) => (
          rrule === action.schedule.rrule
          && time.format('HH:mm') === action.schedule.time.format('HH:mm')
        ),
      )

      scheduleToDelete = { ...scheduleToDelete, _destroy: true }
      return updateSig(state, { id: action.id, schedules: [...currentSchedules, scheduleToDelete] }, 'schedules')
    }
    case 'resetState': {
      return initialState({
        ...action.reviewableOrder,
        matchedDose: state.matchedDose,
      })
    }
    case 'updateMatchedDose': {
      return initialState({
        ...action.reviewableOrder,
        matchedDose: {
          ...action.matchedDose,
          doseSigs: action.matchedDose?.sigs ?? [],
        },
      })
    }
    case 'updateStartAdministrationTime': {
      return updateSig(state, action, 'startAdministrationTime')
    }
    case 'updateEndAdministrationTime': {
      return updateSig(state, action, 'endAdministrationTime')
    }
    default: {
      throw Error(`Unknown action: ${action.type}`)
    }
  }
}

export default reviewableOrderReducer
