import React from 'react'
import moment from 'moment-timezone'
import { Card } from 'primereact/card'
import { TreatmentCategoryTag, TreatmentDirectiveTag } from '@components/treatments/tags'
import { momentFormats } from '@services/utils/moment'
import './ReviewableTreatmentCard.scss'

function ReviewableTreatmentCard({ reviewableOrder, treatment, timezone }) {
  if (!reviewableOrder || !treatment) {
    return null
  }

  const header = (
    <div className="flex flex-row justify-content-between align-items-center card-header">
      <div className="flex flex-row gap-2">
        <TreatmentDirectiveTag treatment={treatment} directives={reviewableOrder.directives} />
        <TreatmentCategoryTag treatment={treatment} />
      </div>
      <div className="flex flex-row gap-2 align-items-center text-sm text-color-secondary">
        {treatment.orderNumber}
        &nbsp;|&nbsp;
        {treatment.pharmacy?.name}
      </div>
    </div>
  )

  const startDate = treatment.startAt ? moment(treatment.startAt).tz(timezone).format(momentFormats.dateYear) : ''
  const endDate = treatment.endAt ? moment(treatment.endAt).tz(timezone).format(momentFormats.dateYear) : ''

  return (
    <Card header={header} className="reviewable-treatment-card">
      <div className="text-md font-medium p-0 m-0">
        {treatment.instructions}
      </div>
      <div className="flex flex-column align-items-start p-text-secondary mt-1">
        <p className="m-0">{treatment.rawAdministrationTimes}</p>
        <p className="m-0">{`${startDate} - ${endDate}`}</p>
      </div>
    </Card>
  )
}

export default ReviewableTreatmentCard
