import moment from 'moment-timezone'
import { tarHistoryStatusLabels } from '@components/patients/AdherenceDashboard/config'
import { enumerateDaysBetweenMoments } from '@services/utils'
import { momentFormats } from '@services/utils/moment'

/**
 * Formats the occurrences of tasks based on a specified timezone.
 *
 * @param {Array<Object>} tarTasks - An array of task objects containing an array of occurrences.
 * @returns {Array<Object>} - A new tasks array with the occurrences formatted and grouped by date.
 */
export function formatOccurrences(tarTasks) {
  if (!tarTasks) return []

  return tarTasks.map((task) => {
    const occurrences = task.occurrences.reduce((acc, occurrence) => {
      const baseTime = occurrence.expectedAt || occurrence.actionedAt
      const date = moment(baseTime).tz(occurrence.timezone).format(momentFormats.date)
      acc[date] = acc[date] || []
      acc[date].push(occurrence)
      return acc
    }, {})
    return { ...task, occurrences }
  })
}

/**
 * Gets the label for a task occurrence status.
 *
 * @param {string} status
 * @returns {string}
 */
export const getOccurrenceHistoryLabel = (status) => {
  const label = tarHistoryStatusLabels[status]
  return label || 'Unknown'
}

/**
 * Sorts an array of tasks by the instructions property of each task's treatment.
 *
 * @param {Array<Object>} tarTasks - An array of task objects.
 * @returns {Array<Object>} - The sorted array of task objects.
 */
export function sortTarTasks(tarTasks) {
  return tarTasks.sort(
    (a, b) => (a.treatment?.instructions || '').localeCompare(b.treatment?.instructions || ''),
  )
}

/**
 * Creates column props for the data table based on the provided parameters.
 *
 * @param {Object} params - The parameters for generating the columns.
 * @param {Object} params.dateRange - The date range for the columns.
 * @param {Object} params.dateRange.startDate - The start date of the range.
 * @param {Object} params.dateRange.endDate - The end date of the range.
 * @param {Function} params.treatmentTemplate - The template function for rendering treatment cells.
 * @param {Function} params.taskTemplate - The template function for rendering task cells.
 * @param {Function} params.occurrenceTemplate - The template function to render occurrence cells.
 * @param {boolean} [params.showTime=true] - Flag to determine if the time column should be shown.
 * @returns {Array<Object>} - The array of column configuration objects.
 */
export function tarTasksColumns({
  dateRange,
  treatmentTemplate,
  taskTemplate,
  occurrenceTemplate,
  showTime = true,
}) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'treatment.id',
      header: 'Treatment',
      body: treatmentTemplate,
      style: { minWidth: '12rem', width: '12rem', maxWidth: '12rem' },
      frozen: true,
    },
    {
      field: 'task.id',
      header: 'Task',
      body: taskTemplate,
      style: { minWidth: '12rem', width: '12rem', maxWidth: '12rem' },
      frozen: true,
    },
  ]

  if (showTime) {
    columns.push({
      field: 'time',
      header: 'Time',
      style: { minWidth: '6rem', width: '6rem', maxWidth: '6rem' },
      frozen: true,
    })
  }

  days.forEach((day) => {
    columns.push({
      field: `occurrences.${day.format(momentFormats.date)}`,
      header: day.format('MMM Do'),
      body: occurrenceTemplate,
      style: { minWidth: '6rem', width: '6rem', maxWidth: '6rem' },
    })
  })

  return columns
}
