import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Exports from '@components/patients/Exports'
import Redirect from '@components/routing/Redirect'
import ManageTreatmentPage from './ManageTreatmentPage'
import TreatmentsOverviewPage from './TreatmentsOverviewPage'
import './treatments-styles.scss'
import CreateTreatmentPage from './CreateTreatmentPage'
import ReviewTreatmentPage from './ReviewTreatmentPage'

function Treatments({
  patient, handleSetHeader, maxDataViewHeight, layoutRef,
}) {
  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <>
      <Exports
        patientId={patient.id}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Routes>
        <Route index element={<Redirect to="all" />} />
        <Route
          path="create"
          element={(
            <CreateTreatmentPage
              clientId={patient.id}
              handleSetHeader={handleSetHeader}
              layoutRef={layoutRef}
            />
          )}
        />
        <Route
          path="manage/:treatmentId"
          element={(
            <ManageTreatmentPage
              patient={patient}
              handleSetHeader={handleSetHeader}
            />
          )}
        />
        <Route
          path="review/:treatmentId"
          element={(
            <ReviewTreatmentPage
              patient={patient}
              handleSetHeader={handleSetHeader}
            />
          )}
        />
        <Route
          path="/*"
          element={(
            <TreatmentsOverviewPage
              patient={patient}
              maxDataViewHeight={maxDataViewHeight}
              handleSetHeader={handleSetHeader}
            />
          )}
        />
      </Routes>
    </>
  )
}

export default Treatments
