import React from 'react'
import { Section } from './SharedComponents'
import { DoseFieldGroup } from './DoseComponents'

function DoseSection({
  compositeOrder,
  prescription,
}) {
  const helpText = 'Review and edit medication and dosing information.'

  return (
    <Section header="Dosing" helpText={helpText}>
      <DoseFieldGroup
        compositeOrder={compositeOrder}
        prescription={prescription}
      />
    </Section>
  )
}

export default DoseSection
