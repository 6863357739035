import React, { useRef } from 'react'
import { TreatmentCategoryTag, TreatmentStatusTag, TreatmentTypeTag } from '@components/treatments/tags'
import { TreatmentActionMenu } from '@components/treatments/TreatmentActionMenu'

function TreatmentTitle({ treatment }) {
  return (
    <span className="text-lg flex align-items-end">
      <span>Treatment</span>
      <span className="ml-2">
        <TreatmentStatusTag treatment={treatment} />
      </span>
      <span className="ml-2">
        <TreatmentCategoryTag treatment={treatment} />
      </span>
      <span className="ml-2">
        <TreatmentTypeTag treatment={treatment} />
      </span>
    </span>
  )
}

function TreatmentHeader({ treatment }) {
  const statusMessage = useRef(null)

  return (
    <div className="col-12" data-testid="treatment-header">
      <div className="p-menubar bg-white flex flex-row align-items-center px-3">
        <div className="flex flex-column gap-2 align-items-baseline">
          <TreatmentTitle treatment={treatment} />
          {
            treatment.orderNumber && (
              <span className="text-sm text-600">
                Order Number:
                <span className="text-900 ml-1">{treatment.orderNumber}</span>
              </span>
            )
          }
          {
            treatment.instructions && (
              <span className="text-sm text-600">
                Instructions:
                <span className="text-900 ml-1">{treatment.instructions}</span>
                <i className="text-500 ml-1">(not shown in app)</i>
              </span>
            )
          }
          <div>
            {
              treatment.currentHold && treatment.currentHold.note
              && (
                <span className="text-sm text-600">
                  Hold Note:
                  <span className="text-900 ml-1">{treatment.currentHold.note}</span>
                </span>
              )
            }
          </div>
        </div>
        <div className="ml-auto">
          <TreatmentActionMenu
            statusMessage={statusMessage}
            treatment={treatment}
          />
        </div>
      </div>
    </div>
  )
}

export default TreatmentHeader
