export function iTherapyDocsRedirectUrl() {
  const baseUrl = window.location.origin

  return `${baseUrl}/itd_redirect`
}

export function mobileRedirectUrl(path) {
  const baseUrl = window.location.origin

  if (!path) {
    return `${baseUrl}/mobile_redirect`
  }

  return `${baseUrl}/mobile_redirect?path=${path}`
}
