import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useDeleteMeasurement = ({ patientId, statusMessage, mode = 'tar' }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ measurementId, type, checkId }) => (
      ApiClient.delete(`/measurements/${measurementId}`, { data: { type, patientId, checkId } })
    ),
    onSuccess: async () => {
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Measurement was successfully deleted!' },
      ])

      if (mode === 'tar') {
        queryClient.invalidateQueries('tarDashboard')
        queryClient.invalidateQueries('taskOccurrenceHistory')
      } else {
        queryClient.invalidateQueries('marDashboard')
        queryClient.invalidateQueries('consumptionHistory')
      }
    },
    onError: async (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useDeleteMeasurement
