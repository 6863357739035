import moment from 'moment'
import { enumerateDaysBetweenMoments } from '@services/utils'
import { momentFormats } from '@services/utils/moment'
import { marHistoryStatusLabels } from '@components/patients/AdherenceDashboard/config'

/**
 * Calculates the appropriate administration event code based on the old and new consumption status.
 *
 * @param {string} oldStatus
 * @param {string} newStatus
 * @returns {string}
 */
export const getAdministrationEventCode = (oldStatus, newStatus) => {
  const formattedNewStatus = newStatus.toUpperCase().replace('LEAVE_OF_ABSENCE', 'LOA')

  if (!oldStatus) {
    return `CREATE_${formattedNewStatus}`
  }

  if (oldStatus === newStatus) {
    return 'OVERRIDE_DETAILS'
  }

  return `OVERRIDE_${formattedNewStatus}`
}

export const getConsumptionStatusLabel = (code) => {
  const statusObj = marHistoryStatusLabels[code]
  return statusObj || 'Unknown'
}

export function marColumns(
  {
    dateRange,
    marApprovals,
    doseTemplate,
    doseSigTemplate,
    consumptionTemplate,
    headerTemplate,
    showTime = true,
  },
) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'doseSig.dose.id',
      header: 'Medicine',
      body: doseTemplate,
      style: { minWidth: '18rem', width: '18rem', maxWidth: '18rem' },
      frozen: true,
    },
    {
      field: 'doseSig.id',
      header: 'Details',
      body: doseSigTemplate,
      style: { minWidth: '12rem', width: '12rem', maxWidth: '12rem' },
      frozen: true,
    },
  ]

  if (showTime) {
    columns.push({
      field: 'time',
      header: 'Time',
      body: (rowData) => rowData.time?.toUpperCase(),
      style: { minWidth: '6rem', width: '6rem', maxWidth: '6rem' },
      frozen: true,
    })
  }

  const dayCount = dateRange.endDate.diff(dateRange.startDate, 'days') + 1

  days.forEach((day) => {
    const dateIter = day.clone()
    // In the MAR view, if we show the weekly or daily view we show the day and the month
    // then for the monthly view we show the day only to have smaller columns
    const formattedDate = moment(dateIter).format(dayCount > 7 ? 'D' : 'MMM Do')

    let dayMarApprovals = []
    if (marApprovals) {
      const dateIterStr = dateIter.format('YYYY-MM-DD')
      dayMarApprovals = marApprovals.filter((mar) => mar.days.includes(dateIterStr))
    }

    columns.push({
      field: `${day.format(momentFormats.basicDate)}`,
      header: headerTemplate(dateIter, formattedDate, dayMarApprovals),
      body: consumptionTemplate,
      style: { minWidth: '6rem', width: '6rem', maxWidth: '6rem' },
    })
  })

  return columns
}
