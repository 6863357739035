import React from 'react'
import AttributeCard from '@components/display/AttributeCard/AttributeCard'
import { useCurrentUser } from '@components/App'

function RoleCardTitle({ title }) {
  return (
    <div className="flex flex-row">
      <div>{title}</div>
    </div>
  )
}

function RoleCard({
  children, title, roles = [], ...props
}) {
  const currentUser = useCurrentUser()
  const isAllowedRole = roles.includes(currentUser.role)

  if (!isAllowedRole) {
    return null
  }

  return (
    <AttributeCard
      title={{ body: (<RoleCardTitle title={title} />) }}
      {...props}
    >
      {children}
    </AttributeCard>
  )
}

export default RoleCard
