import React, { useEffect, useState } from 'react'
import Block from '@components/display/Block'
import { useCurrentOrganization } from '@components/App'
import Exports from './Exports'
import NotificationTestButton from '../display/UserDevices/NotificationTestButton'
import AdherenceDashboard from './AdherenceDashboard/AdherenceDashboard'
import './AdherenceDashboard/AdherenceDashboard.scss'
import MedicationsDueNotification from './MedicationsDueNotification'
import MatchNotification from './MatchNotification'

function PatientDashboard({
  patientId,
  patient,
  handleSetHeader,
  maxDataViewHeight,
  rootPath,
}) {
  const organization = useCurrentOrganization()
  const [primaryAction, setPrimaryAction] = useState(null)

  const matchNotificationTemplate = () => (
    <MatchNotification
      rootPath={rootPath}
      patientId={patientId}
      organization={organization}
    />
  )

  const medicationsDueTemplate = () => (
    <MedicationsDueNotification patient={patient} />
  )

  useEffect(() => {
    if (patient && primaryAction) {
      const notifications = []

      if (patient.matchingUsers.length > 0) {
        notifications.push(matchNotificationTemplate)
      }

      if (patient.allowedConsumptionsCount > 0) {
        notifications.push(medicationsDueTemplate)
      }
      handleSetHeader({
        primaryAction,
        notifications,
      })
    }
  }, [primaryAction, patient])

  const setupPrimaryAction = (exportAction) => {
    const actionPrimary = (
      <div className="flex flex-row gap-2">
        <NotificationTestButton userId={patientId} />
        {exportAction}
      </div>
    )

    setPrimaryAction(actionPrimary)
  }

  return (
    <>
      <Exports
        patientId={patientId}
        exportsMenuSetupCallBack={setupPrimaryAction}
      />
      <Block col={12}>
        {!organization.ccmEnabled
          && (
          <div className="flex flex-column gap-4">
            {patient && <AdherenceDashboard patient={patient} title="Adherence" maxDataViewHeight={maxDataViewHeight} />}
          </div>
          )}
      </Block>
    </>
  )
}

export default PatientDashboard
