export const offsetOptions = [
  { title: '0 minutes', minutes: 0 },
  { title: '5 minutes', minutes: 5 },
  { title: '10 minutes', minutes: 10 },
  { title: '15 minutes', minutes: 15 },
]

export const getFriendlyTime = (notificationType, selectedOffset) => {
  switch (notificationType) {
    case 'impact_check_due_now':
      return selectedOffset === 0
        ? 'When impact check should be answered'
        : `${selectedOffset} mins after PRN is given`
    case 'reminder':
      return 'When recommended consumption window begins'
    case 'due_now':
      return selectedOffset === 0
        ? 'When medication is due'
        : `${selectedOffset} mins before medication due`
    case 'warning':
      return `${selectedOffset} minutes after medication is due`
    case 'missing':
      return selectedOffset === 0
        ? 'When medication is missed'
        : `${selectedOffset} minutes after medication is missed`
    default:
      return `Error: invalid notification type (${notificationType})`
  }
}
