import React, { useEffect, useRef, useState } from 'react'
import { filter, split } from 'lodash'
import { Button } from 'primereact/button'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import AttributeCard from '@components/display/AttributeCard'
import ReviewScheduledNoteTable from '@components/scheduledNotes/ReviewScheduledNoteTable'
import { ReviewTaskTable } from '@components/tasks/ReviewTaskTable'
import IgnoreChangesDialog from '@components/treatments/IgnoreChangesDialog'
import { useAcknowledgeTreatment } from '@hooks/treatments'

const getActionOptions = (directives) => {
  const actionOptions = []

  if (directives.includes('new_treatment')) {
    actionOptions.push(
      { label: 'Add as Task', value: 'create_task' },
      { label: 'Add as Reminder in Med Pass', value: 'create_remind' },
    )
  }

  if (directives.includes('treatment_update')) {
    actionOptions.push(
      { label: 'Update as Task', value: 'update_task' },
      { label: 'Update as Reminder in Med Pass', value: 'update_remind' },
    )
  }

  if (directives.includes('discontinue_treatment') || directives.includes('cancel_treatment')) {
    actionOptions.push(
      { label: 'Discontinue Treatment', value: 'discontinue' },
    )
  }

  return actionOptions
}

const getActionButtonText = (mode) => {
  switch (mode) {
    case 'create_task':
      return 'Onboard Treatment'
    case 'create_remind':
      return 'Onboard Treatment'
    case 'update_task':
      return 'Update Treatment'
    case 'update_remind':
      return 'Update Treatment'
    case 'discontinue':
      return 'Discontinue Treatment'
    default:
      return ''
  }
}

const getDefaultMode = (treatment, directive) => {
  switch (directive) {
    case 'new_treatment':
      return 'create_task'
    case 'treatment_update':
      return treatment?.tasks?.length ? 'update_task' : 'update_remind'
    case 'discontinue_treatment':
    case 'cancel_treatment':
      return 'discontinue'
    default:
      return 'ignore'
  }
}

const getModeText = (mode) => {
  switch (mode) {
    case 'create_task':
      return 'A task will be created using the treatment details and the following information.'
    case 'create_remind':
      // TODO: Rework as a multiline div with examples
      return 'This treatment has no task to complete and will appear as a reminder during each med pass.'
    case 'update_task':
      return 'The treatment and task will be updated using the treatment details and the following information.'
    case 'update_remind':
      return 'The treatment will be updated and appear as a reminder during each med pass.'
    case 'discontinue':
      return 'The treatment will be updated and discontinued and any task will be updated and ended.'
    default:
      return ''
  }
}

function ActionSection({ patient, treatment, directives }) {
  const statusMessage = useRef(null)
  const [showIgnoreChangesDialog, setShowIgnoreChangesDialog] = useState(false)
  const [actionOptions, setActionOptions] = useState([])
  const [selectedMode, setSelectedMode] = useState(null)
  const [task, setTask] = useState({})
  const [scheduledNote, setScheduledNote] = useState({})

  const {
    mutateAsync: acknowledgeTreatment,
  } = useAcknowledgeTreatment({ statusMessage })

  useEffect(() => {
    setActionOptions(getActionOptions(directives?.map((d) => d.directive)) || [])
    setSelectedMode(getDefaultMode(treatment, directives?.find((d) => d.isPrimary)?.directive))
  }, [treatment, directives])

  const showReviewTaskTable = ['create_task', 'update_task'].includes(selectedMode)
  const showReviewReminderTable = ['create_remind', 'update_remind'].includes(selectedMode)

  const handleActionChange = (e) => {
    setSelectedMode(e.value)
  }

  const handleAcknowledgeTreatment = async (mode) => {
    const params = {
      id: treatment.id,
      mode: mode || selectedMode,
      treatment: {
        instructions: treatment.instructions,
        rawAdministrationTimes: treatment.rawAdministrationTimes,
        startAt: treatment.startAt,
        endAt: treatment.endAt,
      },
    }

    if (showReviewTaskTable) {
      params.task = {
        id: task.id,
        taskType: task.taskType,
        taskAction: task.taskAction,
        title: task.title,
        description: task.description,
        showOnTar: task.showOnTar,
        startAt: task.startAt,
        endAt: task.endAt,
        schedules: task.schedules.map((schedule) => ({
          flexibilityAttributes: schedule.flexibility,
          time_hh_mm: schedule.time_hh_mm,
          // The rrule.js library converts rrules to string
          // in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"
          // The below line extracts the rule itself, omitting the "RRULE:"
          // so that it is compatible with API validations
          rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
        })),
      }
    }

    if (showReviewReminderTable) {
      params.scheduledNote = {
        id: scheduledNote.id,
        note: scheduledNote.note,
        startAt: scheduledNote.startAt,
        endAt: scheduledNote.endAt,
      }
    }

    await acknowledgeTreatment(params)
  }

  const actionTitleTemplate = () => (
    <div className="flex flex-row align-items-center gap-2">
      <SelectButton
        allowEmpty={false}
        onChange={handleActionChange}
        optionLabel="label"
        optionValue="value"
        options={actionOptions}
        placeholder="Select an option"
        value={selectedMode}
      />
    </div>
  )

  return (
    <div className="col-12">
      <Toast ref={statusMessage} />
      <IgnoreChangesDialog
        onConfirm={() => handleAcknowledgeTreatment('ignore')}
        onHide={() => setShowIgnoreChangesDialog(false)}
        visible={showIgnoreChangesDialog}
      />
      <AttributeCard title={{ body: (actionTitleTemplate()) }}>
        <div className="flex flex-row">
          <span className="opacity-70">{getModeText(selectedMode)}</span>
        </div>
        {
          showReviewTaskTable && (
            <ReviewTaskTable
              patient={patient}
              treatment={treatment}
              task={task}
              setTask={setTask}
            />
          )
        }
        {
          showReviewReminderTable && (
            <ReviewScheduledNoteTable
              patient={patient}
              treatment={treatment}
              scheduledNote={scheduledNote}
              setScheduledNote={setScheduledNote}
            />
          )
        }
        <div className="flex flex-row justify-content-end gap-2">
          <Button
            icon="pi pi-trash"
            label="Ignore Changes"
            className="p-button-sm p-button-secondary"
            onClick={() => setShowIgnoreChangesDialog(true)}
          />
          <Button
            icon="pi pi-check"
            label={getActionButtonText(selectedMode)}
            className="p-button-sm"
            onClick={() => handleAcknowledgeTreatment(selectedMode)}
          />
        </div>
      </AttributeCard>
    </div>
  )
}

export default ActionSection
