import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import {
  DetailsForm,
  DeviceTypeSelectForm,
  SiteForm,
  PatientsSelectForm,
} from '@components/medboxes/MedBoxForm'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useCurrentOrganization } from '@components/App'
import { useMedBoxMutation } from '@hooks/medBoxes'
import { useSites } from '@hooks/sites'
import { usePatientsBySite } from '../Patients/patientHooks'
import {
  LABELS, MED_BOXES_PATH, MED_BOX_TYPE, E_KITS_PATH, E_KIT_TYPE,
} from './config'

function CreateMedBox({ useMedBoxesHeader, type, isSuperAdmin }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const organization = useCurrentOrganization()
  const { setHeader } = useMedBoxesHeader()

  const [details, setDetails] = useState({
    label: '',
    mac: '',
  })
  const [selectedSiteId, setSelectedSiteId] = useState(null)
  const [selectedPatientIds, setSelectedPatientIds] = useState([])
  const [deviceType, setDeviceType] = useState(type)

  const {
    mutateAsync: createMedBox,
    isLoading: createIsLoading,
  } = useMedBoxMutation(statusMessage)

  const { data: sites, isLoading: isSitesLoading } = useSites(
    organization.id,
    statusMessage,
  )
  const { data: patients, isLoading: isPatientsLoading } = usePatientsBySite(selectedSiteId)

  const isEKit = type === E_KIT_TYPE

  const labelText = LABELS[type]
  const basePath = type === MED_BOX_TYPE ? MED_BOXES_PATH : E_KITS_PATH

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-box mr-2" />
        {labelText}
      </span>
    ),
    linkTo: `/${basePath}`,
  }

  const breadcrumbs = [
    {
      label: 'Create',
      template: <span>Create</span>,
    },
  ]

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs, primaryAction: null,
    })
  }, [])

  const handleCreateMedBox = (e) => {
    e.preventDefault()
    const simpill = {
      ...details,
      siteId: selectedSiteId,
      organizationId: organization.id,
      deviceType,
    }
    if (type === MED_BOX_TYPE) {
      simpill.patientIds = selectedPatientIds
    }
    createMedBox(
      {
        simpill,
      },
      {
        onSuccess: (data) => {
          navigate(`/${basePath}/${data.id}`)
        },
      },
    )
  }

  const handleSiteChange = (site) => {
    setSelectedSiteId(site)
    setSelectedPatientIds([])
  }

  return (
    <div className="flex flex-column">
      <div className="flex flex-row justify-content-start flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title={`Create ${labelText}`} fullHeight>
            { !isEKit && (
              <DeviceTypeSelectForm
                deviceType={deviceType}
                setDeviceType={setDeviceType}
              />
            )}
            <DetailsForm
              isSuperAdmin={isSuperAdmin}
              details={details}
              setDetails={(data) => setDetails({ ...details, ...data })}
            />
            <SiteForm
              sites={sites}
              selectedSite={selectedSiteId}
              setSelectedSite={handleSiteChange}
              isSitesLoading={isSitesLoading}
            />
            {
              !isEKit
            && (
            <PatientsSelectForm
              patients={patients}
              selectedPatientIds={selectedPatientIds}
              setSelectedPatientIds={setSelectedPatientIds}
              isPatientsLoading={isPatientsLoading}
              siteAlreadySelected={!!selectedSiteId}
            />
            )
          }
            <Button
              label="Save"
              className="p-button-sm m-2"
              loading={createIsLoading}
              onClick={handleCreateMedBox}
            />
          </FormWrapper>
        </div>
      </div>
    </div>
  )
}

export default CreateMedBox
