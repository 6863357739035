import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useMedBoxMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (medBox) => ApiClient.post('/simpills', medBox),
    onSuccess: () => {
      queryClient.invalidateQueries('medBox')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useMedBoxMutation
