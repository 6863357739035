import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import FormWrapper from '@components/display/Form/FormWrapper'
import { TaskForm } from '@components/tasks/TaskForm'
import { useCreateTask } from '@hooks/tasks'

function CreateTaskPage({ clientId }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()

  const [task, setTask] = useState({
    taskType: 'routine',
    taskAction: 'binary',
    startAt: '',
    endAt: '',
    title: '',
    description: '',
    showOnTar: true,
    schedules: [],
  })

  const {
    mutateAsync: createTask,
    isLoading,
  } = useCreateTask({ statusMessage })

  const handleCreateTask = async () => {
    const createdTask = await createTask({
      ...task,
      patientId: clientId,
      taskCategory: 'custom',
    })

    if (createdTask) {
      navigate(`/admin/patients/${clientId}/tasks/manage/${createdTask.id}`)
    }
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Task" fullHeight>
          <TaskForm
            task={task}
            setTask={(value) => setTask((t) => ({ ...t, ...value }))}
          />
          <Button
            label="Create Task"
            className="p-button-sm mt-4"
            loading={isLoading}
            onClick={handleCreateTask}
          />
        </FormWrapper>
      </div>
    </div>
  )
}

export default CreateTaskPage
