import { useCurrentUser } from '@components/App'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Button } from 'primereact/button'
import Menu from './AdherenceMenu'
import TarTasks from './TAR/TarTasks'

function TypeNavigator({ granularity, setApprovalDialogVisible }) {
  const currentUser = useCurrentUser()
  return (
    <div className="flex flex-row gap-3 align-items-center w-full">
      <Menu />
      <div className="flex-grow-1" />
      {
        setApprovalDialogVisible && currentUser.role === 'site_admin' && granularity === 'Monthly' && (
          <Button
            label="Approve"
            className="p-button-primary"
            onClick={() => setApprovalDialogVisible(true)}
          />
        )
      }
    </div>
  )
}

function TAR({
  patient,
  startDate,
  endDate,
  granularity,
}) {
  const dateRange = { startDate, endDate }

  return (
    <div className="flex flex-column gap-3">
      <TypeNavigator
        granularity={granularity}
      />
      <Routes>
        <Route
          index
          element={(
            <Navigate
              to="schedule"
              replace
            />
          )}
        />
        <Route
          path="schedule"
          element={(
            <TarTasks
              patient={patient}
              dateRange={dateRange}
              type="routine"
            />
          )}
        />
        <Route
          path="prn"
          element={(
            <TarTasks
              patient={patient}
              dateRange={dateRange}
              type="prn"
            />
          )}
        />
      </Routes>
    </div>
  )
}

export default TAR
