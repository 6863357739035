import React from 'react'
import { injectionSiteOptions } from './config'

function InjectionSite({
  inline = false,
  value,
}) {
  const injectionSiteOption = injectionSiteOptions.find((option) => option.value === value)

  if (!injectionSiteOption) {
    return null
  }

  if (inline) {
    return (
      <div className="flex flex-row gap-2 text-xs">
        <i className="fa-solid fa-syringe" />
        <strong>{injectionSiteOption.code}</strong>
        <span>{injectionSiteOption.description}</span>
      </div>
    )
  }

  return (
    <div className="flex flex-row gap-2">
      <i className="fa-solid fa-syringe" />
      <div className="mr-3 flex-shrink-0" style={{ width: '4rem' }}>
        <strong>{injectionSiteOption.code}</strong>
      </div>
      <div className="flex flex-column">
        <span>{injectionSiteOption.description}</span>
      </div>
    </div>
  )
}

export default InjectionSite
