import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import { Messages } from 'primereact/messages'
import { Calendar } from 'primereact/calendar'
import { useCurrentOrganization } from '@components/App'
import { useCreateReportMedicationRelease } from '@hooks/reports'

function MedicationReleaseReportDialog({ patientId, isVisible, setIsVisible }) {
  const organization = useCurrentOrganization()
  const statusMessageRef = useRef(null)
  const {
    mutateAsync: createReportMedicationRelease,
    isLoading: isExporting,
  } = useCreateReportMedicationRelease(statusMessageRef, patientId)

  const [startDate, setStartDate] = useState(moment().startOf('day').toDate())
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate())

  const handleStartDateChange = (e) => {
    const newStartDate = e.value
    setStartDate(newStartDate)
    if (endDate && moment(newStartDate).isAfter(endDate)) {
      setEndDate(newStartDate)
    }
  }

  const handleEndDateChange = (e) => {
    const newEndDate = e.value
    setEndDate(newEndDate)
    if (startDate && moment(newEndDate).isBefore(startDate)) {
      setStartDate(newEndDate)
    }
  }

  const handleCreateReport = async () => {
    createReportMedicationRelease({
      startDate, endDate,
    }, {
      onSuccess: () => setIsVisible(false),
    })
  }

  return (
    <Dialog
      header={`Export ${organization.patientLabelSingular} Medication Release Report`}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={statusMessageRef} />
      <div className="flex flex-column">
        <label htmlFor="medication-release-start-date">Start Date</label>
        <Calendar
          id="medication-release-start-date"
          className="mt-1 mb-3 border-round-md"
          value={startDate}
          stepMinute={15}
          onChange={handleStartDateChange}
          showTime={false}
          showIcon
          icon={<i className="pi pi-clock" />}
          minDate={moment().toDate()}
          maxDate={moment().add(9, 'day').toDate()}
          selectionMode="single"
        />
        <label htmlFor="medication-release-end-date">End Date</label>
        <Calendar
          id="medication-release-end-date"
          className="mt-1 mb-3 border-round-md"
          value={endDate}
          stepMinute={15}
          onChange={handleEndDateChange}
          showTime={false}
          showIcon
          icon={<i className="pi pi-clock" />}
          minDate={moment().toDate()}
          maxDate={moment().add(9, 'day').toDate()}
          selectionMode="single"
        />
        <Button
          className="mt-1 p-button-sm p-button-outlined"
          label="Export"
          onClick={handleCreateReport}
          loading={isExporting}
          disabled={isExporting}
        />
      </div>
    </Dialog>
  )
}

export default MedicationReleaseReportDialog
