import React from 'react'
import { compact } from 'lodash'
import { Route, Routes, useParams } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { useCaregiver } from './caregiversHooks'
import CaregiverDashboard from '../../components/caregivers/CaregiverDashboard'
import UpdateCaregiver from './UpdateCaregiver'
import CaregiverSites from '../../components/caregivers/CaregiverSites'
import CaregiverPatientsAssignment from '../CaregiverPatientsAssignment/CaregiverPatientsAssignment'

function Caregiver({ useCaregiverHeader }) {
  const { caregiverId } = useParams()
  const { setHeader, maxDataViewHeight } = useCaregiverHeader()
  const organization = useCurrentOrganization()
  const { data: caregiver, isLoading } = useCaregiver(caregiverId, organization.id)
  const currentUser = useCurrentUser()

  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const canViewPatients = isCurrentUserAdmin && !organization.deviceBasedAccess
  const canViewSites = isCurrentUserAdmin && organization.hasSites && (
    !organization.deviceBasedAccess || caregiver?.restrictSiteAccess
  )

  const title = {
    label: (
      <span className={`flex flex-row align-items-center ${!isCurrentUserAdmin && 'py-2'}`} id="caregiver-name-tab">
        <i className="text-xl pi pi-user mr-2" />
        {isLoading && (
          <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
        )}
        {!isLoading && (
          <>
            <span className="fadein">
              {`${caregiver.firstName} ${caregiver.lastName}`}
            </span>
            {!caregiver?.isActive && (
              <Tag className="ml-2" value="Deactivated" severity="danger" />
            )}
          </>
        )}
      </span>
    ),
    linkTo: `/admin/caregivers/${caregiverId}`,
  }

  const tabs = compact([
    canViewPatients && {
      label: organization.patientLabelPlural,
      linkTo: `${caregiverId}/patients`,
    },
    canViewSites && {
      label: 'Sites',
      linkTo: `${caregiverId}/sites`,
    },
  ])

  const breadcrumbs = isCurrentUserAdmin ? [
    {
      label: 'Caregiver',
      template: <span>{organization.caregiverLabelSingular}</span>,
    },
  ] : []

  const handleSetHeader = (props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }

  return (
    <Routes>
      <Route
        index
        element={(
          <CaregiverDashboard
            maxDataViewHeight={maxDataViewHeight}
            isCurrentUserAdmin={isCurrentUserAdmin}
            caregiverId={caregiverId}
            handleSetHeader={handleSetHeader}
            caregiverLoading={isLoading}
            caregiver={caregiver}
          />
        )}
      />
      <Route
        path="update"
        element={<UpdateCaregiver caregiver={caregiver} handleSetHeader={handleSetHeader} />}
      />
      {
        canViewSites && (
          <Route
            path="sites"
            element={(
              <CaregiverSites
                maxDataViewHeight={maxDataViewHeight}
                caregiver={caregiver}
                handleSetHeader={handleSetHeader}
                organization={organization}
              />
            )}
          />
        )
      }
      {
        canViewPatients && (
          <Route
            path="patients"
            element={(
              <CaregiverPatientsAssignment
                caregiver={caregiver}
                handleSetHeader={handleSetHeader}
              />
            )}
          />
        )
      }
    </Routes>
  )
}

export default Caregiver
