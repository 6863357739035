import React from 'react'
import { Badge } from 'primereact/badge'
import { mobileRedirectUrl } from '@utils/url'

const onClickViewMobileMedPass = () => {
  const mobileUrl = mobileRedirectUrl()
  window.open(mobileUrl, '_blank')
}

function MedsDueHeader({ label, count }) {
  return (
    <div className="custom-tab-header flex align-items-center">
      {/* TODO: Not sure why the size attribute here has no effect, maybe because it's inside a
        tab? So I hardcoded the size values. */}
      {/* TODO: Maybe the pointer cursor should be set globally for buttons? */}
      <Badge
        style={{
          width: '1.6rem',
          height: '1.6rem',
          lineHeight: '1.6rem',
          fontSize: '0.8rem',
          padding: 0,
        }}
        value={count}
        className="mr-2"
        severity="secondary"
      />
      <span className="mr-2">{label}</span>
      <button
        type="button"
        className="p-button-text p-button-sm p-button-secondary mobile-link"
        style={{
          fontSize: 14,
          pointerEvents: 'visible',
          cursor: 'pointer',
          border: 'none',
          backgroundColor: 'inherit',
        }}
        onClick={onClickViewMobileMedPass}
      >
        <i className="fa-solid fa-arrow-up-right-from-square" />
      </button>
    </div>
  )
}

export default MedsDueHeader
