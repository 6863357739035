import React, { useMemo, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import ActionsTable from '@components/conditions/ActionsTable'
import ConditionNote from '@components/conditions/ConditionNote'
import ConditionType from '@components/conditions/ConditionType'
import DoseScheduleSelector from './DoseScheduleSelector'
import DoseSigSelector from './DoseSigSelector'
import { useCreateDoseCondition } from './doseConditionHooks'

import './dose-condition.scss'

function CreateDoseConditionDialog({
  dose,
  doseConditionStatusMessage,
  doseSchedules = [],
  existingDoseConditions,
  visible,
  onHide,
}) {
  const [actions, setActions] = useState([])
  const [conditionType, setConditionType] = useState()
  const [conditionNote, setConditionNote] = useState()
  const [doseSig, setDoseSig] = useState()
  const [selectedSchedules, setSelectedSchedules] = useState([])
  const [isValid, setIsValid] = useState(false)

  const availableSchedules = useMemo(
    () => doseSchedules.filter((schedule) => doseSig?.id === undefined || doseSig?.id === null
      || schedule.doseSig?.id === doseSig.id),
    [doseSchedules, doseSig],
  )

  const {
    mutateAsync: createDoseCondition,
    isLoading: isCreatingDoseCondition,
  } = useCreateDoseCondition(doseConditionStatusMessage)

  const onCreateDoseCondition = () => {
    const condition = {
      doseId: dose.id,
      doseSigId: doseSig?.id,
      conditionType,
      note: conditionNote,
      scheduleIds: selectedSchedules.map((scheduleId) => parseInt(scheduleId, 10)),
      actions,
    }
    createDoseCondition(
      condition,
      {
        onSuccess: () => {
          onHide()
        },
      },
    )
  }

  const dialogHeaderTemplate = () => (
    <div className="flex flex-row align-items-baseline w-full justify-content-center">
      <h3 className="font-semibold text-lg flex flex-column gap-2">
        Add Criteria / Vitals
      </h3>
    </div>
  )

  const otherDoseConditionsForType = existingDoseConditions
    .filter((condition) => condition.conditionType === conditionType)

  const showDoseSigSelector = dose.sigs && dose.sigs.length > 0

  const disableSubmit = () => {
    if (conditionNote && actions.length === 0) {
      return false
    }
    if (isValid) {
      return false
    }
    return true
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={dialogHeaderTemplate}
      style={{ width: '75vw' }}
    >
      <div className="flex flex-column gap-4 align-items-center px-2">
        {
          showDoseSigSelector && (
            <div className="col-12 flex flex-column gap-2 text-center">
              <DoseSigSelector
                doseSig={doseSig}
                setDoseSig={setDoseSig}
                doseSigs={dose.sigs}
              />
            </div>
          )
        }
        <div className="col-12 flex flex-column gap-2 text-center">
          <ConditionType
            conditionType={conditionType}
            schedules={availableSchedules}
            existingConditions={existingDoseConditions}
            isPrn={dose.prn}
            setConditionType={setConditionType}
            editable={actions.length === 0}
          />
        </div>
        {
          !dose.prn && (
            <DoseScheduleSelector
              conditionType={conditionType}
              doseSchedules={availableSchedules}
              otherDoseConditionsForType={otherDoseConditionsForType}
              selectedSchedules={selectedSchedules}
              setSelectedSchedules={setSelectedSchedules}
            />
          )
        }
        <ConditionNote
          conditionNote={conditionNote}
          conditionType={conditionType}
          setConditionNote={setConditionNote}
        />
        <ActionsTable
          actions={actions}
          conditionType={conditionType}
          setActions={setActions}
          setIsValid={setIsValid}
        />
        <Button
          label="Create"
          className="flex w-full p-button-sm"
          loading={isCreatingDoseCondition}
          onClick={onCreateDoseCondition}
          disabled={disableSubmit()}
        />
      </div>
    </Dialog>
  )
}

export default CreateDoseConditionDialog
