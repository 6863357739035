import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { useMemo } from 'react'
import { map, sortBy } from 'lodash'
import { formatSchedules } from '@services/utils/schedule'
import { displayErrorMessages } from '@services/errorHandler'

const useHrstDoseSigsQuery = ({ patientId, statusMessage }) => {
  const query = useQuery({
    queryKey: ['hrstDoseSigs', patientId],
    queryFn: () => ApiClient.get('/hrst/dose_sigs', {
      params: {
        patient_id: patientId,
      },
    }),
    enabled: !!patientId,
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })

  return useMemo(() => ({
    ...query,
    data: sortBy(map(query?.data, (doseSig) => (
      {
        ...doseSig,
        schedules: formatSchedules(doseSig.schedules),
      }
    )), 'dose.medicine.name'),
  }), [query.data])
}

export default useHrstDoseSigsQuery
