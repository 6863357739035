import React from 'react'
import '@components/Theme'
import { iTherapyDocsRedirectUrl } from '@utils/url'
import iTherapyDocsSvg from './iTherapyDocs.svg'

function OpenITherapyDocsButton() {
  const redirect = () => window.open(iTherapyDocsRedirectUrl(), '_blank')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      redirect()
    }
  }

  return (
    <div
      onClick={redirect}
      onKeyDown={handleKeyDown}
      role="button"
      style={{
        margin: '1rem', position: 'absolute', top: 5, right: 240, zIndex: 1000, cursor: 'pointer',
      }}
      tabIndex={0}
    >
      <img
        alt="Open iTD"
        src={iTherapyDocsSvg}
        style={{ height: '20px' }}
      />
    </div>
  )
}

export default OpenITherapyDocsButton
