import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import pluralize from 'pluralize'

function InlineTimeInput({
  value,
  onChange,
  onSubmit,
  disabled,
  min,
  max,
  step,
  leftLabel,
  rightLabel,
}) {
  const [editing, setEditing] = useState(false)
  const formattedTime = (time) => {
    const hours = Math.floor(time / 60)
    const minutes = time % 60
    let timeString = ''
    if (hours > 0) {
      timeString += `${hours.toString()} ${pluralize('hour', hours)}`
    }
    if (minutes > 0) {
      timeString += ` ${minutes.toString()} ${pluralize('minute', minutes)}`
    }
    return timeString
  }
  return (
    <div className="flex flex-row gap-2 align-items-center">
      <div className="flex flex-row align-items-center">
        <span className="text-sm font-normal mr-1">
          {leftLabel}
        </span>
        <span className={classNames('text-sm font-bold text-center', { 'w-9rem': editing })}>
          {formattedTime(value, editing)}
        </span>
        <span className="text-sm font-normal ml-1">{rightLabel}</span>
        {
          editing && (
            <span className="flex flex-column p-inputnumber-button-group ml-2">
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  if (value >= max) return
                  onChange(value + step)
                }}
                icon="pi pi-chevron-up"
                disabled={disabled}
                size="small"
                className="p-inputnumber-button p-inputnumber-button-up p-button p-button-icon-only p-component p-0 mb-1"
              />
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  if (value <= min) return
                  onChange(value - step)
                }}
                icon="pi pi-chevron-down"
                disabled={disabled}
                size="small"
                className="p-inputnumber-button p-inputnumber-button-down p-button p-button-icon-only p-componen p-0"
              />
            </span>
          )
        }
      </div>
      <Button
        onClick={(e) => {
          e.preventDefault()
          if (editing) {
            if (onSubmit) onSubmit()
            setEditing(false)
          } else {
            setEditing(true)
          }
        }}
        icon={editing ? 'pi pi-check' : 'pi pi-pencil'}
        className="p-button-text"
      />
    </div>
  )
}

export default InlineTimeInput
