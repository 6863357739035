import React from 'react'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'

function ExpirationDate({ expirationDate, setExpirationDate, handleConfirm }) {
  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-expiration-date">
          When does this medication expire?
        </label>
        <Calendar
          inputId="dose-expiration-date"
          data-testid="expiration-date"
          value={expirationDate}
          showIcon
          minDate={new Date()}
          onChange={(e) => setExpirationDate(e.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!expirationDate}
        />
      </div>
    </div>
  )
}

export default ExpirationDate
