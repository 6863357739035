import React, { useRef, useState } from 'react'
import moment from 'moment-timezone'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import AttributeCard from '@components/display/AttributeCard'
import {
  DoseAttributeRow,
  DateEditor,
  TextAreaEditor,
  EditButton,
} from '@components/DoseAttributeRow'
import { useUpdateScheduledNote } from '@hooks/scheduledNotes'
import { momentFormats, momentToLocalWithoutChangingTime, momentTzWithoutChangingTime } from '@services/utils/moment'

function ScheduledNoteCard({ scheduledNote, editable, timezone }) {
  const statusMessage = useRef(null)
  const [editAttribute, setEditAttribute] = useState(null)
  const {
    mutateAsync: updateScheduledNote,
    isLoading,
  } = useUpdateScheduledNote({ statusMessage })

  if (!scheduledNote) {
    return null
  }

  const handleUpdateAttribute = async (attribute, value) => {
    const payload = { id: scheduledNote.id }
    payload[attribute] = value

    await updateScheduledNote(payload, {
      onSuccess: () => {
        setEditAttribute(null)
      },
    })
  }

  const scheduledNoteAttributes = [
    {
      label: 'Note',
      currentValue: scheduledNote.note,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'note' ? setEditAttribute(null) : setEditAttribute('note'))}
          isEditing={editAttribute === 'note'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'note'}
        />
      ),
      editor: (
        <TextAreaEditor
          text={scheduledNote.note}
          setText={(value) => handleUpdateAttribute('note', value)}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'note',
    },
    {
      label: 'Start Date',
      currentValue: scheduledNote.startAt && timezone
        ? moment(scheduledNote.startAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'startAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={scheduledNote.startAt
            ? momentToLocalWithoutChangingTime(moment(scheduledNote.startAt), timezone) : null}
          setMomentDate={(value) => {
            const startToSet = momentTzWithoutChangingTime(value.startOf('day'), timezone)
            return handleUpdateAttribute('startAt', startToSet.toISOString())
          }}
          max={scheduledNote.endAt ? moment(scheduledNote.endAt) : null}
          isLoading={isLoading}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      currentValue: scheduledNote.endAt && timezone
        ? moment(scheduledNote.endAt).tz(timezone).format(momentFormats.dateYear) : null,
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
          loading={isLoading}
          disabled={editAttribute && editAttribute !== 'endAt'}
        />
      ),
      editor: (
        <DateEditor
          momentDate={scheduledNote.endAt
            ? momentToLocalWithoutChangingTime(moment(scheduledNote.endAt), timezone) : null}
          min={scheduledNote.startAt ? moment(scheduledNote.startAt) : null}
          setMomentDate={(value) => {
            if (!value) {
              return handleUpdateAttribute('endAt', null)
            }
            const newEnd = momentTzWithoutChangingTime(value.endOf('day'), timezone)
            if (newEnd.startOf('day').isBefore(moment().startOf('day'))) {
              confirmDialog({
                message: 'The end date is before today, are you sure you want to continue?',
                header: 'Warning',
                accept: () => handleUpdateAttribute('endAt', newEnd.toISOString()),
                icon: 'pi pi-exclamation-triangle',
              })
            } else {
              return handleUpdateAttribute('endAt', newEnd.toISOString())
            }
          }}
          isLoading={isLoading}
          showButtonBar
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
  ]

  return (
    <div className="col-12">
      <Toast ref={statusMessage} />
      <ConfirmDialog />
      <AttributeCard
        title={{ label: 'Med Pass Reminder' }}
      >
        <ul className="list-none p-0 m-0 dose-order-fields">
          {scheduledNoteAttributes
            .map(({
              label, currentValue, newValue, action, valueChangeHandler, editor, isEditing,
            }, index) => (
              <DoseAttributeRow
                key={label}
                label={label}
                valueChangeHandler={valueChangeHandler}
                currentValue={currentValue}
                newValue={newValue}
                action={action}
                editable={editable}
                editor={editor}
                isEditing={isEditing}
                backgroundHighlight={index % 2 === 0}
              />
            ))}
        </ul>
      </AttributeCard>
    </div>
  )
}

export default ScheduledNoteCard
