import React from 'react'
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { SelectButton } from 'primereact/selectbutton'
import DatePickerWithLabel from '@components/display/DatePickerWithLabel'
import { friendlyName } from '@components/treatments/utils'

const optionValue = (value) => ({ name: friendlyName(value), value })

export function TreatmentForm({ treatment = {}, setTreatment }) {
  return (
    <div className="flex flex-column">
      <div className="field">
        <label htmlFor="treatmentType" className="block text-center">Is this treatment given on an as needed basis?</label>
        <SelectButton
          id="treatmentType"
          className="flex justify-content-center w-full"
          allowEmpty={false}
          value={treatment.treatmentType || 'prn'}
          onChange={({ target: { value } }) => setTreatment({ treatmentType: value })}
          optionLabel="name"
          optionValue="value"
          options={[
            { name: 'Yes', value: 'prn' },
            { name: 'No', value: 'routine' },
          ]}
          pt={{
            button: { className: 'flex-grow-1' },
          }}
        />
      </div>
      <div className="field">
        <label htmlFor="category" className="block">Category</label>
        <Dropdown
          className="flex justify-content-center w-full"
          label="Category"
          value={treatment.category || ''}
          inputId="category"
          name="category"
          filter
          filterBy="name"
          onChange={({ target: { value } }) => setTreatment({ category: value })}
          optionLabel="name"
          optionValue="value"
          options={[
            optionValue('ADAPTIVE'),
            optionValue('ADMIT'),
            optionValue('CBG'),
            optionValue('DIETARY'),
            optionValue('INFO'),
            optionValue('LABS'),
            optionValue('LITERALORDER'),
            optionValue('MONITORING'),
            optionValue('PRN'),
            optionValue('RTN'),
            optionValue('TREAT'),
            optionValue('VITALS'),
          ]}
        />
      </div>
      <div className="field">
        <label htmlFor="instructions" className="block">Instructions</label>
        <InputTextarea
          id="instructions"
          value={treatment.instructions || ''}
          onChange={({ target: { value } }) => setTreatment({ instructions: value })}
          rows={3}
          cols={30}
        />
      </div>
      <div className="flex flex-row justify-content-between">
        <DatePickerWithLabel
          label="Start Date"
          value={treatment.startAt ? moment(treatment.startAt).toDate() : null}
          inputId="startAt"
          name="startAt"
          onChange={({ target: { value } }) => setTreatment({ startAt: value })}
          minDate={moment().subtract(5, 'years').toDate()}
          maxDate={treatment.endAt ? moment(treatment.endAt).toDate() : moment().add(3, 'months').toDate()}
        />
        <DatePickerWithLabel
          label="End Date"
          value={treatment.endAt ? moment(treatment.endAt).toDate() : null}
          inputId="endAt"
          name="endAt"
          onChange={({ target: { value } }) => setTreatment({ endAt: value })}
          minDate={treatment.endAt ? moment(treatment.endAt).toDate() : moment().toDate()}
          maxDate={moment().add(5, 'years').toDate()}
        />
      </div>
      <div className="field">
        <label htmlFor="note" className="block">Note</label>
        <InputTextarea
          id="note"
          value={treatment.note || ''}
          onChange={({ target: { value } }) => setTreatment({ note: value })}
          rows={3}
          cols={30}
        />
      </div>
    </div>
  )
}

export default TreatmentForm
