import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useArchiveMedBoxMutation(statusMessage, medBoxId) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['medbox'],
    mutationFn: () => ApiClient.post(`/simpills/${medBoxId}/archive`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['medBox', medBoxId])
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'MedBox archived successfully',
        },
      ])
    },
  })
}

export default useArchiveMedBoxMutation
