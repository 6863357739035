import React from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

function IgnoreChangesDialog({
  onConfirm, onHide, visible,
}) {
  return (
    <Dialog
      header="Ignore Changes"
      onHide={onHide}
      visible={visible}
    >
      <div className="flex flex-column gap-4">
        <div>
          The pharmacy order will be ignored and no changes will be made to the treatment or task.
        </div>
        <div className="flex flex-row justify-content-end">
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default IgnoreChangesDialog
