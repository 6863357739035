import React, { useState, useEffect } from 'react'
import pluralize from 'pluralize'
import { get, groupBy } from 'lodash'
import DashboardCard from './DashboardCard'

function DashboardStats({
  organization,
  activeView,
  setView,
  highRiskConsumptions,
  onTimeConsumptions,
  highRiskImpacts,
  permissionRequests,
  views,
}) {
  const [activeStat, setActiveStat] = useState(activeView)

  const highRiskPatientsCount = Object.keys(groupBy(highRiskConsumptions.data, 'patient.id'))?.length
  const onTimePatientsCount = Object.keys(groupBy(onTimeConsumptions.data, 'patient.id'))?.length
  const highRiskImpactsCount = Object.keys(groupBy(highRiskImpacts.data, 'patient.id'))?.length
  const permissionRequestsCaregiversCount = Object.keys(groupBy(permissionRequests.data, 'user.id'))?.length

  useEffect(() => {
    if (activeStat !== activeView) {
      setView(activeStat)
    }
  }, [activeStat])

  return (
    <div className="flex flex-row">
      <DashboardCard
        title="At Risk"
        isActive={activeStat === views.highRisk}
        onClick={() => setActiveStat(views.highRisk)}
        loading={highRiskConsumptions.isLoading}
        icon="pi-exclamation-circle"
        color="red"
        stat={`${get(highRiskConsumptions.data, 'length')} ${pluralize('Consumptions', get(highRiskConsumptions.data, 'length'))}`}
        description={`Missed for ${highRiskPatientsCount} ${pluralize(organization.patientLabelSingular, highRiskPatientsCount)}`}
      />
      <DashboardCard
        title="On Time"
        isActive={activeStat === views.onTime}
        onClick={() => setActiveStat(views.onTime)}
        loading={onTimeConsumptions.isLoading}
        icon="pi-check-square"
        color="green"
        stat={`${get(onTimeConsumptions.data, 'length')} ${pluralize('Consumptions', get(onTimeConsumptions.data, 'length'))}`}
        description={`Administered to ${onTimePatientsCount} ${pluralize(organization.patientLabelSingular, onTimePatientsCount)}`}
      />
      <DashboardCard
        title="PRN Impacts"
        isActive={activeStat === views.negativeImpacts}
        onClick={() => setActiveStat(views.negativeImpacts)}
        loading={highRiskImpacts.isLoading}
        icon="pi-minus-circle"
        color="orange"
        stat={`${get(highRiskImpacts.data, 'length')} ${pluralize('Impact Check', get(highRiskImpacts.data, 'length'))}`}
        description={`Captured for ${highRiskImpactsCount} ${pluralize(organization.patientLabelSingular, highRiskImpactsCount)}`}
      />
      <DashboardCard
        title="Permission Requests"
        isActive={activeStat === views.permissionRequests}
        onClick={() => setActiveStat(views.permissionRequests)}
        loading={permissionRequests.isLoading}
        icon="pi-bell"
        color="yellow"
        stat={`${get(permissionRequests.data, 'length')} ${pluralize('Requests', get(permissionRequests.data, 'length'))}`}
        description={`Sent from ${permissionRequestsCaregiversCount} ${pluralize(organization.caregiverLabelSingular, permissionRequestsCaregiversCount)}`}
      />
    </div>
  )
}

export default DashboardStats
