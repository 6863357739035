import React, { useState } from 'react'
import {
  TextAreaEditor,
  EditButton,
  AdministrationRouteEditor,
  DropdownEditor,
} from '@components/DoseAttributeRow'
import { csaScheduleText } from '@components/medicines/csaScheduleLabel'
import { useCurrentUser } from '@components/App'
import { Button } from 'primereact/button'
import { administrationRoutes } from '../../../../clientDoses/config'
import {
  useReviewableOrderState,
  useReviewableOrderHandlers,
} from '../../../../../features/ClientDoses/PharmacyOrders/reviewableOrderState/ReviewableOrderContext'

const Attributes = ({ compositeOrder, pharmScript, combine }) => {
  const [editAttribute, setEditAttribute] = useState(null)
  const {
    note,
    administrationRoute,
    medicine,
    prescription,
    purposeCode,
    purposeText,
  } = useReviewableOrderState()

  const {
    handleUpdateMedicine,
    handleUpdateAdministrationRoute,
    handleUpdateNote,
    handleUpdatePurposeText,
  } = useReviewableOrderHandlers()

  const isAdmin = ['superadmin'].includes(useCurrentUser().role)

  const pharmacyLinkAction = () => {
    if (isAdmin) {
      return (
        <Button label={pharmScript?.pharmacy?.name} icon="pi pi-link" link onClick={() => window.open(`/admin/pharmacies/${pharmScript?.pharmacyId}`)} />
      )
    }
  }
  function prescriberInfoText() {
    return (
      <div>
        {pharmScript?.prescriberFullName}
        {'    '}
        <font className="text-xs font-light vertical-align-bottom link font">
          (
          {pharmScript?.prescriberIdNumber}
          )
        </font>
      </div>
    )
  }
  return ([
    {
      label: 'Controlled Substance',
      order: 0,
      currentValue: csaScheduleText(medicine.csaSchedule),
      newValue: csaScheduleText(compositeOrder?.medicine.csaSchedule),
      valueChangeHandler: () => (
        handleUpdateMedicine({
          csaSchedule: compositeOrder?.medicine.csaSchedule,
        })
      ),
      display: Boolean(compositeOrder?.medicine.csaSchedule),
      isEditing: editAttribute === 'csaSchedule',
      action: <EditButton
        onClick={editAttribute === 'csaSchedule' ? () => setEditAttribute(null) : () => setEditAttribute('csaSchedule')}
        isEditing={editAttribute === 'csaSchedule'}
        disabled={editAttribute && editAttribute !== 'csaSchedule'}
      />,
      editor: <DropdownEditor
        option={medicine.csaSchedule}
        options={[
          { label: 'Schedule I', value: 1 },
          { label: 'Schedule II', value: 2 },
          { label: 'Schedule III', value: 3 },
          { label: 'Schedule IV', value: 4 },
          { label: 'Schedule V', value: 5 },
        ]}
        setOption={(value) => {
          handleUpdateMedicine({ csaSchedule: value })
          setEditAttribute(null)
        }}
      />,
    },
    {
      label: 'Medicine',
      order: 0,
      currentValue: compositeOrder?.medicine.orderText,
    },
    {
      label: 'Prescription Number',
      order: 1,
      currentValue: prescription?.prescriptionNumber,
    },
    {
      label: 'Notes',
      order: 2,
      currentValue: note,
      newValue: compositeOrder?.note,
      valueChangeHandler: () => (
        handleUpdateNote({
          note: compositeOrder?.note,
        })
      ),
      editor: (
        <TextAreaEditor
          text={note || ''}
          setText={(value) => {
            handleUpdateNote({ note: value })
            setEditAttribute(null)
          }}
        />
      ),
      isEditing: editAttribute === 'note',
      action: (
        <EditButton
          onClick={() => (editAttribute === 'note'
            ? setEditAttribute(null)
            : setEditAttribute('note'))}
          isEditing={editAttribute === 'note'}
        />
      ),
    },
    {
      label: 'Administration Route',
      order: 3,
      newValue: administrationRoutes[compositeOrder?.administrationRoute]?.name,
      currentValue: administrationRoutes[administrationRoute]?.name,
      valueChangeHandler: () => (
        handleUpdateAdministrationRoute({
          administrationRoute: compositeOrder?.administrationRoute,
        })
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'administrationRoute' ? setEditAttribute(null) : setEditAttribute('administrationRoute'))}
          isEditing={editAttribute === 'administrationRoute'}
        />
      ),
      editor: (
        <AdministrationRouteEditor
          administrationRoute={administrationRoute}
          setAdministrationRoute={(value) => {
            handleUpdateAdministrationRoute({ administrationRoute: value })
            setEditAttribute(null)
          }}
        />
      ),
      isEditing: editAttribute === 'administrationRoute',
    },
    {
      label: 'Purpose Code',
      order: 4,
      currentValue: purposeCode,
    },
    {
      label: 'Purpose Text',
      order: 5,
      currentValue: purposeText,
      ...purposeCode && {
        newValue: compositeOrder?.purposeText,
        valueChangeHandler: () => (
          handleUpdatePurposeText({
            purposeText: compositeOrder?.purposeText,
          })
        ),
        editor: (
          <TextAreaEditor
            text={purposeText || ''}
            setText={(value) => {
              handleUpdatePurposeText({ purposeText: value })
              setEditAttribute(null)
            }}
          />
        ),
        isEditing: editAttribute === 'purposeText',
        action: !purposeText && (
        <EditButton
          onClick={() => (editAttribute === 'purposeText' ? setEditAttribute(null) : setEditAttribute('purposeText'))}
          isEditing={editAttribute === 'purposeText'}
        />
        ),
      },
    },
    ...(combine && [
      {
        label: 'Prescriber Info',
        order: 6,
        currentValue: prescriberInfoText(),
        action: pharmacyLinkAction(),
      },
    ]) || [
      {
        label: 'Prescriber Name',
        order: 6,
        currentValue: pharmScript?.prescriberFullName,
        action: null,
      },
      {
        label: 'Prescriber NPI',
        order: 7,
        currentValue: pharmScript?.prescriberIdNumber,
        action: null,
      },
      {
        label: 'Pharmacy',
        order: 8,
        currentValue: pharmScript?.pharmacy?.name,
        action: pharmacyLinkAction(),
      },
    ],
  ])
}
export default Attributes
