import React, { useState, useRef } from 'react'
import { get } from 'lodash'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Messages } from 'primereact/messages'
import { InputNumber } from 'primereact/inputnumber'
import { useDoseRefill } from './doseRefillHooks'
import { dateTemplate } from '../../../services/utils'

export const FulfillmentStatus = { pending: 'pending', loadedByAgency: 'loaded_by_agency' }

function DoseRefill({
  slot, doseFill, drawerId, onSuccess, newFulfillmentStatus,
}) {
  const statusMessage = useRef(null)
  const {
    mutateAsync: refillDoseFill,
    isLoading,
  } = useDoseRefill(doseFill.dose.id, statusMessage)
  const [expirationDate, setExpirationDate] = useState(new Date(get(doseFill, 'expiresAt')))
  const [dosesInFill, setDosesInFill] = useState(get(doseFill, 'dosesInFill'))

  const handleRefill = async () => {
    const agencyLoadedAt = (
      newFulfillmentStatus === FulfillmentStatus.loadedByAgency ? new Date() : null
    )
    await refillDoseFill({
      doseFill: {
        dosesInFill,
        agencyLoadedAt,
        id: doseFill.id,
        expiresAt: expirationDate,
        simpillDrawerId: drawerId,
        drawerSlotId: slot?.id,
      },
    }, {
      onSuccess: () => {
        onSuccess()
      },
    })
  }

  const actionLabel = () => {
    if (newFulfillmentStatus === FulfillmentStatus.pending) {
      return 'Prepare a Refill'
    } if (doseFill.fulfillmentStatus === FulfillmentStatus.pending) {
      return 'Confirm Fulfillment'
    }
    return 'Confirm Refill'
  }

  return (
    <>
      <Messages ref={statusMessage} />
      <div className="flex flex-column gap-2 w-20rem">
        <label className="text-lg font-normal mb-1" htmlFor="dosesInFill">Doses in Fill</label>
        <InputNumber
          inputId="dosesInFill"
          inputClassName="py-4"
          value={dosesInFill}
          onValueChange={(e) => setDosesInFill(e.value)}
        />
        <label className="text-lg font-normal mb-1" htmlFor="expirationDate">Expiration Date</label>
        <Calendar
          id="expirationDate"
          value={expirationDate}
          showIcon
          minDate={new Date()}
          onChange={(e) => setExpirationDate(e.value)}
        />
        <Divider />
        { expirationDate && dosesInFill
          && (
          <>
            <div className="flex flex-column gap-4">
              <div className="flex flex-column gap-2">
                <h5 className="text-base font-bold mb-2">Medication</h5>
                <span className="text-sm">
                  Name:
                  {get(doseFill, 'medicine.name')}
                </span>
                <span className="text-sm">
                  Strength:
                  {get(doseFill, 'medicine.strength')}
                </span>
                <span className="text-sm">
                  Doses in fill:
                  {dosesInFill}
                </span>
                <span className="text-sm">
                  Expiration Date:
                  {dateTemplate(expirationDate)}
                </span>
              </div>
            </div>
            <Button
              className="mt-5"
              label={actionLabel()}
              onClick={handleRefill}
              loading={isLoading}
            />
          </>
          )}
      </div>
    </>
  )
}

export default DoseRefill
