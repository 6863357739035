import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

const useCreateTreatment = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params) => {
      const treatment = {
        patientId: params.patientId,
        instructions: params.instructions,
        startAt: params.startAt,
        endAt: params.endAt,
        rawAdministrationTimes: params.rawAdministrationTimes,
        note: params.note,
        treatmentType: params.treatmentType,
        category: params.category,
        showInMedpass: params.showInMedpass,
      }

      return ApiClient.post('/treatments', treatment)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('treatments')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Treatment Created!' },
      ])
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useCreateTreatment
