import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

function useDeleteMedBoxMutation(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['medbox'],
    mutationFn: (medBoxId) => ApiClient.delete(`/simpills/${medBoxId}`),
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['medbox'])
    },
  })
}

export default useDeleteMedBoxMutation
