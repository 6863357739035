import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

const getUrl = (model, id, prescriptionNumber) => {
  if (!id && model !== 'Prescription') {
    return null
  }

  if (!prescriptionNumber && model === 'Prescription') {
    return null
  }

  switch (model) {
    case 'Dose':
      return `/doses/${id}/prescriptions`
    case 'Patient':
      return `/prescriptions?patient_id=${id}`
    case 'PendingUser':
      return `/prescriptions?pending_user_id=${id}`
    case 'Pharmacy':
      return `/prescriptions?pharmacy_id=${id}`
    case 'Prescription':
      return `/prescriptions?prescription_number=${prescriptionNumber}`
    default:
      return null
  }
}

export const usePrescriptions = ({ model, id, prescriptionNumber }) => {
  const url = getUrl(model, id, prescriptionNumber)

  const query = useQuery({
    queryKey: ['prescriptions', url],
    queryFn: () => ApiClient.get(url),
    enabled: url !== null,
  })

  return useMemo(() => ({
    ...query,
    data: query.data?.prescriptions || [],
  }), [query])
}

export default usePrescriptions
