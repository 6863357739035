import moment from 'moment'
import { getCssVariable } from '@services/utils'

function getImpactCheckStatusColor({ completedAt, effective }) {
  if (completedAt && effective) {
    return getCssVariable('--text-color')
  }

  return getCssVariable('--circle-pending')
}

const getImpactCheckStatusClass = ({ completedAt, effective }) => {
  if (completedAt && effective) {
    return 'font-bold'
  }

  return 'color-ineffective-missed'
}

const insertImpactCheckIntoHistory = ({ administrationEvents, impactChecks }) => {
  if (!administrationEvents) return []
  const impactCheck = impactChecks.at(-1)
  if (!impactCheck || impactCheck.status === 'pending') return administrationEvents

  const historyImpactCheck = { ...impactCheck, isImpactCheck: true }
  const dateToCompare = impactCheck.completedAt ? moment(impactCheck.completedAt)
    : moment(impactCheck.expectedAt)

  let insertionIndex = administrationEvents.findIndex((el) => dateToCompare.isAfter(el.recordedAt))
  if (insertionIndex === -1) insertionIndex = administrationEvents.length

  return [
    ...administrationEvents.slice(0, insertionIndex),
    historyImpactCheck,
    ...administrationEvents.slice(insertionIndex),
  ]
}

export {
  getImpactCheckStatusColor,
  getImpactCheckStatusClass,
  insertImpactCheckIntoHistory,
}
