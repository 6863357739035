import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

// eslint-disable-next-line import/prefer-default-export
export function useSubmitMedicationsMutation(statusMessage, patientId) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => ApiClient.post('/hrst/medication_submissions', {
      patientId,
    }),
    onSuccess: async (response) => {
      queryClient.invalidateQueries('patient')
      await queryClient.invalidateQueries('hrstDoseSigs')
      statusMessage.current.clear()
      const submittedMedicationLabel = response.submittedSigsCount === 1 ? 'medication' : 'medications'
      const skippedMedicationLabel = response.skippedSigsCount === 1 ? 'medication' : 'medications'
      const message = response.skippedSigsCount > 0
        ? `${response.submittedSigsCount} ${submittedMedicationLabel} submitted, ${response.skippedSigsCount} ${skippedMedicationLabel} skipped`
        : `${response.submittedSigsCount} ${submittedMedicationLabel} submitted`
      statusMessage.current.show({
        summary: message,
        severity: 'success',
      })
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}
