import React, { useState } from 'react'
import { get, update } from 'lodash'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { conditionTypes } from '@components/conditions/config'

function ConditionCheckInput({
  condition,
  conditionCheck,
  setConditionCheck,
  createConditionCheckInput,
  deleteMeasurement,
  measurementDeleteDialog,
}) {
  const [isDeletingMeasurement, setIsDeletingMeasurement] = useState(false)
  const conditionType = conditionTypes.find((ct) => ct.code === condition?.conditionType)

  if (!conditionType) {
    return null
  }

  const handleChange = (path, value) => {
    const newConditionCheck = { ...conditionCheck }
    update(newConditionCheck, path, () => value)
    setConditionCheck(newConditionCheck)
  }

  const handleDelete = async () => {
    setIsDeletingMeasurement(true)
    await deleteMeasurement({
      measurementId: conditionCheck.measurementId,
      type: conditionType.code,
      checkId: conditionCheck.id,
    }, {
      onSuccess: () => {
        const newConditionCheck = createConditionCheckInput(condition)
        setConditionCheck(newConditionCheck)
      },
    })
    setIsDeletingMeasurement(false)
  }

  const unitPath = `${conditionType.measurementKey}.unit`

  return (
    <div className="p-inputgroup flex-shrink-1">
      <span className="p-inputgroup-addon text-sm" style={{ minWidth: '125px' }}>
        {conditionType.displayText}
      </span>
      <InputText
        keyfilter="num"
        onChange={(e) => handleChange(conditionType.values[0], e.target.value)}
        value={isDeletingMeasurement ? 'Deleting...' : get(conditionCheck, conditionType.values[0], '')}
        className="text-center text-sm"
        disabled={isDeletingMeasurement}
      />
      {
        (!isDeletingMeasurement && conditionType.values.length > 1) && (
          <>
            <span className="p-inputgroup-addon text-sm" style={{ minWidth: '15px' }}>
              /
            </span>
            <InputText
              keyfilter="num"
              onChange={(e) => handleChange(conditionType.values[1], e.target.value)}
              value={get(conditionCheck, conditionType.values[1], '')}
              className="text-center text-sm"
            />
          </>
        )
      }
      {
        (!isDeletingMeasurement && conditionType.units.length === 1) && (
          <span
            className="p-inputgroup-addon text-sm"
            style={{
              minWidth: '125px', borderRadius: '0 5px 5px 0', borderRight: 'solid thin #CFD8DC',
            }}
          >
            {conditionType.units[0]}
          </span>
        )
      }
      {
        (!isDeletingMeasurement && conditionType.units.length > 1) && (
          <Dropdown
            id={`condition-check-unit-dropdown-${conditionType.measurementType}`}
            value={get(conditionCheck, unitPath, conditionType.units[0])}
            options={conditionType.units.map(
              (unit, index) => ({ label: conditionType.unitLabels[index], value: unit }),
            )}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => handleChange(unitPath, e.value)}
            className="text-right text-sm bg-gray-100"
            style={{
              width: '125px', maxWidth: '125px', flexShrink: 0, borderRadius: '0 5px 5px 0',
            }}
          />
        )
      }
      <Button
        icon="pi pi-trash"
        text
        severity="danger"
        aria-label="Cancel"
        onClick={() => measurementDeleteDialog(conditionType.displayText, handleDelete)}
        disabled={!conditionCheck.measurementId}
      />
    </div>
  )
}

export default ConditionCheckInput
