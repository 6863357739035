import React from 'react'
import { map, sortBy } from 'lodash'
import { DoseAttributeRow } from '@components/DoseAttributeRow'
import '../../style.scss'
import { Attributes } from './doseAttributes'

function DoseFieldGroup({
  compositeOrder = {}, prescription,
}) {
  const doseAttributes = Attributes({ compositeOrder, combine: true, pharmScript: prescription })
  const sortedAttributes = sortBy(doseAttributes.filter(({ display = true }) => display), 'order')

  return (
    <ul className="list-none p-0 m-0 dose-order-fields">
      {map(sortedAttributes, ({
        label, currentValue, newValue, action,
        valueChangeHandler, editor, isEditing,
      }, index) => (
        <DoseAttributeRow
          key={label}
          label={label}
          valueChangeHandler={valueChangeHandler}
          currentValue={currentValue}
          newValue={newValue}
          action={action}
          editor={editor}
          isEditing={isEditing}
          backgroundHighlight={index % 2 === 0}
        />
      ))}
    </ul>
  )
}

export default DoseFieldGroup
