import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { get } from 'lodash'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import {
  DetailsForm,
  SiteForm,
  PatientsSelectForm,
  MedboxDrawers,
} from '@components/medboxes/MedBoxForm'
import { useCurrentOrganization } from '@components/App'
import FormWrapper from '@components/display/Form/FormWrapper'
import { useMedBoxQuery, useUpdateMedBoxMutation } from '@hooks/medBoxes'
import { useSites } from '@hooks/sites'
import { usePatientsBySite } from '../Patients/patientHooks'
import {
  MED_BOXES_PATH,
  LABELS,
  MED_BOX_TYPE,
  E_KITS_PATH,
  E_KIT_TYPE,
} from './config'

function EditMedBox({ useMedBoxesHeader, type, isSuperAdmin }) {
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()
  const { medBoxId } = useParams()
  const navigate = useNavigate()
  const { setHeader } = useMedBoxesHeader()

  const [details, setDetails] = useState({
    label: '',
    mac: '',
  })

  const [selectedSiteId, setSelectedSiteId] = useState(null)
  const [selectedPatientIds, setSelectedPatientIds] = useState(null)
  const {
    mutateAsync: updateMedBox,
    isLoading: updateIsLoading,
  } = useUpdateMedBoxMutation(medBoxId, statusMessage)

  const { data: medbox, isLoading: medboxIsLoading } = useMedBoxQuery(medBoxId)
  const { data: sites, isLoading: isSitesLoading } = useSites(organization.id, statusMessage)
  const { data: patients, isLoading: isPatientsLoading } = usePatientsBySite(selectedSiteId)

  const basePath = type === MED_BOX_TYPE ? MED_BOXES_PATH : E_KITS_PATH
  const isEKit = type === E_KIT_TYPE
  const labelText = LABELS[type]

  const handleUpdateMedBox = (e) => {
    e.preventDefault()
    const simpill = {
      ...details,
      siteId: selectedSiteId,
      organizationId: organization.id,
      deviceType: type,
    }
    if (type === MED_BOX_TYPE) {
      simpill.patientIds = selectedPatientIds
    }
    updateMedBox(
      {
        simpill,
      },
      {
        onSuccess: () => navigate(`/${basePath}/${medBoxId}`),
      },
    )
  }

  const handleSiteChange = (site) => {
    setSelectedSiteId(site)
    setSelectedPatientIds([])
  }

  const title = {
    label: (
      <span>
        <i className="text-xl pi pi-box mr-2" />
        {LABELS[type]}
      </span>
    ),
    linkTo: `/${basePath}/${medBoxId}`,
  }

  const breadcrumbs = [
    {
      label: labelText,
      template: <Link to={`/${basePath}/${medBoxId}`}>{labelText}</Link>,
    },
    {
      label: 'Edit',
      template: <span>Edit</span>,
    },
  ]

  useEffect(() => {
    if (medbox) {
      setDetails({
        label: medbox.label,
        mac: medbox.mac,
      })
      setSelectedPatientIds(get(medbox, 'patients', []).map((patient) => patient.id))
      setSelectedSiteId(medbox.siteId)
    }
  }, [medbox])

  useEffect(() => {
    setHeader({
      title, tabs: [], breadcrumbs, primaryAction: null,
    })
  }, [])

  return (
    <div id="edit-med-box" className="flex flex-column">
      <div className="flex flex-row justify-content-start flex-wrap">
        <Toast ref={statusMessage} />
        <div className="col-6">
          <FormWrapper title="Profile and Assignment" fullHeight>
            <DetailsForm
              isSuperAdmin={isSuperAdmin}
              details={details}
              setDetails={(data) => setDetails({ ...details, ...data })}
              isDetailsLoading={medboxIsLoading}
            />
            <SiteForm
              sites={sites}
              selectedSite={selectedSiteId}
              setSelectedSite={handleSiteChange}
              isSitesLoading={isSitesLoading}
            />
            {
              !isEKit && (
                <PatientsSelectForm
                  patients={patients}
                  selectedPatientIds={selectedPatientIds}
                  setSelectedPatientIds={setSelectedPatientIds}
                  isPatientsLoading={isPatientsLoading}
                  siteAlreadySelected={!!selectedSiteId}
                />
              )
            }
            <Button
              label="Save"
              className="p-button-sm m-2"
              loading={updateIsLoading}
              onClick={handleUpdateMedBox}
            />
          </FormWrapper>
        </div>
        {
          !isEKit && (
            <MedboxDrawers
              medBoxId={medBoxId}
              drawers={medbox?.simpillDrawers}
            />
          )
        }
      </div>
    </div>
  )
}

export default EditMedBox
