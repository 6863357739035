import React, { useRef, useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Messages } from 'primereact/messages'
import moment from 'moment-timezone'
import { Tag } from 'primereact/tag'
import { sortBy } from 'lodash'
import { Button } from 'primereact/button'
import ApprovePermissionRequestDialog from '@components/permissionRequests/ApprovePermissionRequestDialog'
import RejectPermissionRequestDialog from '@components/permissionRequests/RejectPermissionRequestDialog'
import SearchField from '@components/display/Form/SearchField'
import { fuzzySearch } from '@services/utils/search'
import CardWithHeader from '../../components/display/CardWithHeader'

function ManageRequestButtons({
  onApprove, onRefuse,
}) {
  return (
    <div className="flex flex-column gap-2">
      <Button
        label="Approve"
        onClick={() => onApprove()}
        className="p-button-sm"
        icon="pi pi-check"
      />
      <Button
        label="Reject"
        onClick={() => onRefuse()}
        className="p-button-sm p-button-outlined"
        icon="pi pi-times"
      />
    </div>
  )
}

function PermissionRequests({ organization, maxDataViewHeight, permissionRequests }) {
  const statusMessage = useRef(null)
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredPermissionRequests, setFilteredPermissionRequests] = useState([])
  const [selectedPermissionRequest, setSelectedPermissionRequest] = useState(null)
  const [approveDialogVisible, setApproveDialogVisible] = useState(false)
  const [refuseDialogVisible, setRefuseDialogVisible] = useState(false)

  useEffect(() => {
    setSearchLoading(true)
    if (searchTerm) {
      setFilteredPermissionRequests(fuzzySearch(permissionRequests.data, ['patient.fullName', 'user.fullName'], searchTerm))
    } else {
      setFilteredPermissionRequests(permissionRequests.data)
    }
    setSearchLoading(false)
  }, [permissionRequests.isFetching, searchTerm])

  const formattedMedicineString = (medicine) => {
    const { name: medicineName, strength } = medicine
    return (
      <div className="flex flex-column gap-1">
        <div>
          {`${medicineName} ${strength}`}
        </div>
      </div>
    )
  }

  const consumptionTemplate = (consumption) => {
    const formattedExpectedAt = moment(consumption.expectedAt).format('MMM D, YYYY h:mm a')
    return (
      <div className="flex flex-column gap-1">
        <span className="text-900 font-medium block">
          Override Missed Status
        </span>
        <div className="mt-1">
          {formattedMedicineString(consumption.medicine)}
        </div>
        <span className="mt-2">
          Expected:
          {formattedExpectedAt}
        </span>
      </div>
    )
  }

  const doseTemplate = (dose) => (
    <div className="flex flex-column gap-1">
      <span className="text-900 font-medium block">
        PRN Administration
      </span>
      <div className="mt-1">
        {formattedMedicineString(dose.medicine)}
      </div>
    </div>
  )

  const resourceTemplate = (permissionRequest) => {
    let resource = null
    if (permissionRequest.resourceType === 'Consumption') {
      resource = consumptionTemplate(permissionRequest.consumption)
    } else {
      resource = doseTemplate(permissionRequest.dose)
    }

    return (
      <div className="flex flex-column gap-1">
        {resource}
        {
          permissionRequest.note && (
            <div className="mt-2">
              <span className="text-900 font-medium block">
                Note
              </span>
              <div className="mt-1">
                {permissionRequest.note}
              </div>
            </div>
          )
        }
      </div>
    )
  }

  const statusTemplate = (permissionRequest) => {
    if (permissionRequest.state === 'pending') {
      return (
        <ManageRequestButtons
          onApprove={() => {
            setSelectedPermissionRequest(permissionRequest)
            setApproveDialogVisible(true)
          }}
          onRefuse={() => {
            setSelectedPermissionRequest(permissionRequest)
            setRefuseDialogVisible(true)
          }}
        />
      )
    }

    let background = 'var(--green-400)'
    if (permissionRequest.state === 'rejected') {
      background = 'var(--bluegray-200)'
    }
    if (permissionRequest.state === 'expired') {
      background = 'var(--orange-400)'
    }

    return (
      <div className="flex flex-column gap-1">
        <span className="text-900 font-medium block">
          <Tag
            style={{ background }}
            value={permissionRequest.state.toUpperCase()}
          />
        </span>
      </div>
    )
  }

  return (
    <CardWithHeader title="Permission Requests">
      <div className="col-12">
        {
          selectedPermissionRequest && (
            <ApprovePermissionRequestDialog
              permissionRequest={selectedPermissionRequest}
              visible={approveDialogVisible}
              onHide={() => setApproveDialogVisible(false)}
              resourceTemplate={resourceTemplate}
            />
          )
        }
        {
          selectedPermissionRequest && (
            <RejectPermissionRequestDialog
              permissionRequest={selectedPermissionRequest}
              visible={refuseDialogVisible}
              onHide={() => setRefuseDialogVisible(false)}
              resourceTemplate={resourceTemplate}
            />
          )
        }
        <Messages ref={statusMessage} />
        <div className="flex flex-column gap-3 text-base text-900">
          <div className="flex flex-row gap-4  pt-2 justify-content-end align-items-center">
            <SearchField
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              placeholder={`Search by ${organization.patientLabelSingular} or Requester`}
              isLoading={searchLoading}
            />
          </div>
          <DataTable
            dataKey="id"
            loading={permissionRequests.isFetching}
            value={sortBy(filteredPermissionRequests, 'createdAt').reverse()}
            rowClassName="fadein patient-row"
            scrollHeight={maxDataViewHeight ? (maxDataViewHeight - 10) : null}
          >
            <Column
              header="Request"
              body={resourceTemplate}
              style={{ width: '40%' }}
            />
            <Column
              field="user.fullName"
              header="Requester"
              style={{ width: '15%' }}
            />
            <Column
              field="patient.fullName"
              header={`${organization.patientLabelSingular}`}
              style={{ width: '15%' }}
            />
            <Column
              header="Requested"
              field="createdAt"
              body={(permissionRequest) => moment(permissionRequest.createdAt).tz(permissionRequest.patient.timezone).format('MMM D, YYYY h:mm a')}
              style={{ width: '20%' }}
            />
            <Column
              header="Status"
              body={statusTemplate}
              style={{ width: '10%' }}
              align="center"
            />
          </DataTable>
        </div>
      </div>
    </CardWithHeader>
  )
}

export default PermissionRequests
