import React, { useState, useEffect, useRef } from 'react'
import { isEmpty, get } from 'lodash'
import { Fieldset } from 'primereact/fieldset'
import { Timeline } from 'primereact/timeline'
import Notes from './Notes'
import PharmacyInfo from './PharmacyInfo'
import WalkInRx from './WalkInRx'

function AdditionalInfoFieldset({
  scheduleStepComplete, isEdit, setActiveField, additionalInfo, handleCompletion,
}) {
  const additionalInfoFieldsetRef = useRef()
  const [activeStep, setActiveStep] = useState()
  const [notes, setNotes] = useState()
  const [pharmacyInfo, setPharmacyInfo] = useState({ name: '', phone: '' })
  const [walkInRx, setWalkInRx] = useState('')

  const steps = [
    { label: 'Notes', value: notes },
    { label: 'Pharmacy Info', value: pharmacyInfo },
    { label: 'Walk-in RX', value: walkInRx },
  ]

  const handleFieldSelection = (index) => {
    setActiveField('additionalInfo')
    setActiveStep(index)
  }

  const fieldMarker = (item, index) => {
    let color = '#dee2e6'
    let icon = 'pi pi-stop'
    if (activeStep === index) {
      color = '#fa8e42'
      icon = 'pi pi-file-edit'
    } else if (item.label === 'Pharmacy Info') {
      if (get(item, 'value.name') || get(item, 'value.phone')) {
        color = '#0bda51'
        icon = 'pi pi-check-square'
      }
    } else if (item.value) {
      color = '#0bda51'
      icon = 'pi pi-check-square'
    }

    return (
      <span onClick={() => handleFieldSelection(index)} className="cursor-pointer p-1" style={{ color }}>
        <i className={icon} />
      </span>
    )
  }

  const fieldContent = (item, index) => {
    const getTextColor = () => (activeStep === index ? 'text-color' : 'text-color-secondary')

    let { value } = item

    if (item.label === 'Pharmacy Info') {
      value = `${get(item, 'value.name') || ''} ${get(item, 'value.phone') || ''}`
    }

    return (
      <div onClick={() => handleFieldSelection(index)} className={`cursor-pointer flex flex-column pt-1 gap-2 ${getTextColor()}`} style={{ wordWrap: 'break-word', maxWidth: '12rem' }}>
        <span className="text-base">{item.label}</span>
        <span className="text-sm">{value}</span>
      </div>
    )
  }

  useEffect(() => {
    if (isEdit) {
      setActiveStep(0)
      additionalInfoFieldsetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    } else {
      setActiveStep(null)
    }
  }, [isEdit])

  useEffect(() => {
    if (!isEmpty(additionalInfo)) {
      setPharmacyInfo(additionalInfo.pharmacyInfo)
      setWalkInRx(additionalInfo.walkInRx)
    }
  }, [additionalInfo])

  const handlePharmacyInfo = (type, value) => {
    setPharmacyInfo({ ...pharmacyInfo, [type]: value })
  }

  const handleLastStep = () => {
    setActiveStep(null)
    handleCompletion({
      notes,
      pharmacyInfo,
      walkInRx,
    })
  }

  return (
    <Fieldset legend="Additional Info">
      <div ref={additionalInfoFieldsetRef} className="flex flew-row">
        {!scheduleStepComplete
          && <div>Please select and save a medicine, dose and schedule first.</div>}
        {scheduleStepComplete
          && (
            <>
              <Timeline
                style={{ minWidth: '16em' }}
                value={steps}
                marker={fieldMarker}
                content={fieldContent}
              />
              {activeStep === 0
                && (
                  <Notes
                    notes={notes}
                    setNotes={setNotes}
                    handleConfirm={() => setActiveStep(activeStep + 1)}
                  />
                )}
              {activeStep === 1
                && (
                  <PharmacyInfo
                    data={pharmacyInfo}
                    handlePharmacyInfo={handlePharmacyInfo}
                    handleConfirm={() => setActiveStep(activeStep + 1)}
                  />
                )}
              {activeStep === 2
                && (
                  <WalkInRx
                    walkInRx={walkInRx}
                    setWalkInRx={setWalkInRx}
                    handleConfirm={handleLastStep}
                  />
                )}
            </>
          )}
      </div>
    </Fieldset>
  )
}

export default AdditionalInfoFieldset
