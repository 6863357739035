import React, { useEffect } from 'react'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import moment from 'moment'
/**
 * @typedef {moment.Moment} Moment
 */

/**
 *
 * @param {Object} props
 * @param {moment} props.startAtMoment A moment object representing the start date
 * and will be showed in the local timezone
 * @param {Function} props.setStartAtMoment
 * @param {Function} props.handleConfirm
 * @returns {JSX.Element}
 */
function StartAtDate({ startAtMoment, setStartAtMoment, handleConfirm }) {
  useEffect(() => {
    if (startAtMoment) {
      setStartAtMoment(startAtMoment)
    }
  }, [])

  return (
    <div className="grid flex flex-grow-1 justify-content-center align-items-center fadein">
      <div className="col-9 flex flex-column gap-2 text-center">
        <label className="text-lg font-normal mb-1" htmlFor="dose-start-date">
          When does this medication regimen start?
        </label>
        <Calendar
          inputId="dose-start-date"
          data-testid="start-at-date"
          value={startAtMoment?.toDate()}
          showIcon
          minDate={moment().subtract(10, 'years').toDate()}
          onChange={(e) => setStartAtMoment(e.value)}
        />
        <Button
          onClick={handleConfirm}
          label="Next"
          className="p-button-text mt-2"
          disabled={!startAtMoment}
        />
      </div>
    </div>
  )
}

export default StartAtDate
