import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useMedBoxQuery(id) {
  return useQuery({
    queryKey: ['medBox', id],
    queryFn: () => ApiClient.get(`/simpills/${id}`),
  })
}

export default useMedBoxQuery
