import React from 'react'
import { Calendar } from 'primereact/calendar'

function DatePickerWithLabel({
  label, value, inputId, onChange, disabled, calendarClassName, minDate, maxDate, selectionMode = 'single',
}) {
  return (
    <div className="field">
      <label className="block" htmlFor={inputId}>{label}</label>
      <Calendar
        onChange={onChange}
        value={value}
        disabled={disabled}
        className={calendarClassName}
        minDate={minDate}
        maxDate={maxDate}
        showButtonBar
        showIcon
        selectionMode={selectionMode}
      />
    </div>
  )
}

export default DatePickerWithLabel
