import React from 'react'
import { Dropdown } from 'primereact/dropdown'

const getOptions = (type, options) => {
  if (type === 'yes-no') {
    return [{ label: 'Yes', value: true }, { label: 'No', value: false }]
  }

  return options
}

function DropdownWithLabel({
  classNameDiv = 'field',
  classNameLabel = 'block',
  classNameDropdown = '',
  disabled = false,
  inputId,
  label,
  onChange,
  options = [],
  optionLabel = 'label',
  optionValue = 'value',
  placeholder,
  type,
  value,
}) {
  return (
    <div className={classNameDiv}>
      <label className={classNameLabel} htmlFor={inputId}>{label}</label>
      <Dropdown
        className={classNameDropdown}
        disabled={disabled}
        id={inputId}
        onChange={onChange}
        options={getOptions(type, options)}
        optionLabel={optionLabel}
        optionValue={optionValue}
        placeholder={placeholder}
        value={value}
      />
    </div>
  )
}

export default DropdownWithLabel
