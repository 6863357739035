import React, { useState } from 'react'
import moment from 'moment'
import { AdherenceForm } from '@components/patients/AdherenceDashboard/Dialog'
import { useUpsertMeasurements } from '@hooks/measurements'
import { useUpsertTaskOccurrence } from '@hooks/taskOccurrences'
import { generateFormattedDateTimeString } from '@services/utils'
import { momentFormats, momentToDatePreservingTime } from '@services/utils/moment'

function TarAdherenceForm({
  onHide,
  taskOccurrence,
  cellTime,
  tarTimeRow,
  mode,
  patient,
  statusMessage,
}) {
  // Use the occurrence's timezone if it exists, otherwise use the patient's current timezone for
  // creating new task occurrences.
  const timezone = taskOccurrence?.timezone || patient.timezone

  const [formData, setFormData] = useState({
    administeredBy: taskOccurrence?.actionedBy || '',
    reason: taskOccurrence?.reason || taskOccurrence?.note || '',
    status: taskOccurrence?.status || '',
    note: taskOccurrence?.note || '',
    time: taskOccurrence
      ? momentToDatePreservingTime(moment(
        taskOccurrence.actionedAt || taskOccurrence.expectedAt,
      ), timezone)
      : momentToDatePreservingTime(cellTime, timezone),
    defaultTime: taskOccurrence && taskOccurrence.expectedAt
      ? momentToDatePreservingTime(moment(taskOccurrence.expectedAt), timezone)
      : momentToDatePreservingTime(cellTime, timezone),
    conditions: tarTimeRow?.conditions || [],
    conditionChecks: (taskOccurrence?.conditionChecks || [])
      .map((cc) => ({ ...cc, condition: cc.taskCondition })),
  })

  const {
    mutateAsync: upsertMeasurements,
  } = useUpsertMeasurements({ mode: 'tar', patientId: patient.id, statusMessage })

  const {
    isLoading,
    mutateAsync: upsertTaskOccurrence,
  } = useUpsertTaskOccurrence({ chained: true, statusMessage })

  if (!cellTime || !tarTimeRow) {
    return null
  }

  const isAddMode = mode === 'add'
  const isPrnMode = isAddMode || tarTimeRow.task.taskType === 'prn'
  const canEditTime = ['completed', 'on_hold', 'refused'].includes(formData.status)
  const showNotes = !['missed', 'refused'].includes(formData.status)
  const showReasonInput = ['missed', 'refused'].includes(formData.status)
  const showReasonDropdown = formData.status === 'on_hold'
  const showStatusDropdown = isAddMode || ['completed', 'missed', 'on_hold', 'refused'].includes(formData.status)
  const showUserDropdown = ['completed', 'on_hold', 'refused'].includes(formData.status)

  const onSubmit = async () => {
    const {
      administeredBy, reason, status, note, time,
    } = formData
    const selectedTime = moment(time).format('HH:mm:ss')
    const responsibleAt = generateFormattedDateTimeString(cellTime, selectedTime)

    const responseBody = await upsertTaskOccurrence({
      task: tarTimeRow.task,
      taskOccurrence,
      status,
      responsibleId: administeredBy.id,
      note,
      reason,
      responsibleAt,
      scheduleId: tarTimeRow.schedule?.id,
      expectedAt: cellTime.format(momentFormats.iso8601_timezone),
    })

    await upsertMeasurements({
      conditionChecks: formData.conditionChecks,
      taskOccurrence: responseBody.taskOccurrence,
    })

    onHide()

    setFormData({
      administeredBy: '',
      reason: '',
      status: '',
      note: '',
      time: '',
      conditions: [],
      conditionChecks: [],
    })
  }

  return (
    <AdherenceForm
      mode={isPrnMode ? 'tar_prn' : 'tar_routine'}
      formData={formData}
      setFormData={setFormData}
      onSubmit={onSubmit}
      onHide={onHide}
      isLoading={isLoading}
      statusMessage={statusMessage}
      patientId={patient.id}
      timezone={timezone}
      cellTime={cellTime}
      canEditTime={canEditTime}
      showConditionChecks={!!taskOccurrence?.id}
      showNotes={showNotes}
      showReasonInput={showReasonInput}
      showReasonDropdown={showReasonDropdown}
      showStatusDropdown={showStatusDropdown}
      showUserDropdown={showUserDropdown}
      submitButtonLabel={isAddMode ? 'Create' : 'Update'}
    />
  )
}

export default TarAdherenceForm
