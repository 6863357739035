import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

function useMedBoxesQuery(organizationId, { includeArchived = false }) {
  return useQuery({
    queryKey: ['medBoxes', organizationId, includeArchived],
    queryFn: () => {
      const params = []
      if (organizationId) {
        params.push(`organization_id=${organizationId}`)
      }
      if (includeArchived) {
        params.push('include_archived=true')
      }
      return ApiClient.get(`/simpills?${params.join('&')}`)
    },
  })
}

export default useMedBoxesQuery
