import React from 'react'
import { Dialog } from 'primereact/dialog'
import Barcode from 'react-barcode'

function BarcodeDialog({ dose, onHide, visible }) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Barcode"
      modal
      className="p-fluid"
    >
      <div className="p-field p-2 flex flex-column gap-1 align-items-center">
        {
          dose.prescriptionNumbers.map((prescriptionNumber) => (
            <div key={prescriptionNumber}>
              <Barcode value={prescriptionNumber} />
            </div>
          ))
        }
      </div>
    </Dialog>
  )
}

export default BarcodeDialog
