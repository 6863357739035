import React, { useRef, useState } from 'react'
import { useCurrentOrganization } from '@components/App'
import DeactivatedUsers from '@components/users/DeactivatedUsers'
import { useDeactivatedAdmins, useReactivateAdmin } from './adminsHooks'
import ReactivateAdminDialog from './ReactivateAdminDialog'

function DeactivatedAdmins({
  searchTerm,
  setSearchLoading,
  maxDataViewHeight,
  rootPath,
  footer,
}) {
  const statusMessage = useRef(null)
  const organization = useCurrentOrganization()

  const [reactivatedAdminId, setReactivatedAdminId] = useState()
  const [reactivateDialogVisible, setReactivateDialogVisible] = useState(false)

  const { data: admins, isLoading } = useDeactivatedAdmins(organization.id, statusMessage)
  const {
    mutateAsync: reactivateAdmin,
    isLoading: isReactivatedAdminLoading,
  } = useReactivateAdmin(statusMessage, organization.adminLabelSingular)

  const reactivateDialog = (handleReactivate) => (
    <ReactivateAdminDialog
      userId={reactivatedAdminId}
      visible={reactivateDialogVisible}
      onHide={() => setReactivateDialogVisible(false)}
      onConfirm={handleReactivate}
      isLoading={isReactivatedAdminLoading}
    />
  )

  return (
    <DeactivatedUsers
      statusMessage={statusMessage}
      users={admins}
      isLoading={isLoading}
      searchTerm={searchTerm}
      setSearchLoading={setSearchLoading}
      maxDataViewHeight={maxDataViewHeight}
      reactivateUser={reactivateAdmin}
      isReactivateUserLoading={isReactivatedAdminLoading}
      reactivatedUserId={reactivatedAdminId}
      setReactivatedUserId={setReactivatedAdminId}
      rootPath={rootPath}
      showReactivateDialog
      reactivateDialog={reactivateDialog}
      setReactivateDialogVisible={setReactivateDialogVisible}
      footer={footer}
    />
  )
}

export default DeactivatedAdmins
