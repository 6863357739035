import React from 'react'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import { momentFormats } from '@services/utils/moment'
import { LoadDoseFillToEkit } from '../LoadToDevice'

function DoseFill({ dose, doseFillToLoad }) {
  return (
    <div className="flex flex-column gap-1 text-base font-normal w-12 align-items-left">
      <span className="text-sm">
        {dose.medicineName}
        {' '}
        {dose.medicineStrength}
        {' '}
      </span>
      {
        doseFillToLoad && (
          <>
            <span className="text-xs">
              Doses in fill:
              {' '}
              {doseFillToLoad.dosesInFill}
            </span>
            <span className="text-xs">
              Expires:
              {' '}
              {moment(doseFillToLoad.expiresAt).format(momentFormats.dateYear)}
            </span>
          </>
        )
      }
    </div>
  )
}

function Header({ dose }) {
  return (
    <div className="flex flex-row gap-1 text-base font-normal align-items-center justify-content-center">
      <span className="text-lg font-bold">
        Refill medication at
        {' '}
        {dose.site?.name}
      </span>
    </div>
  )
}

function LoadRefillDialog({
  dose, doseFillToLoad, doseFills, isVisible, setIsVisible,
}) {
  const header = () => (
    <Header dose={dose} />
  )

  return (
    <Dialog
      header={() => header()}
      headerClassName="px-4 py-2"
      visible={isVisible}
      onHide={() => setIsVisible(false)}
      style={{ width: '40vw' }}
    >
      <div className="flex flex-column gap-2">
        <div className="my-2">
          <DoseFill dose={dose} doseFillToLoad={doseFillToLoad} />
        </div>
        <LoadDoseFillToEkit
          dose={dose}
          doseFillToBeLoaded={doseFillToLoad}
          doseFills={doseFills}
          site={dose?.site}
          onSuccess={() => setIsVisible(false)}
        />
      </div>
    </Dialog>
  )
}

export default LoadRefillDialog
