import React from 'react'
import { InputTextarea } from 'primereact/inputtextarea'

function InputTextareaWithLabel({
  cols = 30,
  disabled = false,
  inputId,
  label,
  onChange,
  rows = 3,
  value,
}) {
  return (
    <div className="field">
      <label className="block" htmlFor={inputId}>{label}</label>
      <InputTextarea
        id={inputId}
        cols={cols}
        rows={rows}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </div>
  )
}

export default InputTextareaWithLabel
