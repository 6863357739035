import React from 'react'
import { Button } from 'primereact/button'

function Breadcrumbs({ goToLocation, gotToLabel, label }) {
  return (
    <div className="flex align-items-center fadein">
      <Button className="p-0 p-button-text p-button-sm text-lg" onClick={goToLocation}>
        {gotToLabel}
      </Button>
      <div className="p-2 inline-flex align-items-center">
        <i className="pi pi-chevron-right mr-2" style={{ fontSize: '0.75em' }} />
        <span className="font-light text-lg">{label}</span>
      </div>
    </div>
  )
}

export default Breadcrumbs
