// Keep in sync with the Injection model
export const injectionSiteOptions = [
  { code: 'LLA', description: 'Left Lower Abdomen', value: 'LLA' },
  { code: 'RUA', description: 'Right Upper Abdomen', value: 'RUA' },
  { code: 'RLA', description: 'Right Lower Abdomen', value: 'RLA' },
  { code: 'LA', description: 'Left Arm', value: 'LA' },
  { code: 'LUA', description: 'Left Upper Abdomen', value: 'LUA' },
  { code: 'RA', description: 'Right Arm', value: 'RA' },
]

export default injectionSiteOptions
