import React, {
  memo, useEffect, useRef, useState,
} from 'react'
import { get, groupBy, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Toast } from 'primereact/toast'
import { useCurrentUser } from '@components/App'
import DateTable from '@components/display/DateTable/DateTable'
import EmptyCell from '@components/patients/AdherenceDashboard/EmptyCell'
import { useTarDashboard } from '@hooks/dashboards'
import { formatOccurrences, sortTarTasks, tarTasksColumns } from './utils'
import StatusOverlay from './StatusOverlay'
import SingleTaskStatusTag from './StatusTag/SingleTaskStatusTag'
import MultipleTasksStatusTag from './StatusTag/MultipleTasksStatusTag'
import TarDialog from './TarDialog'
import AdherenceLOADialog from '../AdherenceLOADialog'

const treatmentTemplate = (rowData) => {
  const text = rowData.treatment
    ? `Instructions: ${rowData.treatment.instructions}`
    : 'N/A'

  return (
    <div className="flex flex-column gap-1 align-items-start text-left">
      {text}
    </div>
  )
}

const taskTemplate = (rowData) => (
  <div className="flex flex-column gap-1 align-items-start text-left">
    <span>{rowData.task.description}</span>
  </div>
)

const TarTasks = memo(({
  dateRange, patient, type,
}) => {
  const statusMessageRef = useRef()
  const statusOverlay = useRef()
  const [selectedOccurrences, setSelectedOccurrences] = useState()
  const [formattedTarDashboard, setFormattedTarDashboard] = useState()
  const [dialogMode, setDialogMode] = useState()
  const [selectedCellTime, setSelectedCellTime] = useState()
  const [selectedTarTimeRow, setSelectedTarTimeRow] = useState()
  const [selectedOccurrence, setSelectedOccurrence] = useState()

  const {
    data: tarDashboard,
    isLoading,
  } = useTarDashboard(patient.id, dateRange, type, statusMessageRef)

  useEffect(() => {
    setFormattedTarDashboard(
      sortTarTasks(
        formatOccurrences(tarDashboard?.tarTasks),
      ),
    )
  }, [tarDashboard])

  const currentUser = useCurrentUser()
  const isAdmin = currentUser.role === 'site_admin'
  const canChangeTar = ['site_admin', 'caregiver'].includes(currentUser.role)

  const onSelectedOccurrence = (e, rowData, cellTime, occurrences) => {
    setSelectedOccurrences(occurrences)
    setSelectedTarTimeRow(rowData)
    setSelectedCellTime(cellTime)

    if (statusOverlay.current) {
      statusOverlay.current.hide()
      requestAnimationFrame(() => {
        statusOverlay.current.show(e)
      })
    } else {
      statusOverlay.current.show(e)
    }
  }

  const onEmptyCellClick = (rowData, cellTime) => {
    setSelectedOccurrences([])
    setSelectedOccurrence(null)
    setSelectedTarTimeRow(rowData)
    setSelectedCellTime(cellTime)
    setDialogMode('add')
  }

  const onEditClick = (occurrence) => {
    if (occurrence.status === 'leave_of_absence') {
      setDialogMode('LOA')
    } else {
      setDialogMode('edit')
    }
    setSelectedOccurrence(occurrence)
    statusOverlay.current.hide()
  }

  const onTarDialogHide = () => {
    setSelectedOccurrence(null)
    setDialogMode(null)
  }

  const scheduledTag = (rowData, cellTime, occurrence) => (
    <SingleTaskStatusTag
      status={occurrence.status}
      initials={occurrence.actionedBy?.initials}
      onClick={(e) => onSelectedOccurrence(e, rowData, cellTime, [occurrence])}
    />
  )

  const prnTag = (rowData, cellTime, occurrences) => {
    const grouped = groupBy(occurrences, 'status')
    return (
      <div className="flex flex-column gap-1 align-items-center">
        {Object.keys(grouped).map((status) => (
          <MultipleTasksStatusTag
            key={status}
            count={grouped[status].length}
            type={status}
            onClick={(e) => onSelectedOccurrence(e, rowData, cellTime, grouped[status])}
          />
        ))}
      </div>
    )
  }

  const occurrenceTemplate = (rowData, column) => {
    const occurrences = get(rowData, column.field)
    const date = column.field.split('.')[1]

    const timezone = occurrences?.[0]?.timezone || patient.timezone
    const cellTime = moment(`${date} ${rowData.time}`, 'YYYY-MM-DD HH:mm A').tz(timezone, true)

    if (isEmpty(occurrences)) {
      // Do not show the option to create an occurrence in empty cells when the cell is in the
      // future, the user is not a site admin, or it is a custom task without a treatment.
      if (cellTime.isAfter(moment())) return null
      if (!isAdmin) return null
      // TODO: Allow creating occurrences for custom tasks once the backend properly supports it.
      if (!rowData.treatment) return null

      return (
        <EmptyCell
          onClick={() => onEmptyCellClick(rowData, cellTime)}
        />
      )
    }

    return (
      <div className="flex flex-row align-items-center justify-content-center">
        {type === 'routine' ? scheduledTag(rowData, cellTime, occurrences[0]) : prnTag(rowData, cellTime, occurrences)}
      </div>
    )
  }

  return (
    <div>
      <Toast ref={statusMessageRef} />
      <OverlayPanel ref={statusOverlay} className="adherence-status-overlay">
        <StatusOverlay
          occurrences={selectedOccurrences}
          canEdit={selectedTarTimeRow?.treatment !== null && isAdmin}
          onEditClick={onEditClick}
        />
      </OverlayPanel>
      <DateTable
        columns={tarTasksColumns({
          dateRange,
          treatmentTemplate,
          taskTemplate,
          occurrenceTemplate,
          showTime: type === 'routine',
        })}
        data={formattedTarDashboard}
        isLoading={isLoading}
        tableClassName="adherence-table"
        emptyMessage="No tasks available"
        className="schedule-mar-view"
        rowGroupMode="rowspan"
        groupRowsBy={['treatment.id', 'task.id']}
        sortMode="single"
        scrollHeight="70vh"
      />
      {
        canChangeTar && (
          <TarDialog
            visible={!isEmpty(dialogMode) && dialogMode !== 'LOA'}
            onHide={onTarDialogHide}
            taskOccurrence={selectedOccurrence}
            cellTime={selectedCellTime}
            tarTimeRow={selectedTarTimeRow}
            mode={dialogMode}
            patient={patient}
          />
        )
      }
      {
        canChangeTar && (
          <AdherenceLOADialog
            patient={tarDashboard?.patient}
            visible={dialogMode === 'LOA'}
            onHide={onTarDialogHide}
            time={selectedOccurrence?.expectedAt}
            statusMessageRef={statusMessageRef}
          />
        )
      }
    </div>
  )
})

export default TarTasks
