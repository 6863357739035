import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { ScheduledNoteDialog } from '@components/scheduledNotes'
import { TaskDialog } from '@components/tasks/TaskDialog'
import { useDiscontinueTreatment, useReactivateTreatment } from '@hooks/treatments'

export function TreatmentActionMenu({ statusMessage, treatment }) {
  const actionMenu = useRef(null)
  const [showScheduledNoteDialog, setShowScheduledNoteDialog] = useState(false)
  const [showTaskDialog, setShowTaskDialog] = useState(false)

  const {
    mutateAsync: discontinueTreatment,
  } = useDiscontinueTreatment({ statusMessage })

  const {
    mutateAsync: reactivateTreatment,
  } = useReactivateTreatment({ statusMessage })

  if (!treatment) {
    return null
  }

  const taskActions = []

  if (treatment.tasks.length < 1 && !treatment.discontinuedAt) {
    taskActions.push({
      label: 'Convert to Task',
      icon: 'pi pi-stopwatch',
      command: () => setShowTaskDialog(true),
    })
  }

  if (!treatment.showInMedpass && !treatment.discontinuedAt) {
    taskActions.push({
      label: 'Convert to Reminder',
      icon: 'pi pi-bell',
      command: () => setShowScheduledNoteDialog(true),
    })
  }

  if (treatment.discontinuedAt) {
    taskActions.push({
      label: 'Reactivate',
      icon: 'pi pi-play',
      command: () => reactivateTreatment(treatment),
    })
  } else {
    taskActions.push({
      label: 'Discontinue',
      icon: 'pi pi-ban',
      command: () => discontinueTreatment(treatment),
    })
  }

  return (
    <div>
      <ScheduledNoteDialog
        treatment={treatment}
        onHide={() => setShowScheduledNoteDialog(false)}
        visible={showScheduledNoteDialog}
      />
      <TaskDialog
        treatment={treatment}
        onHide={() => setShowTaskDialog(false)}
        visible={showTaskDialog}
      />
      <Menu model={taskActions} popup ref={actionMenu} id="popup_menu" />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        className="p-button-sm"
        iconPos="right"
        aria-controls="popup_menu"
        aria-haspopup
        onClick={(event) => actionMenu.current.toggle(event)}
      />
    </div>
  )
}

export default TreatmentActionMenu
