import React from 'react'
import { RoleCard } from '@components/display'
import PrescriptionsTable from '@components/prescriptions/PrescriptionsTable'

function Prescriptions({ patient, doseId }) {
  return (
    <div className="col-12">
      <RoleCard title="Prescriptions" roles={['superadmin', 'site_admin']}>
        <PrescriptionsTable timezone={patient.timezone} model="Dose" id={doseId} allowUnlink />
      </RoleCard>
    </div>
  )
}

export default Prescriptions
