import React from 'react'

/**
 * A barcode icon that works like a button to open a barcode dialog.
 *
 * @param {object} props
 * @param {Function} props.onClick The function to call when the button is clicked
 */
function BarcodeButton({ onClick }) {
  return (
    <div
      className="cursor-pointer"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick()
        }
      }}
      role="button"
      tabIndex={0}
    >
      <i className="fa-solid fa-barcode" />
    </div>
  )
}

export default BarcodeButton
