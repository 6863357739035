/**
 * This is a mapping of the pharmacy fields used internally to reader-friendly values that will
 * be displayed to the user in the UI. These values should be kept consistent with the fields
 * defined in app/services/pharmacy_diff_service.rb. It is important to note that the API returns
 * the fields in snake case, but the API client converts object keys to camel case. This means
 * that the fields for has_many relationships will be in camel case and all other fields will be
 * in snake case. This is not great and should be revisited to avoid future confusion or bugs.
 * For consistency, all fields are defined in camel case here and a helper function is used to
 * convert snake case to camel case before looking up the field label.
 */
export const PHARMACY_FIELDS = {
  // Dose Fields
  endAt: { label: 'End Date', format: 'date' },
  expiration: { label: 'Expiration Date', format: 'date' },
  packaging: { label: 'Packaging' },
  pharmacyName: { label: 'Pharmacy Name' },
  pharmacyPhone: { label: 'Pharmacy Phone' },
  physicianName: { label: 'Physician Name' },
  physicianPhone: { label: 'Physician Phone' },
  refillsRemaining: { label: 'Refills Remaining' },
  startAt: { label: 'Start Date', format: 'date' },
  walkInRx: { label: 'Walk-In Rx' },

  // Dose Purpose Fields
  icdCode: { label: 'ICD Code' },
  // NOTE: The text field was already defined for allergies, so using purposeText instead.
  purposeText: { label: 'Medication Purpose' },

  // Dose Schedule Fields
  dayOfWeek: { label: 'Day of Week' },
  eventTimeDirective: { label: 'Event Time Directive' },
  eventType: { label: 'Event Type' },
  rrule: { label: 'Recurrence Rule' },
  shortcutType: { label: 'Shortcut Type' },
  source: { label: 'Source' },
  time: { label: 'Time' },

  // Dose Sig Fields
  administrationAmount: { label: 'Administration Amount' },
  endAtWall: { label: 'End Time' },
  note: { label: 'Note' },
  instructions: { label: 'Instructions' },
  prn: { label: 'PRN' },
  startAtWall: { label: 'Start Time' },
  units: { label: 'Units' },

  // Patient Fields
  customerId: { label: 'Customer ID' },
  dateOfBirth: { label: 'Date of Birth', format: 'date' },
  email: { label: 'Email' },
  firstName: { label: 'First Name' },
  gender: { label: 'Gender' },
  lastName: { label: 'Last Name' },
  phone: { label: 'Phone' },
  roomNumber: { label: 'Room Number' },
  ssn: { label: 'Social Security Number', format: 'hidden' },

  // Patient Allergies Fields
  allergenType: { label: 'Allergen Type' },
  text: { label: 'Allergen' },

  // Patient Insurance Fields
  deactivatedAt: { label: 'Deactivated At', format: 'date' },
  groupNumber: { label: 'Group Number' },
  name: { label: 'Name' },
  policyNumber: { label: 'Policy Number' },

  // Patient Settings Fields
  selfAdmin: { label: 'Self Admin' },
  medicaidIdNumber: { label: 'Medicaid Id', format: 'hidden' },

  // Prescription Fields
  discontinuedAt: { label: 'Discontinued At', format: 'date' },
  orderNumber: { label: 'Order Number' },
  prescriptionNumber: { label: 'Prescription Number' },
}

export default PHARMACY_FIELDS
