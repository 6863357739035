import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'
import { momentFormats } from '@services/utils/moment'

function useVitalsDashboard(patientId, dateRange, statusMessage) {
  return useQuery({
    queryKey: ['vitalsDashboard', patientId, dateRange],
    queryFn: () => {
      const params = {
        startDate: dateRange.startDate.format(momentFormats.date),
        endDate: dateRange.endDate.format(momentFormats.date),
      }
      return ApiClient.get(`/patients/${patientId}/vitals_dashboard`, { params })
    },
    onError: (error) => displayErrorMessages(error, statusMessage),
  })
}

export default useVitalsDashboard
