import React, { useRef, useState } from 'react'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Message } from 'primereact/message'
import { Messages } from 'primereact/messages'
import DatePickerWithLabel from '@components/display/DatePickerWithLabel'
import InputTextareaWithLabel from '@components/display/InputTextareaWithLabel'
import { useCreateScheduledNote } from '@hooks/scheduledNotes'

function ScheduledNoteDialog({
  treatment, onHide, visible,
}) {
  const statusMessage = useRef()
  const [note, setNote] = useState(treatment?.instructions || '')
  const [startAt, setStartAt] = useState(treatment?.startAt
    ? moment(treatment.startAt).toDate() : null)
  const [endAt, setEndAt] = useState(treatment?.endAt
    ? moment(treatment.endAt).toDate() : null)

  const {
    mutateAsync: createScheduledNote,
    isLoading,
  } = useCreateScheduledNote({ statusMessage })

  if (!treatment) {
    return null
  }

  const showWarningMessage = treatment.tasks.length !== 0

  const handleCreateScheduledNote = async () => {
    await createScheduledNote({
      patientId: treatment.patientId,
      treatmentId: treatment.id,
      note,
      startAt,
      endAt,
    })
    onHide()
  }

  return (
    <Dialog
      header="Create Med Pass Reminder"
      onHide={onHide}
      visible={visible}
    >
      <Messages ref={statusMessage} />
      {
        showWarningMessage && (
          <Message
            className="mb-3"
            severity="warn"
            text="Changing the treatment from a task to a reminder will permanently remove the task."
          />
        )
      }
      <div className="flex flex-column">
        <InputTextareaWithLabel
          inputId="note"
          label="Med Pass Reminder"
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
        <div className="flex flex-row justify-content-between gap-4">
          <DatePickerWithLabel
            inputId="startAt"
            label="Start Date"
            maxDate={endAt}
            onChange={(e) => setStartAt(e.value)}
            value={startAt}
          />
          <DatePickerWithLabel
            inputId="endAt"
            label="End Date"
            minDate={startAt || new Date()}
            onChange={(e) => setEndAt(e.value)}
            value={endAt}
          />
        </div>
        <div className="flex flex-row justify-content-end">
          <Button
            label="Create"
            icon="pi pi-check"
            onClick={handleCreateScheduledNote}
            disabled={isLoading || !note || !startAt}
            loading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ScheduledNoteDialog
