import React from 'react'
import StatusOverlayContent from './StatusOverlayContent'
/**
 * @typedef {moment.Moment} Moment
 */

/**
 * Generic Overlay component that can display various types of overlays
 * by adjusting its content and styling based on the type provided.
 *
 * @param {Object} consumption - The consumption object.
 * @param {moment.Moment} cellTime - The time of the cell.
 * @param {Object} marTimeRow - The marTimeRow object.
 * @param {boolean} canEdit - If the user can edit the status.
 * @param {function} showEditStatusDialog - Function to show the edit status dialog.
 * @returns {JSX.Element} - JSX element representing the overlay.
 */
function StatusOverlay({
  consumption,
  canEdit,
  onConsumptionEdit,
}) {
  if (!consumption) return null

  return (
    <div className="w-22rem p-1 pb-0">
      <StatusOverlayContent
        consumption={consumption}
        {...(canEdit && {
          handleEdit: onConsumptionEdit,
        })}
      />
    </div>
  )
}

export default StatusOverlay
