import React from 'react'
import ScheduleEditor from '@components/clientDoses/Schedule/ScheduleEditor/ScheduleEditor'
import { AddTimeEditor } from '@components/pharmacyOrders/Editors'
import { formatSchedule } from '@utils/schedule'

function ScheduleConfigurationRow({
  schedulableType = 'Task',
  schedules = [],
  setSchedules,
  visible = true,
}) {
  if (!visible) {
    return null
  }

  const handleCreateSchedule = ({ schedule }) => {
    setSchedules([...schedules, formatSchedule(schedule)])
  }

  const handleUpdateSchedule = ({ schedule }) => {
    const unchangedSchedules = schedules
      .filter((s) => s.rrule !== schedule.rrule || s.time !== schedule.time)
    setSchedules([...unchangedSchedules, formatSchedule(schedule)])
  }

  const handleDeleteSchedule = ({ schedule }) => {
    const schedulesToKeep = schedules
      .filter((s) => s.rrule !== schedule.rrule || s.time !== schedule.time)
    setSchedules([...schedulesToKeep])
  }

  return (
    <div className="flex flex-row align-items-center justify-content-between">
      <div className="text-lg">Schedule</div>
      <ScheduleEditor
        handleUpdateSchedule={handleUpdateSchedule}
        handleDeleteSchedule={handleDeleteSchedule}
        schedulableType="Task"
        schedules={schedules}
      />
      <AddTimeEditor
        handleCreateSchedule={handleCreateSchedule}
        schedulableType={schedulableType}
      />
    </div>
  )
}

export default ScheduleConfigurationRow
