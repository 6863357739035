import { filter, map, uniqBy } from 'lodash'
import { getMomentFromMinutes, momentFromIso8601Str } from '@services/utils/moment'
import moment from 'moment'
import { formatSchedule } from './utils'

/**
 * Encapsulates the logic for merging doseSigs and compositeOrderSigs into a single array of sigs.
 * When the dose and composite order values do not match, the UI's attribute row shows a diff
 * between the dose value and the pharmacy order value. The pharmacy order value is displayed
 * via props that are not connected to the state. The initial state value that is populated here
 * is the dose value. However, we want to also show the incoming pharmacy order value and sigs
 * when there is not a corresponding dose sig. Therefore, this function merges the dose sigs and
 * the composite order sigs into a single array of sigs giving precedence to the dose sigs. If
 * there is no dose sig or value, then the composite order sig or value is used for the initial
 * state and thus a diff is not shown.
 * @param doseSigs The dose's current dose sigs. This is how the dose sig is currently configured.
 * @param compositeOrderSigs The composite order's sigs. Sent by the pharmacy and needs review.
 */
const mergeSigs = (doseSigs = [], compositeOrderSigs = []) => {
  const maxLength = Math.max(doseSigs.length, compositeOrderSigs.length)

  return Array.from({ length: maxLength }).map((_, index) => {
    const doseSig = doseSigs[index] || {}
    const compositeOrderSig = compositeOrderSigs[index] || {}

    const sigStartAtWall = doseSig.startAtWall ?? compositeOrderSig.startAtWall
    const sigEndAtWall = doseSig.endAtWall ?? compositeOrderSig.endAtWall
    const sigStartAt = sigStartAtWall ? momentFromIso8601Str(sigStartAtWall) : null
    const sigEndAt = sigEndAtWall ? momentFromIso8601Str(sigEndAtWall) : null

    let schedules = (doseSig.schedules || []).map((schedule) => ({
      ...schedule,
      timeInMinutes: schedule.time,
      time: getMomentFromMinutes(schedule.time),
    }))

    if (schedules.length === 0) {
      schedules = compositeOrderSig.schedules || []
    }

    // Not using the spread operator because we want to carefully manage the values added to state
    return {
      administrationAmount: doseSig.administrationAmount ?? compositeOrderSig.administrationAmount,
      endAtWall: sigEndAtWall,
      hl7CalendarAlignment: doseSig.hl7CalendarAlignment ?? compositeOrderSig.hl7CalendarAlignment,
      hl7Conjunction: doseSig.hl7Conjunction ?? compositeOrderSig.hl7Conjunction,
      hl7ExplicitTimes: doseSig.hl7ExplicitTimes ?? compositeOrderSig.hl7ExplicitTimes,
      hl7Priority: doseSig.hl7Priority ?? compositeOrderSig.hl7Priority,
      hl7ScheduleCode: doseSig.hl7ScheduleCode ?? compositeOrderSig.hl7ScheduleCode,
      hl7SetId: doseSig.hl7SetId ?? compositeOrderSig.hl7SetId,
      hl7TotalOccurrences: doseSig.hl7TotalOccurrences ?? compositeOrderSig.hl7TotalOccurrences,
      // Used to find the sig in state to update
      id: doseSig.id ?? compositeOrderSig.id,
      // Used to find the composite sig's match in state
      compositeOrderSigId: compositeOrderSig.id,
      // Used in API requests to update the sig
      doseSigId: doseSig.id,
      instructions: compositeOrderSig.instructions,
      prn: doseSig.prn ?? compositeOrderSig.prn,
      quantity: doseSig.quantity ?? compositeOrderSig.quantity,
      schedules: schedules.map((schedule) => formatSchedule({
        schedule,
        startAt: sigStartAt,
        endAt: sigEndAt,
      })),
      startAtWall: sigStartAtWall,
      units: doseSig.units ?? compositeOrderSig.units,
      startAt: sigStartAt,
      endAt: sigEndAt,
    }
  })
}

/**
 * A utility function to create the reducer's initial state from the given reviewableOrder.
 * @param {Object} reviewableOrder A reviewableOrder object returned by the API.
 * @returns {Object} The initial state for the reducer.
 */
export const initialState = (reviewableOrder) => {
  const compositeOrder = { ...reviewableOrder.dose, ...reviewableOrder.compositeOrder }
  const sourceOrders = reviewableOrder?.sourceOrders ?? []

  const existingFillDispensedAts = map(reviewableOrder.dose?.doseFills || [], 'pharmacyDispensedAt')

  const fillOrders = uniqBy(
    filter(
      sourceOrders,
      ({ orderControl, dosesInFill, pharmacyDispensedAt }) => ((orderControl === 'RE' || orderControl === 'NW') && (dosesInFill && dosesInFill > 0) && pharmacyDispensedAt),
    ),
    'pharmacyDispensedAt',
  )

  const newFillOrders = filter(
    fillOrders,
    ({ pharmacyDispensedAt }) => !existingFillDispensedAts.includes(pharmacyDispensedAt),
  )

  let doseSigs = reviewableOrder?.dose?.doseSigs || []

  if (doseSigs.length === 0) {
    doseSigs = reviewableOrder?.matchedDose?.doseSigs || []
  }

  const sigs = mergeSigs(doseSigs, reviewableOrder?.compositeOrder?.sigs || [])

  const baseSig = sigs[0]
  const startAt = baseSig?.startAt || compositeOrder?.startAt
  const endAt = baseSig?.endAt || compositeOrder?.endAt
  const startAtMoment = startAt ? moment(startAt) : null
  const endAtMoment = endAt ? moment(endAt) : null

  return {
    id: reviewableOrder?.dose?.id,
    medicine: compositeOrder?.medicine,
    orderType: compositeOrder?.orderType,
    purpose: compositeOrder?.purpose,
    startAt: startAtMoment,
    endAt: endAtMoment,
    instructions: compositeOrder?.instructions,
    note: compositeOrder?.note,
    administrationAmount: compositeOrder?.administrationAmount,
    administrationRoute: compositeOrder?.administrationRoute,
    prn: !!compositeOrder?.prn,
    refillsRemaining: compositeOrder?.refillsRemaining,
    purposeCode: compositeOrder?.purposeIcdCode,
    purposeText: compositeOrder?.purposeText,
    doseFills: map(newFillOrders, (sourceOrder) => ({
      expiresAt: endAt,
      pharmacyDispensedAt: sourceOrder.pharmacyDispensedAt,
      pharmacyOrderId: sourceOrder.id,
      prescriptionNumber: sourceOrder.prescriptionNumber,
      refillsRemaining: sourceOrder.refillsRemaining,
      dosesInFill: sourceOrder.dosesInFill,
      fillsLeft: sourceOrder.dosesInFill,
      medicineText: sourceOrder?.pharmacyMedicine?.medicineText,
    })),
    prescription: {
      id: reviewableOrder?.prescription?.id,
      active: reviewableOrder?.prescription?.active,
      prescriptionNumber: reviewableOrder?.prescription?.prescriptionNumber,
      refillsRemaining: reviewableOrder?.prescription?.refillsRemaining,
    },
    sigs,
  }
}

export default initialState
