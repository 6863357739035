import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import {
  displayErrorMessages,
} from '@services/errorHandler'

export function useCreateDoseCondition(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useCreateDoseCondition'],
    mutationFn: (doseCondition) => ApiClient.post('/dose_conditions', { doseCondition }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Medication criteria / vitals created!',
        },
      ])
      // TODO: Why invalidate the entire dose instead of just the conditions?
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useUpdateDoseCondition(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useUpdateDoseCondition'],
    mutationFn: (doseCondition) => ApiClient.put(`/dose_conditions/${doseCondition.id}`, { doseCondition }),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Medication criteria / vitals updated!',
        },
      ])
      // TODO: Why invalidate the entire dose instead of just the conditions?
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export function useDeleteDoseCondition(statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['useDeleteDoseCondition'],
    mutationFn: (doseCondition) => ApiClient.delete(`/dose_conditions/${doseCondition.id}`),
    onSuccess: () => {
      statusMessage.current.show([
        {
          severity: 'success',
          summary: 'Medication criteria / vitals deleted.',
        },
      ])
      // TODO: Why invalidate the entire dose instead of just the conditions?
      queryClient.invalidateQueries('patientDose')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default {}
