import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { map } from 'lodash'
import { manuallyEnteredFrequency } from '../../clientDoses/config'

function DoseFrequencyField({
  frequency,
  hl7ScheduleCodeFrequency,
  newFrequency,
  setNewFrequency,
  isEditing,
}) {
  if (hl7ScheduleCodeFrequency) return <span>{hl7ScheduleCodeFrequency}</span>

  if (!isEditing) {
    if (!frequency) {
      return (
        <div className="flex flex-row gap-1">
          <i className="pi pi-exclamation-triangle text-orange-500" />
          <span className="text-xs">Click edit to set frequency</span>
        </div>
      )
    }
    return (
      <span style={{ wordBreak: 'break-all', minWidth: '120px' }}>{frequency}</span>
    )
  }

  return (
    <Dropdown
      className="w-12"
      value={newFrequency}
      options={map(manuallyEnteredFrequency, (value) => ({ label: value, value }))}
      dataKey="value"
      dataValue="label"
      onChange={(e) => setNewFrequency(e.value)}
      placeholder="Select a frequency"
    />
  )
}

export default DoseFrequencyField
